// src/components/Contacts/ContactActivityTimeline.jsx

import React from 'react';
import { VStack } from '@chakra-ui/react';
import ContactActivityItem from './ContactActivityItem';

/**
 * ContactActivityTimeline Component
 * Renders a timeline of contact activities.
 *
 * @param {Object} props
 * @param {Array} props.activities - Array of activity objects.
 * @returns {JSX.Element}
 */
const ContactActivityTimeline = ({ activities }) => {
  return (
    <VStack spacing={4} align="stretch">
      {activities.map((activity) => (
        <ContactActivityItem key={activity.id} activity={activity} />
      ))}
    </VStack>
  );
};

export default ContactActivityTimeline;
