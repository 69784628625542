// src/components/UserStatsGrid.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthData } from "auth-context";
import UserStatCard from "./UserStatCard";
import { addDays, format } from 'date-fns';

/**
 * UserStatsGrid Component
 * Fetches user-specific activity details from the new endpoint, classifies activities,
 * combines with LinkedIn data, and displays individual user statistics.
 */
const UserStatsGrid = ({ timeRange }) => {
  const [userStats, setUserStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const userData = useAuthData();
  const accountID = userData?.account_ID;

  useEffect(() => {
    const fetchUserStats = async () => {
      if (!accountID) {
        setError("Account ID not found.");
        setLoading(false);
        return;
      }

      try {
        // Calculate start_date and end_date based on timeRange
        let startDate;
        let endDate = new Date(); // Today

        switch (timeRange) {
          case 'week':
            startDate = addDays(endDate, -7);
            break;
          case 'month':
            startDate = addDays(endDate, -30);
            break;
          case 'day':
            startDate = addDays(endDate, -1);
            break;
          // Add more cases as needed
          default:
            startDate = addDays(endDate, -7);
        }

        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');

        // Get access token
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:dashboard",
          },
        });

        const apiBaseUrl = process.env.REACT_APP_API_URL || "https://lysto-be-tf.azurewebsites.net";

        const newDealActivityUrl = `${apiBaseUrl}/accounts/${accountID}/deal/activity-detail-by-days-and-users`;
        const linkedinActivityUrl = `${apiBaseUrl}/accounts/${accountID}/linkedin/activity-count-by-days-and-user`;

        // Define headers with authorization  
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        // Define query parameters
        const dealParams = {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        };

        const linkedinParams = {
          days: 7, // Adjust if needed
          pipeline_external_id: accountID === "4" ? "012JA0000004IMpYAM" : "7fded5a8-151d-4cd5-8d86-b434c362f707",
          period: "week",
        };

        // Make API calls in parallel
        const [dealRes, linkedinRes] = await Promise.all([
          fetch(newDealActivityUrl + '?' + new URLSearchParams(dealParams), { headers }),
          fetch(linkedinActivityUrl + '?' + new URLSearchParams(linkedinParams), { headers }),
        ]);

        if (!dealRes.ok || !linkedinRes.ok) {
          throw new Error('Failed to fetch user stats');
        }

        const [dealData, linkedinData] = await Promise.all([
          dealRes.json(),
          linkedinRes.json()
        ]);

        const mergedStats = mergeUserStats(dealData.activities || [], linkedinData);
        setUserStats(mergedStats);

      } catch (err) {
        console.error("Error fetching user stats:", err);
        setError("Failed to fetch user statistics.");
        toast({
          title: "Error",
          description: "Failed to fetch user statistics.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUserStats();
  }, [accountID, timeRange, getAccessTokenSilently, toast]);

  /**
   * Merges dealData with LinkedIn data for each user.
   * @param {Array} dealData - Detailed activities from the new endpoint
   * @param {Array} linkedinData - Data from linkedin/activity-count-by-days-and-user
   * @returns {Array} Merged user statistics
   */
  const mergeUserStats = (dealData, linkedinData) => {
    const userMap = new Map();

    // Process dealData with new classification
    dealData.forEach((activity) => {
      const userId = activity.user_id; // Adjust based on actual field name
      const userName = activity.user_name; // Adjust based on actual field name
      const userEmail = activity.user_email; // Adjust based on actual field name

      if (!userMap.has(userId)) {
        userMap.set(userId, {
          user_id: userId,
          name: userName,
          email: userEmail,
          recordings: { count: 0, growth: 0 },
          calendars: { count: 0, growth: 0 },
          emails: { count: 0, growth: 0 },
          linkedin_messages_sent: { count: 0, growth: 0 },
          linkedin_messages_received: { count: 0, growth: 0 },
          linkedin_invites: { count: 0, growth: 0 },
        });
      }

      const user = userMap.get(userId);

      // Classification Logic
      if (activity.description && activity.description.includes("https://us-70510.app.gong.io/call?id=")) {
        // Recording
        user.recordings.count += 1;
        user.recordings.growth += activity.growth || 0;
      } else if (activity.title && /(Calendly|Meeting)/i.test(activity.title)) {
        // Calendar
        user.calendars.count += 1;
        user.calendars.growth += activity.growth || 0;
      } else {
        // Email
        user.emails.count += 1;
        user.emails.growth += activity.growth || 0;
      }
    });

    // Process linkedinData
    linkedinData.forEach((user) => {
      const userId = user.user_id; // Adjust based on actual field name

      if (!userMap.has(userId)) {
        userMap.set(userId, {
          user_id: userId,
          name: user.name,
          email: user.email,
          recordings: { count: 0, growth: 0 },
          calendars: { count: 0, growth: 0 },
          emails: { count: 0, growth: 0 },
          linkedin_messages_sent: { count: 0, growth: 0 },
          linkedin_messages_received: { count: 0, growth: 0 },
          linkedin_invites: { count: 0, growth: 0 },
        });
      }

      const userStats = userMap.get(userId);

      userStats.linkedin_messages_sent.count += user.linkedin_messages_sent?.count || 0;
      userStats.linkedin_messages_sent.growth += user.linkedin_messages_sent?.growth || 0;

      userStats.linkedin_messages_received.count += user.linkedin_messages_received?.count || 0;
      userStats.linkedin_messages_received.growth += user.linkedin_messages_received?.growth || 0;

      userStats.linkedin_invites.count += user.linkedin_invites?.count || 0;
      userStats.linkedin_invites.growth += user.linkedin_invites?.growth || 0;
    });

    // Convert map to array and filter out inactive users
    return Array.from(userMap.values()).filter(user => (
      user.recordings.count > 0 ||
      user.calendars.count > 0 ||
      user.emails.count > 0 ||
      user.linkedin_messages_sent.count > 0 ||
      user.linkedin_messages_received.count > 0 ||
      user.linkedin_invites.count > 0
    ));
  };

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading user statistics...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  if (userStats.length === 0) {
    return (
      <Box textAlign="center" py={10}>
        <Text>No active users found.</Text>
      </Box>
    );
  }

  return (
    <Box pb={4}>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} gap="20px">
        {userStats.map((user) => (
          <UserStatCard key={user.user_id} user={user} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default UserStatsGrid;
