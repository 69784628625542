// src/components/Inbox/ConversationHistory.jsx
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex, VStack } from '@chakra-ui/react';
import ChatMessage from './ChatMessage';

const ConversationHistory = ({ conversationHistory }) => {
  const messagesEndRef = useRef(null);

  // Auto-scroll to bottom when new messages are added
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversationHistory]);

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={2}>
        Conversation History
      </Text>

      <Box
        bg="white"
        maxHeight="400px"
        overflowY="auto"
        borderWidth={1}
        borderColor="gray.200"
        borderRadius="md"
        p={4}
        sx={{
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'gray.100',
            borderRadius: 'md',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.400',
            borderRadius: 'md',
          },
        }}
      >
        <VStack spacing={4} align="stretch">
          {conversationHistory.length > 0 ? (
            conversationHistory.map((msg) => (
              <ChatMessage
                key={msg.message_id}
                message={msg}
                isNote={msg.type === 'note'}
              />
            ))
          ) : (
            <Flex justify="center" align="center" minH="100px" color="gray.500">
              <Text>No conversation history available.</Text>
            </Flex>
          )}
          <div ref={messagesEndRef} />
        </VStack>
      </Box>
    </Box>
  );
};

ConversationHistory.propTypes = {
  conversationHistory: PropTypes.arrayOf(
    PropTypes.shape({
      message_id: PropTypes.string.isRequired,
      identity: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired, // Corrected field
      type: PropTypes.string,
      status: PropTypes.string,
      date_sent: PropTypes.string,
      sender_name: PropTypes.string,
      sender_email: PropTypes.string,
    })
  ).isRequired,
};

export default ConversationHistory;
