// src/components/AggregatedPieCharts.js

import React, { useState, useEffect } from "react";
import { Box, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthData } from "auth-context"; // Ensure this hook is correctly implemented
import PieCard from "./PieCard";
import { CHART_COLORS } from "../constants"; // Ensure this path is correct
import { addDays, format } from "date-fns";

/**
 * AggregatedPieCharts Component
 * Fetches data from the new detailed endpoint, aggregates it, and displays two pie charts:
 * - Activity by Rep
 * - Activity by Channel
 */
const AggregatedPieCharts = ({ timeRange }) => {
  const [activityByRep, setActivityByRep] = useState(null);
  const [activityByChannel, setActivityByChannel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  // Authentication and account info
  const { getAccessTokenSilently } = useAuth0();
  const userData = useAuthData();
  const accountID = userData?.account_ID;

  useEffect(() => {
    const fetchData = async () => {
      if (!accountID) {
        setError("Account ID not found.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        // Calculate start_date and end_date based on timeRange
        let startDate;
        let endDate = new Date(); // Today

        switch (timeRange) {
          case "week":
            startDate = addDays(endDate, -7);
            break;
          case "month":
            startDate = addDays(endDate, -30);
            break;
          case "day":
            startDate = addDays(endDate, -1);
            break;
          // Add more cases as needed
          default:
            startDate = addDays(endDate, -7);
        }

        const formattedStartDate = format(startDate, "yyyy-MM-dd");
        const formattedEndDate = format(endDate, "yyyy-MM-dd");

        // Get access token
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:dashboard", // Adjust scope as needed
          },
        });

        const apiUrl =
          process.env.REACT_APP_API_URL ||
          "https://lysto-be-tf.azurewebsites.net";
        const newDealActivityUrl = `${apiUrl}/accounts/${accountID}/deal/activity-detail-by-days-and-users`;
        const linkedinActivityUrl = `${apiUrl}/accounts/${accountID}/linkedin/activity-count-by-days-and-user`;

        // Make API calls in parallel
        const [dealRes, linkedinRes] = await Promise.all([
          axios.get(newDealActivityUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              start_date: formattedStartDate,
              end_date: formattedEndDate,
            },
          }),
          axios.get(linkedinActivityUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              days: 7,
              period: "week",
            },
          }),
        ]);

        const dealData = dealRes.data.activities || []; // Adjust based on actual response structure
        const linkedinData = linkedinRes.data || [];

        // Aggregate data based on new classifications
        const { repData, channelData } = aggregateData(dealData, linkedinData);

        setActivityByRep(repData);
        setActivityByChannel(channelData);
      } catch (err) {
        console.error("Error fetching aggregated pie charts:", err);
        setError("Failed to fetch aggregated pie charts.");
        toast({
          title: "Error",
          description: "Failed to fetch aggregated pie charts.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accountID, timeRange, getAccessTokenSilently, toast]);

  /**
   * Aggregates data based on new classifications: Recordings, Calendar Updates, Emails
   * @param {Array} dealData - Detailed activities from the new endpoint
   * @param {Array} linkedinData - Data from linkedin/activity-count-by-days-and-user
   * @returns {Object} Contains repData and channelData
   */
  const aggregateData = (dealData, linkedinData) => {
    // Aggregate Activity by Rep
    const repActivityMap = new Map();

    // Process dealData for Activity by Rep
    dealData.forEach((activity) => {
      const userName = activity.user_name || "Unknown"; // Adjust based on actual field name
      const description = activity.description || "";
      const title = activity.title || "";

      let activityCategory = "Emails"; // Default

      if (description.includes("https://us-70510.app.gong.io/call?id=")) {
        activityCategory = "Recordings";
      } else if (/(Calendly|Meeting)/i.test(title)) {
        activityCategory = "Calendar Updates"; // Updated classification name
      }

      if (repActivityMap.has(userName)) {
        repActivityMap.set(userName, {
          ...repActivityMap.get(userName),
          [activityCategory]:
            (repActivityMap.get(userName)[activityCategory] || 0) + 1,
        });
      } else {
        repActivityMap.set(userName, {
          Recordings: activityCategory === "Recordings" ? 1 : 0,
          "Calendar Updates": activityCategory === "Calendar Updates" ? 1 : 0, // Updated classification name
          Emails: activityCategory === "Emails" ? 1 : 0,
          "LinkedIn Sent": 0,
          "LinkedIn Received": 0,
          "LinkedIn Invites": 0,
        });
      }
    });

    // Process linkedinData for Activity by Rep
    linkedinData.forEach((user) => {
      const userName = user.name || "Unknown"; // Adjust based on actual field name

      if (repActivityMap.has(userName)) {
        repActivityMap.set(userName, {
          ...repActivityMap.get(userName),
          "LinkedIn Sent":
            (repActivityMap.get(userName)["LinkedIn Sent"] || 0) +
            (user.linkedin_messages_sent?.count || 0),
          "LinkedIn Received":
            (repActivityMap.get(userName)["LinkedIn Received"] || 0) +
            (user.linkedin_messages_received?.count || 0),
          "LinkedIn Invites":
            (repActivityMap.get(userName)["LinkedIn Invites"] || 0) +
            (user.linkedin_invites?.count || 0),
        });
      } else {
        repActivityMap.set(userName, {
          Recordings: 0,
          "Calendar Updates": 0, // Updated classification name
          Emails: 0,
          "LinkedIn Sent": user.linkedin_messages_sent?.count || 0,
          "LinkedIn Received": user.linkedin_messages_received?.count || 0,
          "LinkedIn Invites": user.linkedin_invites?.count || 0,
        });
      }
    });

    // Prepare repData for Pie Chart
    const repLabels = [];
    const repDataValues = [];

    repActivityMap.forEach((counts, repName) => {
      // Calculate total activities including LinkedIn activities
      const totalActivities =
        counts.Recordings +
        counts["Calendar Updates"] +
        counts.Emails +
        counts["LinkedIn Sent"] +
        counts["LinkedIn Received"] +
        counts["LinkedIn Invites"];
      if (totalActivities > 0) {
        repLabels.push(repName);
        repDataValues.push(totalActivities);
      }
    });

    const repData = {
      title: "Activity by Rep",
      labels: repLabels,
      datasets: [
        {
          data: repDataValues,
          backgroundColor: CHART_COLORS, // Ensure CHART_COLORS is defined
        },
      ],
    };

    // Aggregate Activity by Channel
    const channelActivity = {
      Recordings: 0,
      "Calendar Updates": 0, // Updated classification name
      Emails: 0,
      "LinkedIn Sent": 0,
      "LinkedIn Received": 0,
      "LinkedIn Invites": 0,
    };

    // Process dealData for Activity by Channel
    dealData.forEach((activity) => {
      const description = activity.description || "";
      const title = activity.title || "";

      if (description.includes("https://us-70510.app.gong.io/call?id=")) {
        channelActivity.Recordings += 1;
      } else if (/(Calendly|Meeting)/i.test(title)) {
        channelActivity["Calendar Updates"] += 1; // Updated classification name
      } else {
        channelActivity.Emails += 1;
      }
    });

    // Process linkedinData for Activity by Channel
    linkedinData.forEach((user) => {
      const sent = user.linkedin_messages_sent || {};
      channelActivity["LinkedIn Sent"] += sent.count || 0;

      const received = user.linkedin_messages_received || {};
      channelActivity["LinkedIn Received"] += received.count || 0;

      const invites = user.linkedin_invites || {};
      channelActivity["LinkedIn Invites"] += invites.count || 0;
    });

    const channelData = {
      title: "Activity by Channel",
      labels: Object.keys(channelActivity),
      datasets: [
        {
          data: Object.values(channelActivity),
          backgroundColor: CHART_COLORS, // Ensure CHART_COLORS is defined
        },
      ],
    };

    return { repData, channelData };
  };

  // Handle loading and error states
  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading aggregated pie charts...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box pb={4}>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
        {/* Activity by Rep Pie Chart */}
        <PieCard
          pieData={activityByRep}
          // You can pass additional props if needed
        />

        {/* Activity by Channel Pie Chart */}
        <PieCard
          pieData={activityByChannel} // Corrected: Use the state variable 'activityByChannel'
          // You can pass additional props if needed
        />
      </SimpleGrid>
    </Box>
  );
};

export default AggregatedPieCharts;
