// src/components/LiveActivity.js

import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Button,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import { MdEmail, MdEvent } from "react-icons/md";
import { PiPulse } from "react-icons/pi";
import { FaRegFileAudio } from "react-icons/fa"; // Icon for Recordings tab
import LiveActivityTimeline from "./LiveActivityTimeline";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { addDays, format } from 'date-fns'; // Import date-fns functions

const ITEMS_PER_PAGE = 8;

const LiveActivity = ({ accountId, userId, timeRange }) => { // Accept timeRange as a prop
  // State Management
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Hooks
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  // Determine the API base URL based on the environment
  const getApiBaseUrl = () => {
    return "https://lysto-be-tf.azurewebsites.net";
  };

// Categorization logic for activities
const getActivityCategory = (activity) => {
  const description = activity.description || "";
  const title = activity.title || "";
  const communication_medium = activity.classification?.communication_medium?.toLowerCase() || "";

  // New Condition:
  // If title includes "[Gong]" and description does NOT include the Gong URL, categorize as "calendar-update"
  if (
    title.toLowerCase().includes("[gong]") &&
    !description.includes("https://us-70510.app.gong.io/call?id=")
  ) {
    return "calendar-update";
  }

  if (description.includes("https://us-70510.app.gong.io/call?id=")) {
    return "recording"; // Align with "recording" category in tabs
  } else if (communication_medium === "gong" || /(Calendly|Meeting)/i.test(title)) {
    return "calendar-update"; // Align with "calendar-update" category in tabs
  } else if (activity.activity_type?.toLowerCase() === "email") {
    return "email"; // Align with "email" category in tabs
  } else {
    return "other"; // Default category
  }
};

  // Filter activities based on activity category
  const filterActivities = (type) => {
    if (!Array.isArray(activities)) return [];

    return activities.filter((activity) => {
      const category = getActivityCategory(activity);

      if (type === "all") {
        return ["recording", "calendar-update", "email"].includes(category);
      }

      return category === type;
    });
  };

  // Calculate activity counts based on activity category
  const activityCounts = {
    all: filterActivities("all").length,
    recording: filterActivities("recording").length,
    "calendar-update": filterActivities("calendar-update").length,
    email: filterActivities("email").length,
  };

  // Define tab items with icons and labels
  const tabItems = [
    { icon: PiPulse, label: `All (${activityCounts.all})`, type: "all" },
    {
      icon: FaRegFileAudio,
      label: `Recordings (${activityCounts.recording})`,
      type: "recording",
    },
    {
      icon: MdEvent,
      label: `Calendar Updates (${activityCounts["calendar-update"]})`,
      type: "calendar-update",
    },
    {
      icon: MdEmail,
      label: `Emails (${activityCounts.email})`,
      type: "email",
    },
  ];

  // Fetch activities from the backend API
  const fetchActivities = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Determine durationDays based on timeRange
      let durationDays;
      switch (timeRange) {
        case 'day':
          durationDays = 1;
          break;
        case 'week':
          durationDays = 7;
          break;
        case 'month':
          durationDays = 30;
          break;
        // Add more cases as needed
        default:
          durationDays = 7;
      }

      const endDate = new Date(); // Today
      const startDate = addDays(endDate, -durationDays); // Based on timeRange
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');

      const accessToken = await getAccessTokenSilently({
        authorizationParams: { scope: "read:activities" },
      });

      const headers = { Authorization: `Bearer ${accessToken}` };
      const apiBaseUrl = getApiBaseUrl();
      const apiUrl = `${apiBaseUrl}/accounts/${accountId}/deal/activity-detail-by-days-and-users`;
      const params = { start_date: formattedStartDate, end_date: formattedEndDate };

      if (userId && userId > 0) {
        params.user_id = userId;
      }

      const response = await axios.get(apiUrl, { headers, params });

      if (response.data && Array.isArray(response.data.activities)) {
        const uniqueActivities = [];
        const titleSet = new Set();

        for (const activity of response.data.activities) {
          const title = activity.title || "";
          if (!titleSet.has(title)) {
            titleSet.add(title);
            uniqueActivities.push(activity);
          }
        }

        uniqueActivities.sort(
          (a, b) => new Date(b.activity_date) - new Date(a.activity_date)
        );
        setActivities(uniqueActivities);
      } else {
        setActivities([]);
        toast({
          title: "No Activities Found",
          description: "No activities were found for the selected criteria.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.error("Error fetching activities:", err);
      setError("An error occurred while fetching activities.");
      toast({
        title: "Error",
        description: "An error occurred while fetching activities.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accountId) {
      fetchActivities();
    }
  }, [accountId, userId, timeRange]); // Add timeRange to dependencies

  const paginatedActivities = (activities) => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return activities.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const handleTabChange = (index) => {
    setSelectedTab(index);
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    const filtered = filterActivities(tabItems[selectedTab].type);
    const totalPages = Math.ceil(filtered.length / ITEMS_PER_PAGE);
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const currentFilteredActivities = filterActivities(
    tabItems[selectedTab].type
  );
  const totalPages = Math.ceil(
    currentFilteredActivities.length / ITEMS_PER_PAGE
  );

  return (
    <Box bg="white" p={4} borderRadius="md" boxShadow="sm" mt={1}>
      <Tabs
        variant="line"
        colorScheme="blue"
        index={selectedTab}
        onChange={handleTabChange}
      >
        <TabList>
          {tabItems.map((item, index) => (
            <Tab
              key={index}
              _selected={{
                color: "#1A5BF6",
                fontWeight: "bold",
                borderBottomColor: "#1A5BF6",
              }}
              _focus={{ boxShadow: "none", outline: "none" }}
            >
              <Flex alignItems="center">
                <item.icon style={{ marginRight: "4px" }} />
                <span>{item.label}</span>
              </Flex>
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabItems.map((item, index) => {
            const filteredActivities = filterActivities(item.type);
            const paginated = paginatedActivities(filteredActivities);

            return (
              <TabPanel key={index}>
                {isLoading ? (
                  <Spinner size="lg" />
                ) : error ? (
                  <Alert status="error" mt={4}>
                    <AlertIcon />
                    {error}
                  </Alert>
                ) : paginated.length > 0 ? (
                  <LiveActivityTimeline activities={paginated} />
                ) : (
                  <Text textAlign="center" py={4} color="gray.500">
                    No {item.type === "all" ? "activities" : item.type + "s"}{" "}
                    found.
                  </Text>
                )}

                {totalPages > 1 && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt={4}
                  >
                    <Button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Text>
                      Page {currentPage} of {totalPages}
                    </Text>
                    <Button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Button>
                  </Flex>
                )}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default LiveActivity;
