import React from "react";
import { Icon } from "@chakra-ui/react";
import IconBox from "components/icons/IconBox"; // Ensure the path is correct
import MiniStatistics from "components/card/MiniStatistics"; // Ensure the path is correct

/**
 * StatCard Component
 * Displays a statistic with an icon, title, value, and growth percentage.
 *
 * @param {Object} props - The props for the component.
 * @param {React.Component} props.icon - The icon to display.
 * @param {string} props.title - The title of the statistic.
 * @param {number|string} props.value - The value of the statistic.
 * @param {number|string} props.growth - The growth percentage.
 * @param {string} props.primaryColor - The primary color for the icon background.
 * @returns {JSX.Element} The rendered StatCard component.
 */
const StatCard = ({
  icon,
  title,
  value,
  growth,
  timeRange,
  primaryColor, // Primary color passed as a prop
}) => {
  // Format the growth to one decimal place if it's a number
  const formattedGrowth =
    typeof growth === "number" ? growth.toFixed(1) : "N/A";

  // Determine the type for arrow based on growth value
  let growthDisplay = "N/A";
  if (typeof growth === "number") {
    if (growth >= 0) {
      growthDisplay = `↑ ${Math.abs(formattedGrowth)}`;
    } else {
      growthDisplay = `↓ ${Math.abs(formattedGrowth)}`;
    }
  }

  const iconBox = (
    <IconBox
      w="56px"
      h="56px"
      bg={primaryColor}
      icon={<Icon as={icon} w="32px" h="32px" color="white" />}
    />
  );

  return (
    <MiniStatistics
      name={title}
      value={value !== undefined && value !== "N/A" ? value : "N/A"}
      growth={growthDisplay}
      timeRange={timeRange}
      startContent={iconBox}
    />
  );
};

// Export the component
export default StatCard;
