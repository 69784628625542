import React from "react";
import {
  Box,
  Icon,
  Text,
  Flex,
  Select,
  Switch,
  FormLabel,
  Tag,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { FaClock, FaMapMarkerAlt, FaUser } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { MdEvent, MdBusiness } from "react-icons/md";
import { useNavigate } from "react-router-dom"; // Replaced useHistory with useNavigate
import callRecapData from "./CallRecapData";

const HoverBox = ({ children, onClick, icon, iconProps }) => {
  const isSlackOrBuilding = icon === FontAwesomeIcon || icon === MdBusiness;

  return (
    <Tooltip label="Click to expand">
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="lg"
        cursor="pointer"
        position="relative"
        transition="all 0.2s"
        role="group"
        onClick={onClick}
        _hover={{
          "& > *": { color: "#1A5BF6" },
        }}
      >
        <Box
          as="span"
          mr={2}
          position="relative"
          fontSize="20px"
          width="20px"
          height="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            transition="opacity 0.2s"
            opacity={1}
            _groupHover={{ opacity: 0 }}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            pt={isSlackOrBuilding ? "0px" : "1px"}
          >
            {icon === FontAwesomeIcon ? (
              <FontAwesomeIcon
                icon={iconProps.icon}
                color="#748094"
                size="lg"
              />
            ) : (
              <Icon as={icon} color="#748094" boxSize="20px" />
            )}
          </Box>
          <Box
            as={FontAwesomeIcon}
            icon={faExpandAlt}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            fontSize="20px"
            width="20px"
            height="20px"
            opacity={0}
            transition="opacity 0.2s"
            _groupHover={{ opacity: 1, color: "#1A5BF6" }}
            color="#748094"
          />
        </Box>
        {children}
      </Box>
    </Tooltip>
  );
};

function EventCard({ event }) {
  const maxTitleLength = 38;
  const navigate = useNavigate(); // Replaced useHistory with useNavigate

  var pathname = `/admin/meetings/details/${event.id}`;
  if (event.recorded) {
    pathname = `/admin/meetings/analysis/${event.id}`;
  }
  const handleNavigateToMeeting = () => {
    navigate({
      pathname,
      state: { callData: callRecapData[0] },
    });
  };

  const capitalizeFirstLetter = (string) => {
    string = string.split("@")[0];
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  return (
    <Box bg="white" p="10px" m="1" borderRadius="8px" boxShadow="sm">
      <Flex align="center">
        <Flex flexDir="column" width="300px" mr="10">
          <Box mb="2">
            <HoverBox icon={MdEvent} onClick={handleNavigateToMeeting}>
              <Text
                fontWeight="semibold"
                align="left"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                title={event.title}
                fontSize="md"
              >
                {truncateText(event.title, maxTitleLength)}
              </Text>
            </HoverBox>
          </Box>
          <Flex wrap="wrap" align="center">
            {event.attendees.map((attendee, index) => (
              <Tag
                key={index}
                size="sm"
                borderRadius="lg"
                mr={1}
                mb={1}
                px={2}
                py={1}
                bg="white"
                borderWidth="1px"
                borderColor="gray.300"
              >
                <Flex alignItems="center">
                  <Icon as={FaUser} color="gray.500" mr={1} />
                  <Text fontSize="xs" color="black">
                    {capitalizeFirstLetter(attendee)}
                  </Text>
                </Flex>
              </Tag>
            ))}
          </Flex>
        </Flex>
        <Box height="60px" width="1px" bg="gray.300" mr="6"></Box>
        <Flex flexDir="column" justify="center">
          <Flex align="center" mb="2">
            <Icon as={FaClock} mr="2" color="#748094" />
            <Text fontSize="md">{event.time}</Text>
          </Flex>
          <Flex align="center">
            <Icon as={FaMapMarkerAlt} mr="2" color="#748094" />
            {event.location === undefined ? (
              <Link
                href={event.hangoutLink}
                isExternal
                _hover={{ color: "#1A5BF6" }}
                fontSize="md"
              >
                G Meet
              </Link>
            ) : (
              <Text>{event.location}</Text>
            )}
          </Flex>
        </Flex>
        <Flex ml="auto" flexDir="column" align="flex-end" justify="center">
          <Flex align="center" justify="flex-end" mb="2" width="100%">
            <Flex align="center" mr="4">
              <Text fontSize="sm" mr="2">
                Notes:
              </Text>
              <Select
                placeholder="Fit Call"
                size="sm"
                width="100px"
                bg="gray.100"
                borderRadius="full"
                _hover={{ bg: "gray.200" }}
              >
                <option value="fit-call">Demo</option>
                <option value="negotiation">Negotiation</option>
                <option value="integration">Integration</option>
                <option value="retention">Retention</option>
                <option value="other">Other</option>
              </Select>
            </Flex>
            <Flex align="center">
              <FormLabel htmlFor="record-switch" mb="0" mr="2" fontSize="sm">
                Record:
              </FormLabel>
              <Switch
                id="record-switch"
                size="sm"
                colorScheme="blue"
                defaultChecked={true}
                sx={{
                  "& .chakra-switch__track[data-checked]": {
                    bg: "#1A5BF6",
                  },
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default EventCard;