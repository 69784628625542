// src/components/Inbox/EmailDetailsSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Input, VStack } from '@chakra-ui/react';
import EditableEmailRecipients from './EditableEmailRecipients';

const EmailDetailsSection = ({ emailSubject, onSubjectChange, recipients, onRecipientsChange }) => (
  <Box>
    <Text fontSize="md" fontWeight="semibold" mb={2}>
      Generated Reply
    </Text>
    <VStack spacing={4} align="stretch">
      <Input
        value={emailSubject}
        onChange={onSubjectChange}
        placeholder="Enter email subject"
        variant="outline"
        bg="white"
        borderRadius="md"
        borderWidth="1px"
        borderColor="gray.300"
        _hover={{ borderColor: 'gray.400' }}
        _focus={{ borderColor: 'gray.500', boxShadow: 'outline' }}
      />
      <Box
        borderWidth="1px" // Added border around recipients
        borderColor="gray.300"
        borderRadius="md"
        p={4}
      >
        <EditableEmailRecipients
          recipients={recipients}
          onChange={onRecipientsChange}
        />
      </Box>
    </VStack>
  </Box>
);

EmailDetailsSection.propTypes = {
  emailSubject: PropTypes.string.isRequired,
  onSubjectChange: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    recipient_type: PropTypes.oneOf(['to', 'cc', 'bcc']).isRequired
  })).isRequired,
  onRecipientsChange: PropTypes.func.isRequired,
};

export default EmailDetailsSection;
