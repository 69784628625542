// src/components/Inbox/EditableEmailRecipients.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Text,
  Flex,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';

const EditableEmailRecipients = ({ recipients = [], onChange }) => {
  const [sections, setSections] = useState({
    to: true,
    cc: recipients.some((r) => r.recipient_type === 'cc'),
    bcc: recipients.some((r) => r.recipient_type === 'bcc'),
  });

  const [inputValues, setInputValues] = useState({
    to: '',
    cc: '',
    bcc: '',
  });

  const [isAdding, setIsAdding] = useState({
    to: false,
    cc: false,
    bcc: false,
  });

  useEffect(() => {
    setSections({
      to: true,
      cc: recipients.some((r) => r.recipient_type === 'cc'),
      bcc: recipients.some((r) => r.recipient_type === 'bcc'),
    });
  }, [recipients]);

  const handleAdd = (type) => {
    if (!inputValues[type].trim()) return;

    const emails = inputValues[type]
      .split(',')
      .map((email) => {
        const emailParts = email.trim().match(/^(.*?)(?:<(.+?)>)?$/);
        const name = emailParts[2] ? emailParts[1].trim() : '';
        const emailAddress = emailParts[2]
          ? emailParts[2].trim()
          : emailParts[1].trim();
        return {
          email: emailAddress,
          name,
          recipient_type: type,
        };
      })
      .filter((email) => email.email);

    onChange([...recipients, ...emails]);
    setInputValues((prev) => ({ ...prev, [type]: '' }));
    setIsAdding((prev) => ({ ...prev, [type]: false }));
    setSections((prev) => ({
      ...prev,
      [type]: true,
    }));
  };

  const removeRecipient = (recipientToRemove) => {
    const updatedRecipients = recipients.filter(
      (r) => r !== recipientToRemove
    );
    onChange(updatedRecipients);
  };

  const renderRecipientBubble = (recipient, index) => {
    const colorScheme = 'blue';

    return (
      <Tooltip
        key={`${recipient.email}-${index}`}
        label={
          recipient.name
            ? `${recipient.name} <${recipient.email}>`
            : recipient.email
        }
        placement="top"
      >
        <Flex
          align="center"
          bg={`${colorScheme}.50`}
          color={`${colorScheme}.700`}
          px={3}
          py={1}
          borderRadius="md"
          borderWidth="1px"
          borderColor={`${colorScheme}.200`}
          mr={2}
          _hover={{
            bg: `${colorScheme}.100`,
          }}
          maxW="200px"
        >
          <Text fontSize="sm" fontWeight="medium" noOfLines={1} mr={2}>
            {recipient.name || recipient.email}
          </Text>
          <IconButton
            aria-label="Remove recipient"
            icon={<CloseIcon />}
            size="xs"
            variant="ghost"
            onClick={() => removeRecipient(recipient)}
          />
        </Flex>
      </Tooltip>
    );
  };

  const renderSection = (type, label) => {
    const recipientsForType = recipients.filter(
      (r) => r.recipient_type === type
    );

    return (
      <Flex key={type} align="center" mr={4} flexWrap="wrap">
        <Text color="gray.600" mr={2}>
          {label}
        </Text>
        {recipientsForType.map((r, index) =>
          renderRecipientBubble(r, index)
        )}
        {isAdding[type] ? (
          <Input
            value={inputValues[type]}
            variant="flushed"
            placeholder="Enter email addresses"
            onChange={(e) =>
              setInputValues((prev) => ({
                ...prev,
                [type]: e.target.value,
              }))
            }
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' ||
                (e.key === ',' && !e.shiftKey)
              ) {
                e.preventDefault();
                handleAdd(type);
              }
            }}
            onBlur={() => handleAdd(type)}
            flex="1"
            minW="150px"
            autoFocus
            size="sm"
            mr={2}
            mt="2px"
          />
        ) : (
          <IconButton
            aria-label={`Add ${label}`}
            icon={<AddIcon />}
            size="xs"
            onClick={() =>
              setIsAdding((prev) => ({ ...prev, [type]: true }))
            }
            variant="ghost"
            colorScheme="blue"
            ml={1}
          />
        )}
      </Flex>
    );
  };

  return (
    <Flex flexWrap="wrap">
      {sections.to && renderSection('to', 'To:')}
      {sections.cc && renderSection('cc', 'Cc:')}
      {sections.bcc && renderSection('bcc', 'Bcc:')}
    </Flex>
  );
};

EditableEmailRecipients.propTypes = {
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string,
      recipient_type: PropTypes.oneOf(['to', 'cc', 'bcc'])
        .isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};

export default EditableEmailRecipients;
