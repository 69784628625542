// src/components/Deals/OverviewSection.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { 
  FaBuilding, 
  FaDollarSign, 
  FaClipboardList, 
  FaClock, 
  FaPlay, 
  FaFlagCheckered 
} from 'react-icons/fa';
import { parseISO, format } from 'date-fns';

const OverviewTag = ({ icon, label, value, colorScheme = "gray" }) => (
  <Tooltip label={`${label}: ${value}`} placement="top">
    <Flex
      align="center"
      bg={`${colorScheme}.50`}
      color={`${colorScheme}.700`}
      px={3}
      py={2}
      borderRadius="md"
      borderWidth="1px"
      borderColor={`${colorScheme}.200`}
      _hover={{
        bg: `${colorScheme}.100`,
      }}
    >
      <Icon as={icon} mr={2} />
      <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
        {value}
      </Text>
    </Flex>
  </Tooltip>
);

const OverviewSection = ({ 
  accountName, 
  dealName, 
  amount, 
  stage, 
  lastActivityDate,
  startDate, // New Prop
  endDate,   // New Prop
}) => {
  const formattedLastActivityDate = lastActivityDate 
    ? format(parseISO(lastActivityDate), 'MMMM do, yyyy') // e.g., December 4th, 2024
    : 'N/A';
  
  const formattedStartDate = startDate 
    ? format(parseISO(startDate), 'MMMM do, yyyy') 
    : 'N/A';
  
  const formattedEndDate = endDate 
    ? format(parseISO(endDate), 'MMMM do, yyyy') 
    : 'N/A';

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Overview
      </Text>
      <Flex gap={3} flexWrap="wrap">
        <OverviewTag
          icon={FaBuilding}
          label="Account"
          value={accountName || 'Unknown Account'}
          colorScheme="blue"
        />
        
        <OverviewTag
          icon={FaClipboardList}
          label="Deal Name"
          value={dealName || 'Unnamed Deal'}
          colorScheme="green"
        />

        <OverviewTag
          icon={FaDollarSign}
          label="Amount"
          value={`$${amount}`}
          colorScheme="purple"
        />

        <OverviewTag
          icon={FaClipboardList}
          label="Stage"
          value={stage || 'N/A'}
          colorScheme="orange"
        />

        <OverviewTag
          icon={FaClock}
          label="Last Activity"
          value={formattedLastActivityDate}
          colorScheme="teal"
        />

        {/* Updated Tags for Start and End Dates */}
        <OverviewTag
          icon={FaPlay}
          label="Start"
          value={formattedStartDate}
          colorScheme="cyan"
        />

        <OverviewTag
          icon={FaFlagCheckered}
          label="End"
          value={formattedEndDate}
          colorScheme="red"
        />
      </Flex>
    </Box>
  );
};

OverviewSection.propTypes = {
  accountName: PropTypes.string.isRequired,
  dealName: PropTypes.string,
  amount: PropTypes.string,
  stage: PropTypes.string,
  lastActivityDate: PropTypes.string,
  startDate: PropTypes.string, // New Prop
  endDate: PropTypes.string,   // New Prop
};

OverviewSection.defaultProps = {
  dealName: 'Unnamed Deal',
  amount: '0.00',
  stage: 'N/A',
  lastActivityDate: null,
  startDate: null, // New Default
  endDate: null,   // New Default
};

OverviewTag.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorScheme: PropTypes.string,
};

export default OverviewSection;
