// src/components/Contacts/ContactActivityItem.jsx

import React, { useState } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Icon,
  IconButton,
  Collapse,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { MdEmail, MdPhone } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import EmailCard from "./EmailCard";

/**
 * Helper function to format dates in "Friday Oct. 11, 2024" format.
 */
const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);

  // Options for weekday and month
  const weekday = date.toLocaleDateString(undefined, { weekday: "long" });
  const month = date.toLocaleDateString(undefined, { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();

  // Ensure month abbreviation has a period
  const monthWithPeriod = month.endsWith(".") ? month : `${month}.`;

  return `${weekday} ${monthWithPeriod} ${day}, ${year}`;
};

/**
 * Determine the activity category based on the activity type.
 */
const getActivityCategory = (activity) => {
  const activityType = activity.activity_type
    ? activity.activity_type.toLowerCase()
    : "";

  switch (activityType) {
    case "email":
      return "email";
    case "call":
      return "call";
    case "task":
      return "task";
    default:
      return "other";
  }
};

/**
 * Return the appropriate icon for the activity category.
 */
const getIcon = (activity) => {
  const category = getActivityCategory(activity);

  switch (category) {
    case "email":
      return MdEmail;
    case "call":
      return MdPhone;
    case "task":
      return FaTasks;
    default:
      return FaTasks; // Default icon
  }
};

/**
 * Generate a user-friendly title for the activity.
 */
const generateTitle = (activity) => {
  const userName = [
    activity.user_name?.charAt(0).toUpperCase() + activity.user_name?.slice(1),
    activity.user_last_name?.charAt(0).toUpperCase() +
      activity.user_last_name?.slice(1),
  ]
    .filter(Boolean)
    .join(" ") || "Unknown User";

  const category = getActivityCategory(activity);

  switch (category) {
    case "email":
      return (
        <Text>
          <strong>{userName}</strong> sent an email:{" "}
          <strong>{activity.title || "No Subject"}</strong>
        </Text>
      );
    case "call":
      return (
        <Text>
          <strong>{userName}</strong> made a call:{" "}
          <strong>{activity.title || "No Title"}</strong>
        </Text>
      );
    case "task":
      return (
        <Text>
          <strong>{userName}</strong> created a task:{" "}
          <strong>{activity.title || "No Title"}</strong>
        </Text>
      );
    default:
      return (
        <Text>
          <strong>{userName}</strong> performed an activity:{" "}
          <strong>{activity.title || "No Title"}</strong>
        </Text>
      );
  }
};

/**
 * ContactActivityItem Component
 *
 * @param {object} props
 * @param {object} props.activity - The activity object to display.
 * @returns {JSX.Element}
 */
const ContactActivityItem = ({ activity }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  /**
   * Render expanded content based on the activity category.
   */
  const renderExpandedContent = () => {
    const category = getActivityCategory(activity);

    switch (category) {
      case "email":
        return <EmailCard activity={activity} />;
      case "call":
        return (
          <Box p={4} bg="gray.100" borderRadius="md">
            <Text fontWeight="bold">{activity.title}</Text>
            <Text>
              Call Status:{" "}
              {activity.classification?.status || "Status not available."}
            </Text>
            <Text>
              Call Details:{" "}
              {activity.description || "No additional details provided."}
            </Text>
          </Box>
        );
      case "task":
        return (
          <Box p={4} bg="gray.100" borderRadius="md">
            <Text fontWeight="bold">{activity.title}</Text>
            <Text>
              Task Description:{" "}
              {activity.description || "No task details provided."}
            </Text>
          </Box>
        );
      default:
        return (
          <Box p={4} bg="gray.100" borderRadius="md">
            <Text fontWeight="bold">{activity.title}</Text>
            <Text>
              Details: {activity.description || "No additional details."}
            </Text>
          </Box>
        );
    }
  };

  return (
    <Box width="100%">
      <HStack
        width="100%"
        spacing={4}
        p={3}
        bg="white"
        boxShadow="sm"
        justifyContent="space-between"
        alignItems="center"
        fontSize="sm"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.200"
      >
        {/* Activity Icon */}
        <Icon as={getIcon(activity)} color="blue.500" boxSize={6} />

        {/* Activity Title and Timestamp */}
        <VStack align="start" flex="1" spacing={0}>
          {generateTitle(activity)}
          <Text fontSize="xs" color="gray.500">
            {formatDate(activity.activity_date)}
          </Text>
        </VStack>

        {/* Expand/Collapse Button */}
        <IconButton
          icon={isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
          aria-label="Toggle details"
          variant="outline"
          borderRadius="full"
          size="sm"
          border="1px solid"
          borderColor="gray.300"
          onClick={toggleExpand}
        />
      </HStack>

      {/* Expanded Content */}
      <Collapse in={isExpanded} animateOpacity>
        <Box mt={2}>{renderExpandedContent()}</Box>
      </Collapse>
    </Box>
  );
};

ContactActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ContactActivityItem;
