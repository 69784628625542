// src/components/Contacts/DealTag.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { MdTrackChanges } from 'react-icons/md';
import { FaExpandAlt } from 'react-icons/fa';

/**
 * DealTag Component
 * Styled to resemble OverviewTags with specific hover behavior.
 *
 * @param {Object} props
 * @param {string} props.dealName - Name of the deal.
 * @param {Function} props.onClick - Function to call when the tag is clicked.
 * @returns {JSX.Element}
 */
const DealTag = ({ dealName, onClick }) => (
  <Tooltip label="View Deal Details" placement="top">
    <Flex
      align="center"
      bg="gray.50"
      color="black"
      px={3}
      py={2}
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.200"
      cursor="pointer"
      onClick={onClick}
      role="group"
      _hover={{
        bg: "gray.100",
      }}
      height="40px" // Ensures consistent height
      minWidth="120px" // Ensures a minimum width
    >
      {/* MdTrackChanges Icon - visible by default, hidden on hover */}
      <Icon
        as={MdTrackChanges}
        mr={2}
        _groupHover={{ display: 'none' }}
        color="gray.500"
        boxSize={4}
      />
      {/* FaExpandAlt Icon - hidden by default, visible on hover */}
      <Icon
        as={FaExpandAlt}
        mr={2}
        display="none"
        _groupHover={{ display: 'block', color: 'blue.500' }}
        boxSize={4}
      />
      <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
        {dealName || 'Unnamed Deal'}
      </Text>
    </Flex>
  </Tooltip>
);

DealTag.propTypes = {
  dealName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DealTag;
