// src/components/Deals/DealActivityTimeline.jsx

import React from 'react';
import { VStack } from '@chakra-ui/react';
import DealActivityItem from './DealActivityItem';

const DealActivityTimeline = ({ activities }) => {
  return (
    <VStack spacing={4} align="stretch">
      {activities.map((activity) => (
        <DealActivityItem key={activity.id} activity={activity} />
      ))}
    </VStack>
  );
};

export default DealActivityTimeline;
