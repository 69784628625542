// src/components/Inbox/extractEmailContent.js
export function extractFirstEmailMessage(htmlContent) {
  if (!htmlContent) return "";

  // Initialize DOMParser
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Define classes that indicate quoted content
  const classesToRemove = [
    "gmail_quote",
    "gmail_attr",
    "yahoo_quoted",
    "OutlookMessageHeader",
  ];

  // Remove elements with specified classes
  classesToRemove.forEach((cls) => {
    const elements = doc.querySelectorAll(`.${cls}`);
    elements.forEach((el) => el.remove());
  });

  // Remove all blockquote elements
  const blockquotes = doc.querySelectorAll("blockquote");
  blockquotes.forEach((el) => el.remove());

  // Optionally, remove any <br> tags that are immediately followed by <div> or other tags to clean up spacing
  const brs = doc.querySelectorAll("br");
  brs.forEach((br) => {
    const next = br.nextSibling;
    if (
      next &&
      (next.nodeType === Node.ELEMENT_NODE || next.nodeType === Node.TEXT_NODE)
    ) {
      br.remove();
    }
  });

  // Return the cleaned HTML
  return doc.body.innerHTML.trim();
}

export function extractEmailContent(htmlContent) {
  if (!htmlContent) return "";

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Remove common email client quote markers and signatures
  const elementsToRemove = [
    ".gmail_quote",
    ".gmail_attr",
    ".gmail_signature",
    ".yahoo_quoted",
    ".OutlookMessageHeader",
    "blockquote",
    '[data-marker="__QUOTED_TEXT__"]',
    "img", // Remove images as they're usually tracking pixels or signatures
    "style", // Remove style tags
  ];

  // Remove unwanted elements
  elementsToRemove.forEach((selector) => {
    doc.querySelectorAll(selector).forEach((el) => el.remove());
  });

  // Clean up empty divs and unnecessary spacing
  doc.querySelectorAll("div:empty").forEach((el) => el.remove());

  // Remove all attributes from remaining elements except 'href' from links
  doc.querySelectorAll("*").forEach((el) => {
    const attrs = Array.from(el.attributes);
    attrs.forEach((attr) => {
      if (attr.name !== "href" || el.tagName.toLowerCase() !== "a") {
        el.removeAttribute(attr.name);
      }
    });
  });

  // Get the cleaned HTML content
  let content = doc.body.innerHTML;

  return content;
}
