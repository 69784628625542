// src/components/Contacts/ContactsTable.jsx

import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
  Flex,
} from "@chakra-ui/react";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { MdPerson } from 'react-icons/md';
import { FaExpandAlt } from 'react-icons/fa'; // Import FaExpandAlt for hover icon
import HoverBox from "../Shared/HoverBox";
import HighlightText from "./HighlightText"; // Use the imported HighlightText
import PropTypes from "prop-types";
import { formatDistanceToNow } from "date-fns"; // Import formatDistanceToNow

/**
 * Helper function to format the date into relative time (e.g., "3 days ago").
 * @param {string} dateString - ISO date string.
 * @returns {string} - Formatted relative time string.
 */
const formatRelativeTime = (dateString) => {
  if (!dateString) return "No activity";

  return `${formatDistanceToNow(new Date(dateString), { addSuffix: true })}`;
};

/**
 * ContactsTable Component
 * Displays contacts data in a table format.
 *
 * @param {Object} props - Component props
 * @param {Array} props.contacts - Array of contact objects
 * @param {Function} props.onViewDetails - Function to handle viewing contact details
 * @param {Function} props.onSort - Function to handle sorting when a header is clicked
 * @param {Object} props.sortConfig - Current sort configuration
 * @param {string} props.searchQuery - Current search query to highlight
 * @returns {JSX.Element}
 */
const ContactsTable = ({ contacts, onViewDetails, onSort, sortConfig, searchQuery }) => {
  
  /**
   * Renders the sort icon based on the current sort configuration.
   * @param {string} columnKey - The key of the column.
   * @returns {JSX.Element|null}
   */
  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    if (sortConfig.direction === 'ascending') {
      return <FaSortUp />;
    }
    return <FaSortDown />;
  };

  // Define fixed widths for each column in pixels
  const columnWidths = {
    name: "200px",
    email: "250px",
    crm_account_name: "200px",
    source: "150px",
    status: "150px",
    last_activity_date: "180px", // New column
  };

  return (
    <Box bg="white">
      <Table variant="simple" bg="white" width="100%" tableLayout="fixed">
        <Thead bg="white">
          <Tr>
            {/* Name Column */}
            <Th
              cursor="pointer"
              onClick={() => onSort('name')}
              width={columnWidths.name}
              whiteSpace="nowrap"
              position="relative"
              _hover={{ bg: "gray.100" }}
              role="group" // Enable group hover
            >
              <Flex align="center">
                <Box mr={2}>Name</Box>
                {/* Sort Icon */}
                <Box
                  display="flex"
                  alignItems="center"
                  opacity={sortConfig.key === 'name' ? 1 : 0}
                  transition="opacity 0.2s"
                  _groupHover={{ opacity: 1 }} // Show on hover
                >
                  {renderSortIcon('name')}
                </Box>
              </Flex>
            </Th>

            {/* Email Column */}
            <Th
              cursor="pointer"
              onClick={() => onSort('email')}
              width={columnWidths.email}
              whiteSpace="nowrap"
              position="relative"
              _hover={{ bg: "gray.100" }}
              role="group"
            >
              <Flex align="center">
                <Box mr={2}>Email</Box>
                <Box
                  display="flex"
                  alignItems="center"
                  opacity={sortConfig.key === 'email' ? 1 : 0}
                  transition="opacity 0.2s"
                  _groupHover={{ opacity: 1 }}
                >
                  {renderSortIcon('email')}
                </Box>
              </Flex>
            </Th>

            {/* Account Column */}
            <Th
              cursor="pointer"
              onClick={() => onSort('crm_account_name')}
              width={columnWidths.crm_account_name}
              whiteSpace="nowrap"
              position="relative"
              _hover={{ bg: "gray.100" }}
              role="group"
            >
              <Flex align="center">
                <Box mr={2}>Account</Box>
                <Box
                  display="flex"
                  alignItems="center"
                  opacity={sortConfig.key === 'crm_account_name' ? 1 : 0}
                  transition="opacity 0.2s"
                  _groupHover={{ opacity: 1 }}
                >
                  {renderSortIcon('crm_account_name')}
                </Box>
              </Flex>
            </Th>

            {/* Source Column */}
            <Th
              cursor="pointer"
              onClick={() => onSort('source')}
              width={columnWidths.source}
              whiteSpace="nowrap"
              position="relative"
              _hover={{ bg: "gray.100" }}
              role="group"
            >
              <Flex align="center">
                <Box mr={2}>Source</Box>
                <Box
                  display="flex"
                  alignItems="center"
                  opacity={sortConfig.key === 'source' ? 1 : 0}
                  transition="opacity 0.2s"
                  _groupHover={{ opacity: 1 }}
                >
                  {renderSortIcon('source')}
                </Box>
              </Flex>
            </Th>

            {/* Status Column */}
            <Th
              cursor="pointer"
              onClick={() => onSort('isActive')} // Updated sort key
              width={columnWidths.status}
              whiteSpace="nowrap"
              position="relative"
              _hover={{ bg: "gray.100" }}
              role="group"
            >
              <Flex align="center">
                <Box mr={2}>Status</Box>
                <Box
                  display="flex"
                  alignItems="center"
                  opacity={sortConfig.key === 'isActive' ? 1 : 0}
                  transition="opacity 0.2s"
                  _groupHover={{ opacity: 1 }}
                >
                  {renderSortIcon('isActive')}
                </Box>
              </Flex>
            </Th>

            {/* Last Activity Column */}
            <Th
              cursor="pointer"
              onClick={() => onSort('last_activity_date')}
              width={columnWidths.last_activity_date}
              whiteSpace="nowrap"
              position="relative"
              _hover={{ bg: "gray.100" }}
              role="group"
            >
              <Flex align="center">
                <Box mr={2}>Last Activity</Box>
                <Box
                  display="flex"
                  alignItems="center"
                  opacity={sortConfig.key === 'last_activity_date' ? 1 : 0}
                  transition="opacity 0.2s"
                  _groupHover={{ opacity: 1 }}
                >
                  {renderSortIcon('last_activity_date')}
                </Box>
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {contacts.map((contact) => (
            <Tr key={contact.id} _hover={{ bg: "gray.50" }}>
              {/* Name Column */}
              <Td>
                <HoverBox
                  icon={MdPerson}
                  hoverIcon={FaExpandAlt} // Updated hover icon to FaExpandAlt
                  onClick={() => onViewDetails(contact)}
                  tooltipLabel="View Contact Details"
                >
                  {/* Apply transform to move icons down by 2px */}
                  <Box transform="translateY(-1px)">
                    <HighlightText
                      text={contact.name || "Unnamed Contact"}
                      query={searchQuery}
                    />
                  </Box>
                </HoverBox>
              </Td>

              {/* Email Column */}
              <Td>
                <Box isTruncated maxWidth="100%" fontSize="md">
                  <HighlightText
                    text={contact.email || "—"}
                    query={searchQuery}
                  />
                </Box>
              </Td>

              {/* Account Column */}
              <Td>
                <Box isTruncated maxWidth="100%" fontSize="md">
                  <HighlightText
                    text={contact.crm_account_name || "—"}
                    query={searchQuery}
                  />
                </Box>
              </Td>

              {/* Source Column */}
              <Td>
                <Box isTruncated maxWidth="100%" fontSize="md">
                  <HighlightText
                    text={contact.source || "—"}
                    query={searchQuery}
                  />
                </Box>
              </Td>

              {/* Status Column */}
              <Td>
                <Box display="flex" alignItems="center" fontSize="md">
                  <Box
                    height="10px"
                    width="10px"
                    borderRadius="full"
                    bg={contact.isActive ? "blue.500" : "gray.500"} // Blue for active, Gray for inactive
                    mr={2}
                    transform="translateY(1px)" // Move icon down by 1px
                  ></Box>
                  <Text color="black" isTruncated maxWidth="100%">
                    {contact.isActive ? "Active" : "Inactive"}
                  </Text>
                </Box>
              </Td>

              {/* Last Activity Column */}
              <Td>
                {contact.last_activity_date ? (
                  <Box display="flex" alignItems="center" fontSize="md">
                    <Text isTruncated maxWidth="100%">
                      {formatRelativeTime(contact.last_activity_date)}
                    </Text>
                  </Box>
                ) : (
                  "—"
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

// Define PropTypes for type checking
ContactsTable.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(['ascending', 'descending']),
  }).isRequired,
  searchQuery: PropTypes.string, // New Prop
};

// Define default props
ContactsTable.defaultProps = {
  searchQuery: "",
};

export default ContactsTable;
