import React, { useCallback, useState, useMemo } from "react";
import {
  Box,
  SimpleGrid,
  Spinner,
  Center,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Replaced useHistory with useNavigate
import { useAuthData } from "auth-context";
import DealsTable from "./DealsTable";
import PaginationControls from "../Shared/PaginationControls";
import DealsFilterControls from "./DealsFilterControls"; // Import the enhanced component
import useFetchDeals from "../hooks/useFetchDeals";
import { useDebounce } from "use-debounce"; // Import useDebounce

const ITEMS_PER_PAGE = 10;

const Deals = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate(); // Replaced useHistory with useNavigate
  const { deals, isLoading, isError } = useFetchDeals(accountID);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

  // Filter states
  const [currentStatusFilter, setCurrentStatusFilter] = useState("active"); // Set default to 'active'
  const [searchQuery, setSearchQuery] = useState("");

  // Debounce the search query by 300ms
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Generate search suggestions based on deal names
  const searchSuggestions = useMemo(() => {
    const uniqueNames = Array.from(new Set(deals.map((deal) => deal.deal_name || ""))).sort();
    return uniqueNames;
  }, [deals]);

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        // Toggle direction
        return {
          key,
          direction: prevState.direction === "ascending" ? "descending" : "ascending",
        };
      } else {
        // New sort
        return { key, direction: "ascending" };
      }
    });
  };

  // Filter deals based on currentStatusFilter and debouncedSearchQuery
  const filteredDeals = useMemo(() => {
    let filtered = deals;

    // Status Filter based on last_activity_date
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

    if (currentStatusFilter === "active") {
      filtered = filtered.filter((deal) => {
        if (!deal.last_activity_date) return false; // Exclude if no last_activity_date
        const lastActivity = new Date(deal.last_activity_date);
        return lastActivity >= twoWeeksAgo;
      });
    } else if (currentStatusFilter === "inactive") {
      filtered = filtered.filter((deal) => {
        if (!deal.last_activity_date) return true; // Consider as inactive if no last_activity_date
        const lastActivity = new Date(deal.last_activity_date);
        return lastActivity < twoWeeksAgo;
      });
    }
    // 'all' shows all deals, no filtering needed

    // Search Filter
    if (debouncedSearchQuery.trim() !== "") {
      const query = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(
        (deal) =>
          (deal.deal_name && deal.deal_name.toLowerCase().includes(query)) ||
          (deal.crm_account_name && deal.crm_account_name.toLowerCase().includes(query)) ||
          (deal.user_name && deal.user_name.toLowerCase().includes(query)) ||
          (deal.user_last_name && deal.user_last_name.toLowerCase().includes(query)) ||
          (deal.pipeline_name && deal.pipeline_name.toLowerCase().includes(query)) ||
          (deal.lead_source && deal.lead_source.toLowerCase().includes(query))
      );
    }

    return filtered;
  }, [deals, currentStatusFilter, debouncedSearchQuery]);

  // Sort deals
  const sortedDeals = useMemo(() => {
    if (!sortConfig.key) return filteredDeals;

    const sorted = [...filteredDeals].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle different data types
      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === "descending") {
      sorted.reverse();
    }
    return sorted;
  }, [filteredDeals, sortConfig]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(sortedDeals.length / ITEMS_PER_PAGE) || 1;
  }, [sortedDeals.length]);

  // Get current page's deals
  const currentDeals = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedDeals.slice(startIndex, endIndex);
  }, [currentPage, sortedDeals]);

  /**
   * Handles the navigation to the deal details page.
   * @param {Object} deal - The deal object.
   */
  const handleViewDetails = useCallback(
    (deal) => {
      navigate(`/admin/deals/${deal.id}`); // Replaced history.push with navigate
    },
    [navigate]
  );

  /**
   * Handles navigating to the previous page.
   */
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  /**
   * Handles navigating to the next page.
   */
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Reset to first page if filtered data, sorting, or search changes
  React.useEffect(() => {
    setCurrentPage(1);
  }, [filteredDeals, sortConfig, debouncedSearchQuery]);

  if (isLoading) {
    return (
      <Center pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }}>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4} color="red.500">
        <Text>Failed to load deals. Please try again later.</Text>
      </Box>
    );
  }

  if (deals.length === 0) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4}>
        <Text>No deals available.</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
      {/* Title */}
      <Text fontSize="3xl" mb={4} fontWeight="bold">
        Deals
      </Text>

      {/* Filter Controls */}
      <DealsFilterControls
        currentStatusFilter={currentStatusFilter}
        setCurrentStatusFilter={setCurrentStatusFilter}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchSuggestions={searchSuggestions}
      />

      {/* Deals Table */}
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
          <DealsTable
            deals={currentDeals}
            onViewDetails={handleViewDetails}
            onSort={handleSort}
            sortConfig={sortConfig}
            searchQuery={debouncedSearchQuery} // Pass the debounced search query
          />
        </Box>
      </SimpleGrid>

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </Box>
  );
};

export default Deals;
