import React, { useState, useMemo, useCallback } from "react";
import { Box, SimpleGrid, Spinner, Center, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Replaced useHistory with useNavigate
import { useAuthData } from "auth-context"; // Ensure this context provides user data with account_ID and user_ID
import InboxTable from "./InboxTable";
import PaginationControls from "../Shared/PaginationControls";
import InboxFilterControls from "./InboxFilterControls";
import useFetchInbox from "../hooks/useFetchInbox";
import { useDebounce } from "use-debounce";

const ITEMS_PER_PAGE = 10;

const Inbox = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const userID = userData?.user_ID || "";
  const navigate = useNavigate(); // Replace useHistory with useNavigate

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  // Sorting state
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

  // Filter states
  const [currentStatusFilter, setCurrentStatusFilter] = useState("draft"); // Set default to 'draft'
  const [searchQuery, setSearchQuery] = useState("");

  // Debounce the search query by 300ms
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  // Fetch inbox items
  const { inboxItems, isLoading, isError } = useFetchInbox(accountID, userID);

  // Generate search suggestions based on subjects
  const searchSuggestions = useMemo(() => {
    const uniqueSubjects = Array.from(new Set(inboxItems.map((item) => item.subject || ""))).sort();
    return uniqueSubjects;
  }, [inboxItems]);

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        // Toggle direction
        return {
          key,
          direction: prevState.direction === "ascending" ? "descending" : "ascending",
        };
      } else {
        // New sort
        return { key, direction: "ascending" };
      }
    });
  };

  // Filter inbox items based on currentStatusFilter and debouncedSearchQuery
  const filteredInboxItems = useMemo(() => {
    let filtered = inboxItems;

    // Status Filter
    if (currentStatusFilter !== "all") {
      filtered = filtered.filter((item) => item.status === currentStatusFilter);
    }

    // Search Filter
    if (debouncedSearchQuery.trim() !== "") {
      const query = debouncedSearchQuery.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          (item.subject && item.subject.toLowerCase().includes(query)) ||
          (item.task_type && item.task_type.toLowerCase().includes(query)) ||
          (item.task_subtype && item.task_subtype.toLowerCase().includes(query))
      );
    }

    return filtered;
  }, [inboxItems, currentStatusFilter, debouncedSearchQuery]);

  // Sort inbox items
  const sortedInboxItems = useMemo(() => {
    if (!sortConfig.key) return filteredInboxItems;

    const sorted = [...filteredInboxItems].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Handle different data types
      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue - bValue;
      }
      if (Date.parse(aValue) && Date.parse(bValue)) {
        return new Date(aValue) - new Date(bValue);
      }
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      }
      return 0;
    });

    if (sortConfig.direction === "descending") {
      sorted.reverse();
    }
    return sorted;
  }, [filteredInboxItems, sortConfig]);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(sortedInboxItems.length / ITEMS_PER_PAGE) || 1;
  }, [sortedInboxItems.length]);

  // Get current page's inbox items
  const currentInboxItems = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return sortedInboxItems.slice(startIndex, endIndex);
  }, [currentPage, sortedInboxItems]);

  /**
   * Handles the navigation to the inbox item details page.
   * @param {Object} item - The inbox item object.
   */
  const handleViewDetails = useCallback(
    (item) => {
      navigate(`/admin/inbox/${item.id}`); // Use navigate instead of history.push
    },
    [navigate]
  );

  /**
   * Handles navigating to the previous page.
   */
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  /**
   * Handles navigating to the next page.
   */
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  // Reset to first page if filters, sorting, or search changes
  React.useEffect(() => {
    setCurrentPage(1);
  }, [filteredInboxItems, sortConfig, debouncedSearchQuery]);

  if (isLoading) {
    return (
      <Center pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }}>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4} color="red.500">
        <Text>Failed to load inbox items. Please try again later.</Text>
      </Box>
    );
  }

  if (inboxItems.length === 0) {
    return (
      <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} p={4}>
        <Text>No inbox items available.</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }} px={4}>
      {/* Title */}
      <Text fontSize="3xl" mb={4} fontWeight="bold">
        Inbox
      </Text>

      {/* Filter Controls */}
      <InboxFilterControls
        currentStatusFilter={currentStatusFilter}
        setCurrentStatusFilter={setCurrentStatusFilter}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchSuggestions={searchSuggestions}
      />

      {/* Inbox Table */}
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: "20px", xl: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
          <InboxTable
            inboxItems={currentInboxItems}
            onViewDetails={handleViewDetails}
            onSort={handleSort}
            sortConfig={sortConfig}
            searchQuery={debouncedSearchQuery} // Pass the debounced search query
          />
        </Box>
      </SimpleGrid>

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrevious={handlePreviousPage}
        onNext={handleNextPage}
      />
    </Box>
  );
};

export default Inbox;
