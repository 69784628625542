import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
  Link,
  VStack,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Card from "components/card/Card.js";
import BarChart from "./BarChart";
import { CHART_COLORS } from "../constants";

const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default function BarCard({
  barData,
  dropdown,
  showLabels = true,
  ...rest
}) {
  const textColor = useColorModeValue("gray.700", "white");
  const lightTextColor = useColorModeValue("gray.500", "gray.300");
  const cardColor = useColorModeValue("white", "gray.800");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [selectedBarLabel, setSelectedBarLabel] = useState("");

  if (
    !barData ||
    !barData.datasets ||
    !Array.isArray(barData.datasets) ||
    !barData.labels ||
    !Array.isArray(barData.labels)
  ) {
    return (
      <Card p="20px">
        <Text>No data available</Text>
      </Card>
    );
  }

  const totalsPerLabel = barData.labels.map((_, labelIndex) => {
    return barData.datasets.reduce(
      (sum, dataset) => sum + (dataset.data[labelIndex] || 0),
      0
    );
  });

  const filteredLabels = [];
  const filteredTotals = [];
  const filteredColors = [];
  const filteredDealData = barData.dealData ? [] : null;

  barData.labels.forEach((label, index) => {
    if (totalsPerLabel[index] > 0 || label.toLowerCase() === "connecting") {
      filteredLabels.push(label);
      filteredTotals.push(totalsPerLabel[index]);
      filteredColors.push(CHART_COLORS[index % CHART_COLORS.length]);

      if (barData.dealData) {
        filteredDealData.push(barData.dealData[index]);
      }
    }
  });

  const filteredBarData = {
    labels: filteredLabels,
    datasets: [
      {
        label: barData.datasets[0].label,
        data: filteredTotals,
        backgroundColor: filteredColors,
      },
    ],
    title: barData.title,
    ...(filteredDealData && { dealData: filteredDealData }),
  };

  const handleBarClick = (data) => {
    if (data && data.activeLabelIndex !== undefined) {
      const index = data.activeLabelIndex;
      if (filteredBarData.dealData && filteredBarData.dealData[index]) {
        const dealsForBar = filteredBarData.dealData[index];
        setSelectedDeals(dealsForBar);
        setSelectedBarLabel(filteredBarData.labels[index]);
        setIsModalOpen(true);
      }
    }
  };

  if (filteredLabels.length === 0) {
    return (
      <Card p="20px">
        <Text>No data available</Text>
      </Card>
    );
  }

  const barChartProps = {
    barData: filteredBarData,
    colors: filteredColors,
  };

  if (filteredBarData.dealData) {
    barChartProps.onBarClick = handleBarClick;
  }

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex flexDirection="column" h="100%">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb="15px"
          w="100%"
        >
          <Text color={textColor} fontSize="xl" fontWeight="600">
            {filteredBarData.title || "Activities by Stage"}
          </Text>
          {dropdown && <Box>{dropdown}</Box>}
        </Flex>

        <Box flex="1" mb="15px" h="250px" w="100%">
          <BarChart {...barChartProps} />
        </Box>

        {showLabels && (
          <Card
            bg={cardColor}
            boxShadow={cardShadow}
            w="100%"
            p="15px"
            px="20px"
          >
            <Flex flexWrap="wrap" justifyContent="space-around" w="100%">
              {filteredLabels.map((label, index) => (
                <Flex
                  key={label}
                  direction="column"
                  alignItems="center"
                  minW="80px"
                  mb="10px"
                >
                  <Flex alignItems="center" mb="5px">
                    <Box
                      h="8px"
                      w="8px"
                      bg={filteredColors[index % filteredColors.length]}
                      borderRadius="50%"
                      mr="4px"
                    />
                    <Text fontSize="xs" color={lightTextColor} fontWeight="700">
                      {capitalizeFirstLetter(label)}
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    {filteredTotals[index]}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Card>
        )}
      </Flex>

      {filteredBarData.dealData && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Deals for {selectedBarLabel} Week(s) Since Last Activity
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedDeals && selectedDeals.length > 0 ? (
                <VStack spacing={4} align="stretch">
                  {selectedDeals.map((deal) => (
                    <Box
                      key={deal.deal_id}
                      p={4}
                      borderWidth="1px"
                      borderRadius="md"
                      bg={useColorModeValue("gray.100", "gray.700")}
                    >
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text fontSize="lg" fontWeight="bold">
                          {deal.name}
                        </Text>
                        <Link
                          href={deal.deal_url}
                          color="blue.500"
                          isExternal
                          fontWeight="semibold"
                          display="flex"
                          alignItems="center"
                        >
                          <ExternalLinkIcon mr="2px" /> View Deal
                        </Link>
                      </Flex>
                      <Divider my={2} />
                      <Stack spacing={1}>
                        <HStack>
                          <Text fontWeight="semibold">Pipeline Stage:</Text>
                          <Text>{deal.pipeline_stage_label}</Text>
                        </HStack>
                        <HStack>
                          <Text fontWeight="semibold">Owner:</Text>
                          <Text>
                            {deal.user_name} {deal.user_last_name} (
                            {deal.user_email})
                          </Text>
                        </HStack>
                        <HStack>
                          <Text fontWeight="semibold">Last Activity:</Text>
                          <Text>
                            {new Date(deal.last_activity_date).toLocaleDateString()}{" "}
                            {new Date(deal.last_activity_date).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text fontWeight="semibold">
                            Days Since Last Activity:
                          </Text>
                          <Text>{deal.days_since_last_activity}</Text>
                        </HStack>
                        <HStack>
                          <Text fontWeight="semibold">Deal ID:</Text>
                          <Text>{deal.deal_id}</Text>
                        </HStack>
                      </Stack>
                    </Box>
                  ))}
                </VStack>
              ) : (
                <Text>No deals available</Text>
              )}
            </ModalBody>
            <ModalFooter>
              <Button variant="action" onClick={() => setIsModalOpen(false)}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Card>
  );
}
