import React, { useEffect, useState } from 'react';   
import axios from 'axios';
import { Box, Flex, Icon, Button, useDisclosure, useColorModeValue, Heading } from '@chakra-ui/react';
import { useAuthData } from "auth-context";
import { FaVideo, FaMapMarkerAlt, FaMicrophone } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { useAuth0 } from '@auth0/auth0-react';
import { GetUserTimezone } from "./utils";

import SegmentControl from '../Components/SegmentControl';
import RecordModal from './RecordModal';  // Adjust the path as needed
import Timeline from './Timeline';

export default function MeetingTimeline() {
  const [events, setEvents] = useState([]);
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const userEmail = userData?.email;
  const { isOpen: isRecordOpen, onOpen: onRecordOpen, onClose: onRecordClose } = useDisclosure();
  const [selectedOption, setSelectedOption] = useState('Upcoming');
  const { getAccessTokenSilently } = useAuth0();
  const textColor = useColorModeValue("#56595E", "white");

  useEffect(() => {
    const fetchEvents = async () => {
      if (!accountID || !userID) return;
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });
  
        const target = process.env.REACT_APP_NODE_ENV === 'local' ? 'http://localhost:8080' : 'https://lysto-be-tf.azurewebsites.net';
        const response = await axios.get(`${target}/accounts/${accountID}/users/${userID}/calendar/events`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          params: {
            page_number: 1, // You may want to make this dynamic
            start_time: new Date().toISOString(), // Current time in ISO format
            items_per_page: 10, // You may want to make this dynamic
            status: mapFrontendStatusToBackend(selectedOption),
            time_range: mapFrontendTimeRangeToBackend(selectedOption),
            recorded : selectedOption === 'Recorded' ? true : null
          }
        });
        const transformedEvents = transformEvents(response.data);
        if (selectedOption === 'Past' || selectedOption === 'Recorded') {
          setEvents(transformedEvents.sort((a, b) => b.date - a.date));
        } else {
          setEvents(transformedEvents.sort((a, b) => a.date - b.date));
        }
      } catch (err) {
        setEvents([]);
      }
    };

    fetchEvents();
  }, [accountID, userID, selectedOption, getAccessTokenSilently]);
  
  const mapFrontendStatusToBackend = (frontendStatus) => {
    const statusMap = {
      'Upcoming': 'confirmed',
      'Past': 'confirmed',
      'Pending': 'tentative',
      'Recurring': 'confirmed',
      'Cancelled': 'cancelled',
    };
    return statusMap[frontendStatus] || 'confirmed'; // Default to 'confirmed' if no match
  };

  const mapFrontendTimeRangeToBackend = (frontendTimeRange) => {
    const timeRangeMap = {
      'Upcoming': 'future',
      'Past': 'past',
      'Pending': 'future',
      'Recurring': 'future',
      'Cancelled': 'future',
    };
      return timeRangeMap[frontendTimeRange] || 'all'; // Default to 'confirmed' if no match
  };

  

  const transformEvents = (backendEvents) => {
    const userTimezone = GetUserTimezone();
    return backendEvents.events.map(event => {
      const eventTimezone = event.startTimezone || 'UTC';
      let startTime, endTime, timeString;
  
      try {
        // Parse the date strings in the event's timezone
        startTime = DateTime.fromISO(event.startTime, { zone: eventTimezone });
        endTime = DateTime.fromISO(event.endTime, { zone: eventTimezone });
  
        if (!startTime.isValid || !endTime.isValid) {
          throw new Error('Invalid date');
        }
  
        // Convert times to the user's timezone
        const userStartTime = startTime.setZone(userTimezone);
        const userEndTime = endTime.setZone(userTimezone);
  
        // Format the time string in the user's timezone
        timeString = `${userStartTime.toFormat('h:mm a')} - ${userEndTime.toFormat('h:mm a')}`;
      } catch (error) {
        return null;
      }
  
      // Extract attendees' names or emails
      const attendees = event.attendees?.map(attendee => {
        const attendeeInfo = attendee.split(', ');
        const name = attendeeInfo[0].split(': ')[1];
        const email = attendeeInfo[1].split(': ')[1];
        return name?.trim() || email?.trim() || 'Unknown';
      });
  
      return {
        id: event.id,
        event_overview: { 
          icon: event.location ? FaMapMarkerAlt : FaVideo,
          text: event.location ? "In-Person Meeting" : "Online Meeting"
        },
        title: event.summary || 'Untitled Event',
        description: event.description || "No description provided.",
        status: DateTime.now() < startTime ? "Upcoming" : "Past",
        more: "View Details",
        time: timeString,
        date: startTime.toJSDate(),
        endTime: endTime.toJSDate(),
        recorded: selectedOption === "Recorded" ? true : false,
        hangoutLink: event.hangoutLink || undefined,
        location: event.location || undefined,
        attendees: attendees,
        timezone: eventTimezone
      };
    }).filter(event => event !== null); // Remove any events that failed to process
  };


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Heading as="h2" size="md" mb="20px" color={textColor} pt={5}>
        Meetings
      </Heading>

      <Flex justifyContent="space-between" alignItems="center" mb="30px">
        <SegmentControl
          options={['Upcoming', 'Past', 'Recorded', 'Cancelled']}
          selectedOption={selectedOption}
          onChange={setSelectedOption}
        />
        <Button
          leftIcon={<Icon as={FaMicrophone} />}
          colorScheme="blue"
          variant="solid"
          borderRadius="md"
          px={6}
          onClick={onRecordOpen}
        >
          Record
        </Button>
      </Flex>

      <Timeline initialEvents={events} selectedOption={selectedOption} />
      <RecordModal
        isOpen={isRecordOpen}
        onClose={onRecordClose}
        accountID={accountID}
        userID={userID}
        userEmail={userEmail}
      />
    </Box>
  );
}