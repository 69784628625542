// src/components/Deals/DealActivity.jsx

import React, { useState, useEffect } from "react";
import {
  Box,
  Spinner,
  Center,
  Text,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import DealActivityTimeline from "./DealActivityTimeline";

const DealActivity = ({ accountId, dealId }) => {
  // State
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Hooks
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  // Fetch activities
  const fetchActivities = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const accessToken = await getAccessTokenSilently({
        authorizationParams: { scope: "read:activities" },
      });

      const headers = { Authorization: `Bearer ${accessToken}` };
      const apiBaseUrl = "https://lysto-be-tf.azurewebsites.net";

      const endDate = new Date();
      endDate.setHours(23, 59, 59, 999); // End of today
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1); // One year ago
      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      const apiUrl = `${apiBaseUrl}/accounts/${accountId}/deals/${dealId}/activities`;
      const params = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };

      const response = await axios.get(apiUrl, { headers, params });

      if (response.data && Array.isArray(response.data)) {
        const activities = response.data;
        // Sort activities by date descending
        activities.sort(
          (a, b) => new Date(b.activity_date) - new Date(a.activity_date)
        );
        setActivities(activities);
      } else {
        setActivities([]);
        toast({
          title: "No Activities Found",
          description: "No activities were found for this deal.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.error("Error fetching activities:", err);
      setError("An error occurred while fetching activities.");
      toast({
        title: "Error",
        description: "An error occurred while fetching activities.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accountId && dealId) {
      fetchActivities();
    }
  }, [accountId, dealId]);

  return (
    <Box>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Deal Activity
      </Text>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : error ? (
        <Alert status="error" mt={4}>
          <AlertIcon />
          {error}
        </Alert>
      ) : activities.length > 0 ? (
        <DealActivityTimeline activities={activities} />
      ) : (
        <Text textAlign="center" py={4} color="gray.500">
          No activities found for this deal.
        </Text>
      )}
    </Box>
  );
};

export default DealActivity;
