// SettingsView.js
import React, { useState, useEffect } from "react";
import { Box, Heading, Spinner, Text } from "@chakra-ui/react";
import SegmentControl from "../Components/SegmentControl";
import AccountSection from "./AccountSection";
import IntegrationsSection from "./IntegrationsSection";
import CRMSection from "./CRMSection";
import SmartFollowupsSection from "./SmartFollowupsSection"; // Import the new component
import { useAuthData } from "auth-context"; // Ensure you have this hook available

export default function SettingsView() {
  const userData = useAuthData();
  const userID = userData?.user_ID;
  const [selectedSection, setSelectedSection] = useState("Account");
  const [segmentOptions, setSegmentOptions] = useState([
    "Account",
    "Integrations",
    "CRM",
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const privilegedUserIDs = [6, 8];

  useEffect(() => {
    if (userID !== undefined && userID !== null) {
      // Define the user IDs that have access to additional sections

      if (!privilegedUserIDs.includes(userID)) {
        // If the user is not privileged, restrict the segment options
        setSegmentOptions(["Account", "Integrations"]); // Include "Integrations" for all users
        // If the currently selected section is restricted, default to 'Account'
        if (
          selectedSection !== "Account" &&
          selectedSection !== "Integrations"
        ) {
          setSelectedSection("Account");
        }
      } else {
        // If the user is privileged, allow all sections
        setSegmentOptions(["Account", "Integrations", "Smart Followups"]);
      }

      setIsLoading(false);
    }
  }, [userID, selectedSection]);

  // If user data is still loading, show a loading message or spinner
  if (isLoading) {
    return (
      <Box textAlign="center" p={6}>
        <Spinner size="lg" />
        <Text mt={4} fontSize="md" color="gray.500">
          Loading settings...
        </Text>
      </Box>
    );
  }

  // If userID is not available after loading, you might want to handle it
  if (!userID) {
    return (
      <Box textAlign="center" p={6}>
        <Text fontSize="md" color="gray.500">
          Unable to load user data.
        </Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Heading as="h2" size="md" mb="20px" color="#56595E" pt={5}>
        Settings
      </Heading>

      {/* Segmented Control for selecting different sections */}
      <SegmentControl
        options={segmentOptions}
        selectedOption={selectedSection}
        onChange={setSelectedSection}
      />

      <Box mt={8}>
        {selectedSection === "Account" && <AccountSection />}
        {selectedSection === "Integrations" && <IntegrationsSection />}
        {selectedSection === "CRM" && privilegedUserIDs.includes(userID) && (
          <CRMSection />
        )}
        {selectedSection === "Smart Followups" &&
          privilegedUserIDs.includes(userID) && <SmartFollowupsSection />}
      </Box>
    </Box>
  );
}
