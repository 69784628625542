import React from "react";
import { useParams, useNavigate } from "react-router-dom"; // Replaced useHistory with useNavigate
import { Box, Spinner, Center } from "@chakra-ui/react";
import { useAuthData } from "auth-context"; // Ensure this context provides account_ID and user_ID
import DealDetail from "./DealDetail";
import GoBackHeader from "../Shared/GoBackHeader";
import useFetchDealById from "../hooks/useFetchDealById";

const DealDetailsPage = () => {
  const { dealId } = useParams(); // Ensure your route parameter is named 'dealId'
  const navigate = useNavigate(); // Replaced useHistory with useNavigate
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";

  // Use custom hook to fetch deal
  const { deal, isLoading, isError } = useFetchDealById(accountID, dealId);

  const handleGoBack = () => {
    navigate(-1); // Replaced history.goBack() with navigate(-1)
  };

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (isError) {
    return (
      <Center h="100vh">
        <Box>Error fetching deal details.</Box>
      </Center>
    );
  }

  if (!deal) {
    return (
      <Center h="100vh">
        <Box>Deal not found.</Box>
      </Center>
    );
  }

  return (
    <Box pt={10}>
      {/* Go Back Header with Deal Title */}
      <GoBackHeader
        onGoBack={handleGoBack}
        title={deal.deal_name || "Deal Details"}
      />

      {/* Deal Detail Component */}
      <DealDetail deal={deal} />
    </Box>
  );
};

export default DealDetailsPage;
