import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const useFetchInbox = (accountID, userID) => {
  const { getAccessTokenSilently } = useAuth0();
  const [inboxItems, setInboxItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchInbox = async () => {
      if (!accountID || !userID) {
        setIsLoading(false);
        setInboxItems([]);
        return;
      }

      try {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:inbox",
          },
        });

        const target = process.env.REACT_APP_API_BASE_URL || "https://lysto-be-tf.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${userID}/inbox`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setInboxItems(response.data || []);
        setIsError(false);
      } catch (error) {
        console.error("Error fetching inbox items:", error);
        setInboxItems([]);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInbox();
  }, [accountID, userID, getAccessTokenSilently]);

  return { inboxItems, isLoading, isError };
};

export default useFetchInbox;
