// PrivateRoute.js
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const isLocalhost = window.location.hostname === "localhost";

  if (isLoading) {
    // You can render a loading spinner or placeholder here
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    console.log("Redirecting to external site");
    window.location.href = isLocalhost
      ? "http://localhost:3000/#/auth/sign-in/"
      : "https://app.lysto.ai";
    return null;
  }

  return children;
};

export default PrivateRoute;
