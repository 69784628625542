// src/components/Shared/HoverBox.jsx

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Tooltip,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";

/**
 * HoverBox Component
 * Wraps children with a hover effect that changes the icon and provides a tooltip.
 *
 * @param {Object} props - Component props
 * @param {React.ElementType} props.icon - The default icon to display
 * @param {React.ElementType} props.hoverIcon - The icon to display on hover
 * @param {Function} props.onClick - Function to call when the HoverBox is clicked
 * @param {React.ReactNode} props.children - The content to display inside the HoverBox
 * @param {string} props.tooltipLabel - The tooltip text to display
 * @returns {JSX.Element}
 */
const HoverBox = ({
  children,
  icon,
  hoverIcon,
  onClick,
  tooltipLabel,
}) => {
  const iconColor = useColorModeValue("#748094", "#CBD5E0"); // Slightly lighter gray
  const hoverIconColor = useColorModeValue("#1A5BF6", "#63B3ED");

  return (
    <Tooltip label={tooltipLabel} hasArrow>
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="lg"
        cursor="pointer"
        position="relative"
        transition="all 0.2s"
        role="group"
        _hover={{
          "& .default-icon": { opacity: 0 },
          "& .hover-icon": { opacity: 1 },
        }}
        onClick={onClick}
      >
        {icon && (
          <Box
            className="default-icon"
            mr={2} // Spacing between icon and text
            position="absolute"
            transition="opacity 0.2s"
            opacity={1}
          >
            <Icon as={icon} color={iconColor} boxSize="20px" />
          </Box>
        )}
        {hoverIcon && (
          <Box
            className="hover-icon"
            mr={5} // Spacing between icon and text
            position="absolute"
            transition="opacity 0.2s"
            opacity={0}
          >
            <Icon as={hoverIcon} color={hoverIconColor} boxSize="20px" />
          </Box>
        )}
        <Box
          as="span"
          display="inline-block"
          textAlign={icon ? "left" : "left"}
          width={icon ? "auto" : "100%"}
          pl={icon ? "24px" : "0"} // Adjust padding to accommodate icon
        >
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
};

// Define PropTypes for type checking
HoverBox.propTypes = {
  icon: PropTypes.elementType,
  hoverIcon: PropTypes.elementType,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  tooltipLabel: PropTypes.string,
};

// Define default props
HoverBox.defaultProps = {
  icon: null,
  hoverIcon: null,
  tooltipLabel: "Hover action",
};

export default HoverBox;
