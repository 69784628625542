// src/components/Contacts/hooks/useFetchContactActivities.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Custom hook to fetch activities for a contact.
 * @param {number} accountId
 * @param {number} contactId
 * @returns {Object} { activities, isLoading, error }
 */
const useFetchContactActivities = (accountId, contactId) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const accessToken = await getAccessTokenSilently({
          authorizationParams: { scope: "read:activities" },
        });

        const headers = { Authorization: `Bearer ${accessToken}` };
        const apiBaseUrl =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-be-tf.azurewebsites.net";

        const endDate = new Date(); // Today
        const startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1); // One year ago
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = endDate.toISOString().split("T")[0];

        const apiUrl = `${apiBaseUrl}/accounts/${accountId}/contacts/${contactId}/activities`;
        const params = {
          start_date: formattedStartDate,
          end_date: formattedEndDate,
        };

        const response = await axios.get(apiUrl, { headers, params });

        if (
          response.data &&
          Array.isArray(response.data.activities)
        ) {
          const fetchedActivities = response.data.activities;
          // Sort activities by date descending
          fetchedActivities.sort(
            (a, b) => new Date(b.activity_date) - new Date(a.activity_date)
          );
          setActivities(fetchedActivities);
        } else {
          setActivities([]);
        }
      } catch (err) {
        console.error("Error fetching contact activities:", err);
        setError("An error occurred while fetching activities.");
        setActivities([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (accountId && contactId) {
      fetchActivities();
    }
  }, [accountId, contactId, getAccessTokenSilently]);

  return { activities, isLoading, error };
};

export default useFetchContactActivities;
