import React from "react";
import { Text, Box } from "@chakra-ui/react";
import PropTypes from "prop-types";

/**
 * Highlights the search query within the text.
 * @param {string} text - The original text.
 * @param {string} query - The search query to highlight.
 * @returns {JSX.Element}
 */
const HighlightText = ({ text, query }) => {
  if (!query) return <Text isTruncated noOfLines={1}>{text}</Text>;

  const regex = new RegExp(`(${query})`, "gi");
  const parts = text.split(regex);

  return (
    <Text isTruncated noOfLines={1}>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <Box
            as="mark"
            bg="blue.500"
            color="white"
            key={index}
            px={1}
            borderRadius="sm"
            whiteSpace="nowrap" // Prevent wrapping inside highlighted text
            display="inline" // Ensure inline display
          >
            {part}
          </Box>
        ) : (
          part
        )
      )}
    </Text>
  );
};

HighlightText.propTypes = {
  text: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
};

export default HighlightText;
