import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Text,
  useToast,
  Box,
} from "@chakra-ui/react";
import { formatInTimeZone } from "date-fns-tz";
import { parseISO } from "date-fns";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const RecordModal = ({ isOpen, onClose, accountID, userID, userEmail }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const canvasRef = useRef(null);
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const [audioId, setAudioId] = useState(null);

  useEffect(() => {
    if (isRecording && !isPaused) {
      drawVisualizer();
    }
  }, [isRecording, isPaused]);

  useEffect(() => {
    if (audioId) {
      console.log("Audio ID updated:", audioId);
      createCalendarEvent();
    }
  }, [audioId]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        try {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/webm",
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);

          // Wait for the audio to be sent and audio_id to be set before proceeding
          const response = await sendAudioToAPI(audioBlob);
          setAudioId(response.audio.audio_id);
        } catch (error) {
          console.error(
            "Error occurred while sending audio or creating calendar event:",
            error
          );
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setIsPaused(false);
      setRecordingTime(0);
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      console.error("Error starting recording:", error);
      toast({
        title: "Recording Error",
        description:
          "Failed to start recording. Please check your microphone permissions.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      clearInterval(timerRef.current);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      clearInterval(timerRef.current);
      setIsRecording(false);
      setIsPaused(false);
    }
  };

  const drawVisualizer = () => {
    if (!analyserRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    analyserRef.current.fftSize = 256;
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    function draw() {
      if (!isRecording || isPaused) return;

      requestAnimationFrame(draw);

      analyserRef.current.getByteFrequencyData(dataArray);

      canvasCtx.fillStyle = "rgb(255, 255, 255)";
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      const barWidth = (WIDTH / bufferLength) * 2.5;
      let barHeight;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i] / 2;

        canvasCtx.fillStyle = `rgb(${barHeight + 100}, 50, 50)`;
        canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight);

        x += barWidth + 1;
      }
    }

    draw();
  };

  const sendAudioToAPI = async (audioBlob) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "recording.webm");

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:calendar",
        },
      });

      const target =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "http://localhost:8080"
          : "https://lysto-be-tf.azurewebsites.net";

      const response = await axios.post(
        `${target}/api/accounts/${accountID}/users/${userID}/audio-upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data", // Ensure the correct Content-Type
          },
        }
      );

      if (response.status === 201) {
        toast({
          title: "Audio Uploaded",
          description: "Your recording has been successfully uploaded.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error("Audio upload failed");
        toast({
          title: "Upload Failed",
          description: "Failed to upload the recording. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      return response.data;
    } catch (error) {
      console.error("Error uploading audio:", error);
      toast({
        title: "Upload Error",
        description: "An error occurred while uploading the recording.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  // Helper function to format date-time
  const formatDateTimeBE = (dateTimeString) => {
    return formatInTimeZone(
      parseISO(dateTimeString),
      "UTC",
      "yyyy-MM-dd'T'HH:mm:ssXXX"
    );
  };

  const createCalendarEvent = async () => {
    const now = new Date();
    const endTime = new Date(now.getTime() + recordingTime * 1000);

    const eventData = {
      summary: "Recorded Meeting",
      description: "This event was created from a recording.",
      startTime: formatDateTimeBE(now.toISOString()),
      endTime: formatDateTimeBE(endTime.toISOString()),
      attendees: [userEmail],
      audioID: audioId,
    };

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:calendar",
        },
      });
      const target =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "http://localhost:8080"
          : "https://lysto-be-tf.azurewebsites.net";
      const response = await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/calendar/events/create`,
        eventData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Event created:", response.data);
        // Optionally, update your events list or refetch events
      }
      toast({
        title: "Event Created",
        description: "A calendar event has been created for your recording.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error("Error creating event from recording:", error);
      toast({
        title: "Event Creation Failed",
        description: "Failed to create a calendar event for your recording.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Record Meeting</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Box width="100%" height="100px">
              <canvas ref={canvasRef} width="300" height="100" />
            </Box>
            <Text fontSize="3xl">{formatTime(recordingTime)}</Text>
            {!isRecording ? (
              <Button onClick={startRecording} colorScheme="red">
                Start Recording
              </Button>
            ) : (
              <>
                {!isPaused ? (
                  <Button onClick={pauseRecording}>Pause</Button>
                ) : (
                  <Button onClick={resumeRecording}>Resume</Button>
                )}
                <Button onClick={stopRecording} colorScheme="red">
                  Stop and Save
                </Button>
              </>
            )}
            {audioUrl && <audio src={audioUrl} controls />}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RecordModal;
