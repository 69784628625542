// src/components/Contacts/ContactOverviewSection.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { FaEnvelope, FaBriefcase, FaFilter, FaFlag } from 'react-icons/fa';

/**
 * OverviewTag Component
 * Reused from OverviewSection with necessary adjustments.
 *
 * @param {Object} props
 * @param {React.ElementType} props.icon - The icon component.
 * @param {string} props.label - The label for the tag.
 * @param {string} props.value - The value to display.
 * @param {string} [props.colorScheme="gray"] - The color scheme for styling.
 * @returns {JSX.Element}
 */
const OverviewTag = ({ icon, label, value, colorScheme = "gray" }) => (
  <Tooltip label={`${label}: ${value}`} placement="top">
    <Flex
      align="center"
      bg={`${colorScheme}.50`}
      color={`${colorScheme}.700`}
      px={3}
      py={2}
      borderRadius="md"
      borderWidth="1px"
      borderColor={`${colorScheme}.200`}
      _hover={{
        bg: `${colorScheme}.100`,
      }}
      height="40px" // Ensures consistent height
      minWidth="120px" // Ensures a minimum width
    >
      <Icon as={icon} mr={2} />
      <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
        {value || 'N/A'}
      </Text>
    </Flex>
  </Tooltip>
);

OverviewTag.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorScheme: PropTypes.string,
};

/**
 * ContactOverviewSection Component
 * Displays overview information for a contact, excluding Name and Account.
 *
 * @param {Object} props
 * @param {string} props.email - Contact's email.
 * @param {string} props.title - Contact's title.
 * @param {string} props.source - Source of the contact.
 * @param {string} props.status - Status of the contact.
 * @returns {JSX.Element}
 */
const ContactOverviewSection = ({
  email,
  title,
  source,
  status,
}) => {
  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Overview
      </Text>
      <Flex gap={3} flexWrap="wrap">
        {/* Email OverviewTag */}
        <OverviewTag
          icon={FaEnvelope}
          label="Email"
          value={email}
          colorScheme="green"
        />

        {/* Title OverviewTag */}
        <OverviewTag
          icon={FaBriefcase}
          label="Title"
          value={title}
          colorScheme="purple"
        />

        {/* Source OverviewTag */}
        <OverviewTag
          icon={FaFilter}
          label="Source"
          value={source}
          colorScheme="orange"
        />

        {/* Status OverviewTag */}
        <OverviewTag
          icon={FaFlag}
          label="Status"
          value={status}
          colorScheme="teal"
        />
      </Flex>
    </Box>
  );
};

ContactOverviewSection.propTypes = {
  email: PropTypes.string,
  title: PropTypes.string,
  source: PropTypes.string,
  status: PropTypes.string,
};

ContactOverviewSection.defaultProps = {
  email: '',
  title: '',
  source: '',
  status: '',
};

export default ContactOverviewSection;
