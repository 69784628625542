// src/components/Inbox/hooks/useInboxItem.js
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { extractEmailContent } from "../Inbox/extractEmailContent"; // Assuming you have this utility

const useInboxItem = (inboxId, accountID, userID, target) => {
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchInboxItem = async () => {
      if (!inboxId || !accountID || !userID) {
        setError("Missing required parameters.");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:inbox",
          },
        });

        const response = await axios.get(
          `${target}/accounts/${accountID}/users/${userID}/inbox/${inboxId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.data) {
          throw new Error("No data received from API.");
        }

        // Determine task type based on response data
        const taskSubtype = determineTaskType(response.data);

        // Transform the data to match component expectations
        const transformedData = {
          ...response.data,
          task_subtype: taskSubtype,
          conversationHistory:
            response.data.conversation_history?.map((msg) => ({
              message_id: msg.message_id,
              identity: msg.identity,
              content: extractEmailContent(msg.content), // Cleaned content
              type: msg.type,
              status: msg.status,
              date_sent: msg.received_at || msg.created_at,
              time_sent: new Date(
                msg.received_at || msg.created_at
              ).toLocaleTimeString(),
              sender_email: msg.sender_email,
              sender_name: msg.sender_name,
            })) || [],
          notes: response.data.notes || [],
          champion: response.data.champion || "N/A",
          taskType: response.data.task_type || "Followup",
          emailSubject: response.data.subject || "",
          leadName: response.data.sender_name || "Unknown Lead",
          contacts: response.data.contacts || [],
          tags: [],
        };

        setItem(transformedData);
      } catch (err) {
        console.error(
          "Error fetching inbox item:",
          err.response ? err.response.data : err.message
        );
        setError(
          err.response?.data?.message ||
            err.message ||
            "Failed to fetch inbox item."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchInboxItem();
  }, [inboxId, accountID, userID, getAccessTokenSilently, target]);

  // Helper function to determine task type
  const determineTaskType = (data) => {
    // Check for task_subtype first as it's explicit
    if (data.task_subtype) {
      return data.task_subtype.toLowerCase();
    }

    // Check for task_type
    if (
      data.task_type === "follow-up" &&
      data.subject?.toLowerCase().includes("call follow-up")
    ) {
      return "call";
    }

    // Check for content structure indicating a call follow-up
    try {
      const parsedContent = JSON.parse(data.content);
      if (
        parsedContent &&
        parsedContent.content &&
        (parsedContent.content.includes("# Key Points to Address") ||
          parsedContent.content.includes("# Suggested Approach"))
      ) {
        return "call";
      }
    } catch (e) {
      // If parsing fails, continue with other checks
    }

    // Check for email-specific fields
    if (
      data.thread_id ||
      data.email_id ||
      data.email_recipients ||
      data.conversation_history?.some((msg) => msg.thread_id || msg.email_id)
    ) {
      return "email";
    }

    // Default to email if no other indicators are found
    return "email";
  };

  // Handle sending a message
  const handleSendMessage = async (messageData) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const apiUrl = `${target}/accounts/${accountID}/users/${userID}/tasks/${inboxId}/send-email`; // Adjusted endpoint

      const payload = {
        message_content: messageData.content, // Adjusted field
        subject: messageData.subject,
        email_recipients: messageData.email_recipients,
        type: "message",
        identity: "agent",
      };

      console.log("Sending message with payload:", payload); // Debug: Verify payload

      const response = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("API Response:", response.data); // Debug: Inspect response

      const sentMessage = {
        message_id: response.data.message_id || Date.now().toString(),
        identity: "agent",
        content: messageData.content,
        type: "message",
        status: "sent",
        date_sent: new Date().toISOString(),
        time_sent: new Date().toLocaleTimeString(),
        sender_email: response.data.sender_email || "current.user@example.com",
        sender_name: response.data.sender_name || "Current User",
      };

      setItem((prev) => ({
        ...prev,
        conversationHistory: [
          ...prev.conversationHistory.filter((msg) => msg.status !== "draft"),
          sentMessage,
        ],
      }));

      return response.data;
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  // Handle adding an annotation
  const handleAddAnnotation = async (annotation) => {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/inbox/${inboxId}/annotations`,
        {
          content: annotation,
          type: "note",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const newNote = {
        id: response.data.id,
        content: annotation,
        created_at: new Date().toISOString(),
        type: "note",
      };

      setItem((prev) => ({
        ...prev,
        notes: [...(prev.notes || []), newNote],
        conversationHistory: [
          ...prev.conversationHistory,
          {
            message_id: `note-${Date.now()}`, // Unique ID for the note
            identity: "agent",
            content: annotation,
            type: "note",
            status: "added",
            date_sent: new Date().toISOString(),
            time_sent: new Date().toLocaleTimeString(),
            sender_email: "current.user@example.com",
            sender_name: "Current User",
          },
        ],
      }));

      return response.data;
    } catch (error) {
      console.error(
        "Error adding annotation:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  // Handle toggling archive status
  const handleArchiveToggle = async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      const newArchivedStatus = item.status !== "archived";

      await axios.post(
        `${target}/accounts/${accountID}/users/${userID}/inbox/${inboxId}/archive`,
        {
          archived: newArchivedStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setItem((prev) => ({
        ...prev,
        status: newArchivedStatus ? "archived" : "active",
      }));
    } catch (error) {
      console.error(
        "Error toggling archive status:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  return {
    item,
    loading,
    error,
    setItem,
    handleSendMessage,
    handleAddAnnotation,
    handleArchiveToggle,
  };
};

export default useInboxItem;
