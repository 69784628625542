import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  VStack,
  Text,
} from '@chakra-ui/react';

const AnnotationModal = ({ isOpen, onClose, onAddAnnotation }) => {
  const [annotation, setAnnotation] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!annotation.trim()) return;
    
    setIsSubmitting(true);
    try {
      await onAddAnnotation(annotation.trim());
      setAnnotation('');
      onClose();
    } catch (error) {
      console.error('Error adding annotation:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setAnnotation('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Note</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={3} align="stretch">
            <Text fontSize="sm" color="gray.600">
              Add a note to this conversation thread. Notes are visible to all team members.
            </Text>
            <Textarea
              value={annotation}
              onChange={(e) => setAnnotation(e.target.value)}
              placeholder="Type your note here..."
              size="md"
              minHeight="150px"
              resize="vertical"
              autoFocus
              isDisabled={isSubmitting}
              _focus={{
                borderColor: 'blue.500',
                boxShadow: 'outline',
              }}
            />
          </VStack>
        </ModalBody>

        <ModalFooter gap={3}>
          <Button 
            variant="outline" 
            onClick={handleClose}
            isDisabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            loadingText="Adding..."
            isDisabled={!annotation.trim()}
          >
            Add Note
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AnnotationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddAnnotation: PropTypes.func.isRequired,
};

export default AnnotationModal;