// src/components/DealCountByStageAndSourceBarCard.js

import React, { useState, useEffect } from "react";
import { Box, Text, useToast, Select } from "@chakra-ui/react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import BarCard from "./BarCard";
import { CHART_COLORS } from "../constants";
import { useAuthData } from "auth-context";

/**
 * Helper function to capitalize the first letter of a string.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} The capitalized string.
 */
const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * DealCountByStageAndSourceBarCard Component
 * Fetches deal count data by stage and source and displays it in a BarCard.
 *
 * @returns {JSX.Element} The rendered component.
 */
const DealCountByStageAndSourceBarCard = () => {
  const [barData, setBarData] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("All");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";

  const toast = useToast();

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const fetchDealCountData = async () => {
      setLoading(true);
      setError(false);

      try {
        // Get access token
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:dashboard", // Adjust scope as needed
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "production"
            ? "https://lysto-be-tf.azurewebsites.net"
            : "https://lysto-be-tf.azurewebsites.net"; // Adjust if needed

        const url = `${target}/accounts/${accountID}/deal/deal-count-by-stage-and-source`;
        var pipeline_id = "";
        if (accountID === "1") {
          pipeline_id = "1635";
        } else if (accountID === "2") {
          pipeline_id = "4";
        } else if (accountID === "4") {
          pipeline_id = "943";
        } else if (accountID === "3") {
          pipeline_id = "1832";
        }

        const params = {
          days: 7,
          pipeline_id: pipeline_id,
          period: "week",
        };

        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const response = await axios.get(url, { headers, params });

        const data = response.data;

        // Store raw data
        setRawData(data);

        // Extract lead source options
        const leadSources = data.leadSources;
        const leadSourceLabels = Object.keys(leadSources).filter((source) => {
          const total = leadSources[source].reduce(
            (acc, stage) => acc + (stage.team.count || 0),
            0
          );
          return total > 0;
        });

        setLeadSourceOptions(["All", ...leadSourceLabels]);
      } catch (err) {
        console.error("Error fetching deal count data:", err);
        setError(true);
        setLeadSourceOptions([]); // Ensure leadSourceOptions is defined
        toast({
          title: "Error",
          description: "Failed to fetch deal count data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchDealCountData();
  }, [getAccessTokenSilently, accountID, toast]);

  useEffect(() => {
    if (!rawData) return;

    const { leadSources } = rawData;

    // Extract unique stages with their display_order
    const stagesMap = new Map();
    Object.values(leadSources).forEach((sourceArray) => {
      sourceArray.forEach((stageObj) => {
        if (!stagesMap.has(stageObj.stage)) {
          stagesMap.set(stageObj.stage, stageObj.display_order);
        }
      });
    });

    // Sort stages by display_order
    const sortedStages = Array.from(stagesMap.entries())
      .sort((a, b) => a[1] - b[1])
      .map((entry) => entry[0]);

    // Remove the exclusion of "Connecting" stage
    // const excludedStages = ["Connecting"];
    // const activeStages = sortedStages.filter(
    //   (stage) => !excludedStages.includes(stage)
    // );

    // Use all sorted stages, including "Connecting"
    const activeStages = sortedStages;

    // Compute counts per stage
    const countsPerStage = activeStages.map((stage) => {
      let totalForStage = 0;
      if (selectedLeadSource === "All") {
        // Sum counts over all lead sources
        Object.values(leadSources).forEach((sourceArray) => {
          const stageObj = sourceArray.find((s) => s.stage === stage);
          if (stageObj) {
            totalForStage += stageObj.team.count || 0;
          }
        });
      } else {
        // Use data only for selected lead source
        const sourceArray = leadSources[selectedLeadSource];
        if (sourceArray) {
          const stageObj = sourceArray.find((s) => s.stage === stage);
          if (stageObj) {
            totalForStage = stageObj.team.count || 0;
          }
        }
      }
      return totalForStage;
    });

    // Filter out stages with total 0, except "Connecting"
    const filteredStages = [];
    const filteredCounts = [];
    const filteredColors = [];

    activeStages.forEach((stage, index) => {
      const total = countsPerStage[index];
      if (total > 0 || stage.toLowerCase() === "connecting") {
        filteredStages.push(capitalizeFirstLetter(stage));
        filteredCounts.push(total);
        filteredColors.push(CHART_COLORS[index % CHART_COLORS.length]);
      }
    });

    // Check if there is any data to display
    const hasData = filteredStages.length > 0;

    if (!hasData) {
      setError(true);
      setBarData(null);
      toast({
        title: "No Data Available",
        description: "There are no deals with non-zero counts.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Prepare bar data
    const processedBarData = {
      title: "Pipeline Overview ",
      labels: filteredStages,
      datasets: [
        {
          label: "Total Deals",
          data: filteredCounts,
          backgroundColor: filteredColors, // Use CHART_COLORS
        },
      ],
    };

    setBarData(processedBarData);
    setError(false);
  }, [rawData, selectedLeadSource, toast]);

  // Prevent rendering BarCard if data isn't ready or if there's an error
  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading deal count statistics...</Text>
      </Box>
    );
  }

  if (
    error ||
    !barData ||
    !leadSourceOptions ||
    leadSourceOptions.length === 0
  ) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">
          {error
            ? "Failed to load deal count statistics."
            : "No deal count data available."}
        </Text>
      </Box>
    );
  }

  // Create the dropdown component
  const dropdown = (
    <Select
      width="200px"
      value={selectedLeadSource}
      onChange={(e) => setSelectedLeadSource(e.target.value)}
    >
      {leadSourceOptions.map((source) => (
        <option key={source} value={source}>
          {capitalizeFirstLetter(source)}
        </option>
      ))}
    </Select>
  );

  return (
    <Box pb={4} w="100%">
      {/* Bar Chart with Labels and Totals */}
      <BarCard
        barData={barData}
        dropdown={dropdown} // Pass the dropdown to BarCard
      />
    </Box>
  );
};

/**
 * Export the component
 */
export default DealCountByStageAndSourceBarCard;
