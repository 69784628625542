// src/components/Deals/DealSummarySection.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, VStack, Button, Collapse } from '@chakra-ui/react';

/**
 * Helper function to safely parse JSON strings.
 * Returns the value of the specified key or a default message if parsing fails.
 */
const parseJSONField = (jsonString, key, defaultMessage) => {
  if (!jsonString) return defaultMessage;
  try {
    const parsed = JSON.parse(jsonString);
    return parsed[key] || defaultMessage;
  } catch (error) {
    console.error(`Error parsing JSON for key "${key}":`, error);
    return defaultMessage;
  }
};

const DealSummarySection = ({ summary, shortSummary }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Parse the summaries
  const parsedSummary = parseJSONField(summary, 'summary', 'No summary provided.');
  const parsedShortSummary = parseJSONField(shortSummary, 'short_summary', 'No summary provided.');

  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Deal Summary
      </Text>
      <VStack spacing={4} align="stretch">
        {/* Full Summary */}
        <Collapse in={isExpanded} animateOpacity>
          <Text fontSize="sm" color="gray.700">
            {parsedSummary}
          </Text>
        </Collapse>

        {/* Short Summary */}
        {!isExpanded && (
          <Text fontSize="sm" color="gray.700">
            {parsedShortSummary}
          </Text>
        )}

        {/* Toggle Button */}
        <Button
          size="sm"
          onClick={handleToggle}
          variant="link"
          colorScheme="blue"
          alignSelf="flex-start"
        >
          {isExpanded ? 'View less' : 'View full summary'}
        </Button>
      </VStack>
    </Box>
  );
};

DealSummarySection.propTypes = {
  summary: PropTypes.string,
  shortSummary: PropTypes.string,
};

DealSummarySection.defaultProps = {
  summary: null,
  shortSummary: null,
};

export default DealSummarySection;
