// DealCountSinceLastActivityBarCard.js

import React, { useState, useEffect } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import BarCard from "./BarCard";
import { CHART_COLORS } from "../constants";
import { useAuthData } from "auth-context";

/**
 * DealCountSinceLastActivityBarCard Component
 * Fetches deal data and displays the number of deals by weeks since last activity.
 *
 * @returns {JSX.Element} The rendered component.
 */
const DealCountSinceLastActivityBarCard = () => {
  const [barData, setBarData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchDealData = async () => {
      setLoading(true);
      setError(false);

      try {
        // Get access token
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:dashboard", // Adjust scope as needed
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "production"
            ? "https://lysto-be-tf.azurewebsites.net"
            : "https://lysto-be-tf.azurewebsites.net"; // Adjust if needed

        const url = `${target}/accounts/${accountID}/deal/deals-since-last-activity`;
        var pipeline_id = "";
        if (accountID === "1") {
          pipeline_id = "1635";
        } else if (accountID === "2") {
          pipeline_id = "4";
        } else if (accountID === "4") {
          pipeline_id = "943";
        } else if (accountID === "3") {
          pipeline_id = "1832";
        }

        const params = {
          pipeline_id: pipeline_id,
        };

        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const response = await axios.get(url, { headers, params });

        const data = response.data; // This should be an array of DealSinceLastActivity

        // Process the data
        const dealsByWeeks = {};

        data.forEach((deal) => {
          const days = deal.days_since_last_activity;

          if (days !== null && days !== undefined) {
            const weeks = Math.floor(days / 7); // Calculate weeks since last activity

            if (!dealsByWeeks[weeks]) {
              dealsByWeeks[weeks] = {
                count: 0,
                deals: [], // Store full deal info
              };
            }
            dealsByWeeks[weeks].count += 1;
            dealsByWeeks[weeks].deals.push(deal); // Store deal
          }
        });

        // Sort the week numbers
        const weekNumbers = Object.keys(dealsByWeeks)
          .map((week) => parseInt(week))
          .sort((a, b) => a - b)
          .slice(0, 20); // Limit to 20 weeks if necessary

        // Prepare labels and data for the chart
        const labels = weekNumbers.map((week) => `${week + 1}`);

        const counts = weekNumbers.map((week) => dealsByWeeks[week].count);

        // Prepare deals array for each bar
        const dealsArray = weekNumbers.map((week) => dealsByWeeks[week].deals);
        // Prepare bar data
        const processedBarData = {
          labels: labels,
          datasets: [
            {
              label: "Number of Deals",
              data: counts,
              backgroundColor: CHART_COLORS.slice(0, counts.length),
            },
          ],
          title: "Deals by Weeks Since Last Activity",
          dealData: dealsArray,
        };

        setBarData(processedBarData);
        setError(false);
      } catch (err) {
        console.error("Error fetching deal data:", err);
        setError(true);
        toast({
          title: "Error",
          description: "Failed to fetch deal data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchDealData();
  }, [getAccessTokenSilently, accountID, toast]);

  // Prevent rendering BarCard if data isn't ready or if there's an error
  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading deal statistics...</Text>
      </Box>
    );
  }

  if (error || !barData) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">
          {error
            ? "Failed to load deal statistics."
            : "No deal data available."}
        </Text>
      </Box>
    );
  }

  return (
    <Box pb={4} w="100%">
      {/* Bar Chart */}
      <BarCard barData={barData} showLabels={false} />
    </Box>
  );
};

/**
 * Export the component
 */
export default DealCountSinceLastActivityBarCard;
