import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { FaBuilding, FaUser, FaClock, FaTasks } from 'react-icons/fa';
import { parseISO, formatDistanceToNow } from 'date-fns';

const OverviewTag = ({ icon, label, value, colorScheme = "gray" }) => (
  <Tooltip label={`${label}: ${value}`} placement="top">
    <Flex
      align="center"
      bg={`${colorScheme}.50`}
      color={`${colorScheme}.700`}
      px={3}
      py={2}
      borderRadius="md"
      borderWidth="1px"
      borderColor={`${colorScheme}.200`}
      _hover={{
        bg: `${colorScheme}.100`,
      }}
    >
      <Icon as={icon} mr={2} />
      <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
        {value}
      </Text>
    </Flex>
  </Tooltip>
);

const OverviewSection = ({ 
  account, 
  taskType, 
  champion, 
  lastEngagementDate 
}) => {
  const formattedDate = lastEngagementDate 
    ? formatDistanceToNow(parseISO(lastEngagementDate), { addSuffix: true })
    : 'N/A';

  return (
    <Box>
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Overview
      </Text>
      <Flex gap={3} flexWrap="wrap">
        <OverviewTag
          icon={FaBuilding}
          label="Account"
          value={account || 'Unknown Account'}
          colorScheme="blue"
        />
        
        <OverviewTag
          icon={FaTasks}
          label="Task Type"
          value={taskType || 'Followup'}
          colorScheme="green"
        />
        
        {champion && (
          <OverviewTag
            icon={FaUser}
            label="Champion"
            value={champion}
            colorScheme="purple"
          />
        )}
        
        <OverviewTag
          icon={FaClock}
          label="Last Activity"
          value={formattedDate}
          colorScheme="orange"
        />
      </Flex>
    </Box>
  );
};

OverviewSection.propTypes = {
  account: PropTypes.string.isRequired,
  taskType: PropTypes.string,
  champion: PropTypes.string,
  lastEngagementDate: PropTypes.string,
};

OverviewSection.defaultProps = {
  taskType: 'Followup',
  champion: null,
  lastEngagementDate: null,
};

OverviewTag.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorScheme: PropTypes.string,
};

export default OverviewSection;