// src/components/Inbox/InboxTable.jsx

import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
} from "@chakra-ui/react";
import { FaSortUp, FaSortDown, FaExpandAlt } from 'react-icons/fa'; // Updated Icons
import { MdMail, MdPhone } from 'react-icons/md'; // Added MdPhone for phone icon
import HoverBox from "../Shared/HoverBox";
import HighlightText from "./HighlightText";
import PropTypes from "prop-types";

// Import status and task mappings
import { STATUS_MAP, TASK_MAP } from "./inboxMappings";

/**
 * Removes the word 'Deal' from the subject string.
 * Specifically targets 'For Deal ' to ensure accurate replacement.
 *
 * @param {string} subject - The original subject string.
 * @returns {string} - The processed subject string without 'Deal'.
 */
const removeDealFromSubject = (subject) => {
  if (!subject) return "";
  return subject.replace('For Deal ', 'For ');
};

/**
 * Formats the status name using the STATUS_MAP.
 * @param {string} status - The status key.
 * @returns {string} - The formatted status label.
 */
const formatStatus = (status) => {
  return STATUS_MAP[status]?.label || status.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};

/**
 * Formats the task by combining task_type and task_subtype.
 * Ensures proper capitalization and hyphenation.
 *
 * @param {string} taskType - The task type.
 * @param {string} taskSubtype - The task subtype.
 * @returns {string} - The formatted task.
 */
const formatTask = (taskType, taskSubtype) => {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const formattedTaskType = taskType ? capitalize(taskType) : "";
  const formattedTaskSubtype = taskSubtype ? capitalize(taskSubtype) : "";

  if (formattedTaskType && formattedTaskSubtype) {
    // Insert hyphen if the task is "Follow-up"
    if (formattedTaskType.toLowerCase() === "follow up" || formattedTaskType.toLowerCase() === "follow-up") {
      return `Follow-up ${formattedTaskSubtype}`;
    }
    return `${formattedTaskType} ${formattedTaskSubtype}`;
  } else if (formattedTaskType) {
    return formattedTaskType;
  } else if (formattedTaskSubtype) {
    return formattedTaskSubtype;
  } else {
    return "—";
  }
};

/**
 * Calculates the time difference between now and the given date, returning a string like "x days ago".
 * @param {string} dateString - The ISO date string to calculate the difference from.
 * @returns {string} - The formatted time difference.
 */
const formatTimeAgo = (dateString) => {
  const now = new Date();
  const past = new Date(dateString);
  const diffInSeconds = Math.floor((now - past) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds);
    if (count >= 1) {
      return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
    }
  }
  return "Just now";
};

/**
 * Maps task names to colors using the TASK_MAP.
 * @param {string} task - The combined task type and subtype.
 * @returns {string} - The color scheme for the dot.
 */
const getTaskColorScheme = (task) => {
  return TASK_MAP[task]?.color || "gray";
};

/**
 * Determines the appropriate icon based on the task type.
 * @param {string} taskType - The task type.
 * @returns {React.Element} - The icon component.
 */
const getTaskIcon = (taskType) => {
  if (taskType.toLowerCase() === "follow up" || taskType.toLowerCase() === "follow-up") {
    return MdPhone; // Phone icon for Follow-up Calls
  } else if (taskType.toLowerCase().includes("email")) {
    return MdMail; // Email icon for Follow-up Emails
  }
  return MdMail; // Default icon
};

/**
 * InboxTable Component
 * Displays inbox items data in a table format.
 *
 * @param {Object} props - Component props
 * @param {Array} props.inboxItems - Array of inbox item objects
 * @param {Function} props.onViewDetails - Function to handle viewing inbox item details
 * @param {Function} props.onSort - Function to handle sorting when a header is clicked
 * @param {Object} props.sortConfig - Current sort configuration
 * @param {string} props.searchQuery - Current search query to highlight
 * @returns {JSX.Element}
 */
const InboxTable = ({ inboxItems, onViewDetails, onSort, sortConfig, searchQuery }) => {
  
  /**
   * Renders the sort icon based on the current sort configuration.
   * @param {string} columnKey - The key of the column.
   * @returns {JSX.Element|null}
   */
  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    if (sortConfig.direction === 'ascending') {
      return <FaSortUp style={{ marginLeft: '4px' }} />;
    }
    return <FaSortDown style={{ marginLeft: '4px' }} />;
  };

  // Define fixed widths for each column in pixels
  const columnWidths = {
    subject: "250px", // Updated key
    status: "150px",
    task: "200px",
    last_activity_date: "200px",
  };

  return (
    <Box bg="white">
      <Table variant="simple" bg="white" width="100%" tableLayout="fixed">
        <Thead bg="white">
          <Tr>
            <Th
              cursor="pointer"
              onClick={() => onSort('subject')} // Updated sorting key
              width={columnWidths.subject}
              whiteSpace="nowrap"
            >
              Task Name
              {renderSortIcon('subject')} {/* Updated sorting key */}
            </Th>
            <Th
              cursor="pointer"
              onClick={() => onSort('status')}
              width={columnWidths.status}
              whiteSpace="nowrap"
            >
              Status
              {renderSortIcon('status')}
            </Th>
            <Th
              cursor="pointer"
              onClick={() => onSort('task_type')} // Sorting based on task_type
              width={columnWidths.task}
              whiteSpace="nowrap"
            >
              Task
              {renderSortIcon('task_type')}
            </Th>
            <Th
              cursor="pointer"
              onClick={() => onSort('last_activity_date')}
              width={columnWidths.last_activity_date}
              whiteSpace="nowrap"
            >
              Last Activity
              {renderSortIcon('last_activity_date')}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {inboxItems.map((item) => {
            const formattedTask = formatTask(item.task_type, item.task_subtype);
            const taskColorScheme = getTaskColorScheme(formattedTask);
            const TaskIcon = getTaskIcon(item.task_type);
            const processedSubject = removeDealFromSubject(item.subject); // New line to process subject
            return (
              <Tr key={item.id} _hover={{ bg: "gray.50" }}>
                {/* Task Name Column */}
                <Td>
                  <HoverBox
                    icon={TaskIcon}
                    hoverIcon={FaExpandAlt} // Updated hover icon
                    onClick={() => onViewDetails(item)}
                    tooltipLabel="View Inbox Item Details"
                  >
                    <HighlightText
                      text={processedSubject || "No Subject"} // Updated to use 'processedSubject'
                      query={searchQuery}
                    />
                  </HoverBox>
                </Td>

                {/* Status Column */}
                <Td>
                  <Box display="flex" alignItems="center" fontSize="md">
                    <Box
                      height="10px"
                      width="10px"
                      borderRadius="full"
                      bg={`${STATUS_MAP[item.status]?.color}.500`}
                      mr={2}
                    ></Box>
                    <Text color="black" isTruncated maxWidth="100%">
                      <HighlightText
                        text={formatStatus(item.status || "Unknown")}
                        query={searchQuery}
                      />
                    </Text>
                  </Box>
                </Td>

                {/* Task Column */}
                <Td>
                  <Box display="flex" alignItems="center" fontSize="md">
                    <Box
                      height="10px"
                      width="10px"
                      borderRadius="full"
                      bg={`${taskColorScheme}.500`}
                      mr={2}
                    ></Box>
                    <Box isTruncated maxWidth="100%">
                      <HighlightText
                        text={formattedTask}
                        query={searchQuery}
                      />
                    </Box>
                  </Box>
                </Td>

                {/* Last Activity Column */}
                <Td>
                  {item.last_activity_date ? (
                    <Box display="flex" alignItems="center" fontSize="md">
                      <Text isTruncated maxWidth="100%">
                        {formatTimeAgo(item.last_activity_date)}
                      </Text>
                    </Box>
                  ) : "—"}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

// Define PropTypes for type checking
InboxTable.propTypes = {
  inboxItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(['ascending', 'descending']),
  }).isRequired,
  searchQuery: PropTypes.string, // New Prop
};

InboxTable.defaultProps = {
  searchQuery: "",
};

export default InboxTable;
