// src/Dashboard.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthData } from "auth-context";

// Components
import DashboardControls from './components/DashboardControls';
import LiveActivity from './components/LiveActivity';
import UserStatsGrid from './components/UserStatsGrid';
import AggregateStatsGrid from "./components/AggregateStatsGrid";
import AggregatedPieCharts from "./components/AggregatedPieCharts";
import DealCountByStageAndSourceBarCard from "./components/DealCountByStageAndSourceBarCard";
import DealCountSinceLastActivityBarCard from "./components/DealCountSinceLastActivityBarCard";

const Dashboard = () => {
  // State
  const [timeRange, setTimeRange] = useState('week'); // Default to 'week'
  const [selectedRep, setSelectedRep] = useState({ name: 'team' });
  const [selectedSection, setSelectedSection] = useState("Sales Activity");
  const [usersData, setUsersData] = useState([{ name: "Team" }]);
  const [loading, setLoading] = useState(true);

  // Hooks
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const userData = useAuthData();
  const accountID = userData?.account_ID;

  // Fetch users data
  useEffect(() => {
    const fetchUsers = async () => {
      if (!accountID) return;

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:users",
          },
        });

        const apiUrl = process.env.REACT_APP_API_URL || "https://lysto-be-tf.azurewebsites.net";
        const response = await fetch(`${apiUrl}/accounts/${accountID}/get-users`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        const data = await response.json();
        const userObjects = data.users.map((user) => ({
          name: user.name,
          id: user.id
        }));

        setUsersData([{ name: "Team" }, ...userObjects]);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast({
          title: "Error",
          description: "Failed to fetch users.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [accountID, getAccessTokenSilently, toast]);

  // Helper function to get current user ID
  const getCurrentUserId = () => {
    if (!selectedRep || selectedRep.name === 'team') {
      return null;
    }
    return selectedRep.id || null;
  };

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} px={{ base: "10px", md: "20px" }}>
        <Text>Loading dashboard data...</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box pt={{ base: "20px", md: "20px" }}>
        {/* Unified Dashboard Controls */}
        <DashboardControls
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          selectedRep={selectedRep}
          setSelectedRep={setSelectedRep}
          reps={usersData}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />

        {/* Content Sections */}
        <Box>
          {selectedSection === "Sales Process" && (
            <>
            <DealCountByStageAndSourceBarCard timeRange={timeRange} />
            <DealCountSinceLastActivityBarCard timeRange={timeRange} />
            </>
          )}

          {selectedSection === "Sales Activity" && (
            <>
              <AggregateStatsGrid timeRange={timeRange} /> {/* Pass timeRange */}
              <AggregatedPieCharts timeRange={timeRange} /> {/* Pass timeRange */}
              <UserStatsGrid timeRange={timeRange} /> {/* Pass timeRange */}
            </>
          )}

          {selectedSection === "Timeline" && (
            <LiveActivity
              accountId={accountID}
              userId={getCurrentUserId()}
              timeRange={timeRange}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
