// src/components/Contacts/ContactActivity.jsx

import React from "react";
import PropTypes from "prop-types";
import { Box, Text, Spinner, Center, Alert, AlertIcon, useToast } from "@chakra-ui/react";
import useFetchContactActivities from "./hooks/useFetchContactActivities";
import ContactActivityTimeline from "./ContactActivityTimeline";

/**
 * ContactActivity Component
 * Fetches and displays activities related to a contact.
 *
 * @param {Object} props
 * @param {number} props.accountId - The account ID.
 * @param {number} props.contactId - The contact ID.
 * @returns {JSX.Element}
 */
const ContactActivity = ({ accountId, contactId }) => {
  const { activities, isLoading, error } = useFetchContactActivities(accountId, contactId);
  const toast = useToast();

  React.useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  return (
    <Box>
      <Text fontSize="md" fontWeight="bold" mb={4}>
        Contact Activity
      </Text>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : error ? (
        <Alert status="error" mt={4}>
          <AlertIcon />
          {error}
        </Alert>
      ) : activities.length > 0 ? (
        <ContactActivityTimeline activities={activities} />
      ) : (
        <Text textAlign="center" py={4} color="gray.500">
          No activities found for this contact.
        </Text>
      )}
    </Box>
  );
};

ContactActivity.propTypes = {
  accountId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
};

export default ContactActivity;
