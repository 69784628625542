// src/components/Inbox/ActionButtons.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {
  HStack,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import { 
  FaArchive, 
  FaPaperPlane, 
  FaStickyNote,
  FaInbox
} from 'react-icons/fa';

const ActionButtons = ({
  onArchiveToggle,
  onAddAnnotation,
  onSend,
  isSending,
  isArchived,
  isMessageEmpty
}) => {
  return (
    <HStack spacing={4} justify="flex-end" py={2}>
      <Tooltip 
        label={isArchived ? "Move to Inbox" : "Archive Thread"} 
        placement="top"
      >
        <Button
          leftIcon={isArchived ? <FaInbox /> : <FaArchive />}
          variant="outline"
          colorScheme={isArchived ? "blue" : "gray"}
          onClick={onArchiveToggle}
          size="md"
        >
          {isArchived ? "Unarchive" : "Archive"}
        </Button>
      </Tooltip>

      <Tooltip label="Add Note" placement="top">
        <Button
          leftIcon={<FaStickyNote />}
          variant="outline"
          colorScheme="yellow"
          onClick={onAddAnnotation}
          size="md"
        >
          Add Note
        </Button>
      </Tooltip>

      <Tooltip 
        label={isMessageEmpty ? "Please enter a message" : "Send Message"} 
        placement="top"
      >
        <Button
          leftIcon={<FaPaperPlane />}
          colorScheme="blue"
          onClick={onSend}
          isLoading={isSending}
          isDisabled={isMessageEmpty || isSending}
          loadingText="Sending..."
          size="md"
        >
          Send
        </Button>
      </Tooltip>
    </HStack>
  );
};

ActionButtons.propTypes = {
  onArchiveToggle: PropTypes.func.isRequired,
  onAddAnnotation: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  isArchived: PropTypes.bool,
  isMessageEmpty: PropTypes.bool,
};

ActionButtons.defaultProps = {
  isSending: false,
  isArchived: false,
  isMessageEmpty: true,
};

export default ActionButtons;
