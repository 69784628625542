// src/components/DashboardControls.js
import React from "react";
import {
  Box,
  Flex,
  Select,
  useColorModeValue,
  Heading,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";
import { MdShowChart, MdBusiness } from "react-icons/md";
import { PiPulse } from "react-icons/pi";

const DashboardControls = ({
  timeRange,
  setTimeRange,
  selectedSection,
  setSelectedSection,
}) => {
  // Theme Colors
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.700", "white");
  const selectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.500", "gray.400");
  const selectedTextColor = useColorModeValue("black.600", "black.200");
  const hoverBg = useColorModeValue("gray.50", "gray.600");

  // Constants
  const timeRangeOptions = [
    { value: 'day', label: 'Last 24 Hours' },
    { value: 'week', label: 'Last 7 Days' },
    { value: 'month', label: 'Last 30 Days' },
  ];

  const sectionOptions = [
    { label: "Sales Activity", icon: MdShowChart },
    { label: "Timeline", icon: PiPulse },
    { label: "Sales Process", icon: MdBusiness },
  ];

  return (
    <Box w="100%" mb={6}>
      {/* Header */}
      <Flex
        flexDirection="column"
        mb="4"
        align={{ base: "flex-start", md: "stretch" }}
      >
        <Heading size="lg" color={textColor} mb="4px">
          Control Center
        </Heading>
      </Flex>

      {/* Controls Container */}
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={6}
        justify="space-between"
        align={{ base: "stretch", md: "center" }}
        w="100%"
      >
        {/* Left side: Section Control */}
        <ButtonGroup
          size="md"
          isAttached
          variant="outline"
          bg={bgColor}
          borderRadius="md"
          borderWidth="1px"
          borderColor={borderColor}
          p="1px"
        >
          {sectionOptions.map((option) => {
            const isSelected = selectedSection === option.label;
            const Icon = option.icon;
            
            return (
              <Button
                key={option.label}
                onClick={() => setSelectedSection(option.label)}
                bg={isSelected ? selectedBg : "transparent"}
                color={isSelected ? selectedTextColor : unselectedTextColor}
                _hover={{
                  bg: hoverBg,
                  color: isSelected ? selectedTextColor : textColor,
                }}
                _active={{
                  bg: selectedBg,
                  color: selectedTextColor,
                }}
                flex="1"
                minW={{ base: "auto", md: "140px" }}
                leftIcon={
                  <Icon
                    style={{
                      color: isSelected ? selectedTextColor : unselectedTextColor,
                    }}
                  />
                }
                borderWidth={0}
                borderRadius="md"
                fontWeight={isSelected ? "600" : "600"}
                boxShadow={isSelected ? "sm" : "none"}
                position="relative"
                zIndex={isSelected ? 1 : 0}
              >
                {option.label}
              </Button>
            );
          })}
        </ButtonGroup>

        {/* Right side: Filters */}
        <Flex 
          direction={{ base: "column", md: "row" }}
          gap={4}
          align={{ base: "stretch", md: "center" }}
        >
          {/* Conditionally render Time Range Selection */}
          {selectedSection !== "Sales Process" && (
            <Select
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              bg={bgColor}
              borderColor={borderColor}
              color={textColor}
              size="md"
              minW="160px"
              _hover={{ borderColor: borderColor }}
              transition="all 0.2s"
            >
              {timeRangeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default DashboardControls;
