// src/components/Deals/DealsTable.jsx

import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
} from "@chakra-ui/react";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { MdBusiness } from 'react-icons/md';
import HoverBox from "../Shared/HoverBox";
import HighlightText from "./HighlightText"; // Ensure correct import path
import PropTypes from "prop-types";

/**
 * Formats the stage name by mapping known stages or formatting unknown ones.
 * @param {string} stage - The stage name to format.
 * @returns {string} - The formatted stage name.
 */
const formatStage = (stage) => {
  const stageMap = {
    "Lead": "Lead",
    "MQL": "MQL",
    "SQL": "SQL",
    "Contract": "Contract",
    "Closed": "Closed",
    "Closed Lost": "Closed Lost",
    "Closed-Paid": "Closed-Paid",
    "Discovery": "Discovery",
    "Demo": "Demo",
  };
  return stageMap[stage] || stage.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};

/**
 * DealsTable Component
 * Displays deals data in a table format.
 *
 * @param {Object} props - Component props
 * @param {Array} props.deals - Array of deal objects
 * @param {Function} props.onViewDetails - Function to handle viewing deal details
 * @param {Function} props.onSort - Function to handle sorting when a header is clicked
 * @param {Object} props.sortConfig - Current sort configuration
 * @param {string} props.searchQuery - Current search query to highlight
 * @returns {JSX.Element}
 */
const DealsTable = ({ deals, onViewDetails, onSort, sortConfig, searchQuery }) => {
  
  /**
   * Renders the sort icon based on the current sort configuration.
   * @param {string} columnKey - The key of the column.
   * @returns {JSX.Element|null}
   */
  const renderSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) return null;
    if (sortConfig.direction === 'ascending') {
      return <FaSortUp style={{ marginLeft: '4px' }} />;
    }
    return <FaSortDown style={{ marginLeft: '4px' }} />;
  };

  // Define fixed widths for each column in pixels
  const columnWidths = {
    deal_name: "200px",
    amount: "120px",
    stage_name: "150px",
    close_date: "150px",
    pipeline_name: "200px",
    lead_source: "200px",
    last_activity_date: "200px", // Added Last Activity column
  };

  return (
    <Box bg="white">
      <Table variant="simple" bg="white" width="100%" tableLayout="fixed">
        <Thead bg="white">
          <Tr>
            <Th
              cursor="pointer"
              onClick={() => onSort('deal_name')}
              width={columnWidths.deal_name}
              whiteSpace="nowrap"
            >
              Name
              {renderSortIcon('deal_name')}
            </Th>
            {/* Removed the Account column header */}
            {/* Removed the Owner column header */}
            <Th
              cursor="pointer"
              onClick={() => onSort('amount')}
              width={columnWidths.amount}
              whiteSpace="nowrap"
            >
              Amount
              {renderSortIcon('amount')}
            </Th>
            <Th
              cursor="pointer"
              onClick={() => onSort('stage_name')}
              width={columnWidths.stage_name}
              whiteSpace="nowrap"
            >
              Stage
              {renderSortIcon('stage_name')}
            </Th>
            <Th
              cursor="pointer"
              onClick={() => onSort('close_date')}
              width={columnWidths.close_date}
              whiteSpace="nowrap"
            >
              Close Date
              {renderSortIcon('close_date')}
            </Th>
            <Th
              cursor="pointer"
              onClick={() => onSort('pipeline_name')}
              width={columnWidths.pipeline_name}
              whiteSpace="nowrap"
            >
              Pipeline
              {renderSortIcon('pipeline_name')}
            </Th>
            <Th
              cursor="pointer"
              onClick={() => onSort('lead_source')}
              width={columnWidths.lead_source}
              whiteSpace="nowrap"
            >
              Source
              {renderSortIcon('lead_source')}
            </Th>
            <Th
              cursor="pointer"
              onClick={() => onSort('last_activity_date')}
              width={columnWidths.last_activity_date}
              whiteSpace="nowrap"
            >
              Last Activity
              {renderSortIcon('last_activity_date')}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {deals.map((deal) => (
            <Tr key={deal.id} _hover={{ bg: "gray.50" }}>
              <Td>
                <HoverBox
                  icon={MdBusiness}
                  onClick={() => onViewDetails(deal)}
                  tooltipLabel="View Deal Details"
                >
                  <HighlightText
                    text={deal.deal_name || "Unnamed Deal"}
                    query={searchQuery}
                  />
                </HoverBox>
              </Td>
              {/* Removed the Account column data cell */}
              {/* Removed the Owner column data cell */}
              <Td>
                <Box isTruncated maxWidth="100%" fontSize="md">
                  {deal.amount
                    ? `$${parseFloat(deal.amount).toLocaleString()}`
                    : "—"}
                </Box>
              </Td>
              <Td>
                <Box display="flex" alignItems="center" fontSize="md">
                  <Box
                    height="10px"
                    width="10px"
                    borderRadius="full"
                    bg={`${{
                      "Lead": "blue",
                      "MQL": "purple",
                      "SQL": "orange",
                      "Contract": "green",
                      "Closed": "teal",
                      "Closed Lost": "red",
                      "Closed-Paid": "green",
                      "Discovery": "yellow",
                      "Demo": "pink",
                    }[deal.stage_name] || "gray"}.500`}
                    mr={2}
                  ></Box>
                  <Text color="black" isTruncated maxWidth="100%">
                    <HighlightText
                      text={formatStage(deal.stage_name || "Unknown")}
                      query={searchQuery}
                    />
                  </Text>
                </Box>
              </Td>
              <Td>
                {deal.close_date ? (
                  <Box display="flex" alignItems="center" fontSize="md">
                    <Text isTruncated maxWidth="100%">
                      {new Date(deal.close_date).toLocaleDateString(undefined, {
                        year: '2-digit',
                        month: 'numeric',
                        day: 'numeric',
                      })}
                    </Text>
                  </Box>
                ) : "—"}
              </Td>
              <Td>
                <Box isTruncated maxWidth="100%" fontSize="md">
                  <HighlightText
                    text={deal.pipeline_name || "—"}
                    query={searchQuery}
                  />
                </Box>
              </Td>
              <Td>
                <Box isTruncated maxWidth="100%" fontSize="md">
                  <HighlightText
                    text={deal.lead_source || "—"}
                    query={searchQuery}
                  />
                </Box>
              </Td>
              <Td>
                {deal.last_activity_date ? (
                  <Box display="flex" alignItems="center" fontSize="md">
                    <Text isTruncated maxWidth="100%">
                      {formatLastActivityDate(deal.last_activity_date)}
                    </Text>
                  </Box>
                ) : "—"}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

/**
 * Formats the last_activity_date to "MM/DD/YY h:mm AM/PM"
 * @param {string} dateString - The ISO date string to format.
 * @returns {string} - The formatted date string.
 */
const formatLastActivityDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString(undefined, {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  });
  const formattedTime = date.toLocaleTimeString(undefined, {
    hour: 'numeric', // 'numeric' removes leading zero
    minute: '2-digit',
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
};

// Define PropTypes for type checking
DealsTable.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewDetails: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(['ascending', 'descending']),
  }).isRequired,
  searchQuery: PropTypes.string, // New Prop
};

// Define default props
DealsTable.defaultProps = {
  searchQuery: "",
};

export default DealsTable;
