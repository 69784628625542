// src/components/Deals/StagesSection.jsx

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  HStack,
  VStack,
  Text,
  Tag,
  Checkbox,
  Collapse,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';

/**
 * Helper function to determine if a stage is completed.
 * A stage is considered completed if all required questions are answered.
 *
 * @param {Object} stage - The stage object containing questions.
 * @returns {boolean} - True if the stage is completed, else false.
 */
const isStageCompleted = (stage) => {
  if (!stage.questions || stage.questions.length === 0) return false;
  return stage.questions.every(
    (question) => !question.required || (question.answered && question.answer.trim() !== '')
  );
};

/**
 * StageTag Component
 * Represents a single stage as a Tag with dynamic icons based on hover and expansion.
 */
const StageTag = ({ stage, isCompleted, isExpanded, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Define consistent styling for all tags
  const tagHeight = '40px'; // Adjust as needed for consistent height
  const bgColor = 'gray.100'; // Light gray background
  const borderColor = 'gray.400'; // Darker gray border

  // Determine which icon to display
  let IconComponent = null;

  if (isExpanded) {
    IconComponent = <FaChevronUp color={useColorModeValue('blue.500', 'blue.300')} />;
  } else if (isHovered) {
    IconComponent = <FaChevronDown color={useColorModeValue('blue.500', 'blue.300')} />;
  } else {
    IconComponent = isCompleted ? (
      <Checkbox isChecked={true} isReadOnly size="sm" colorScheme="green" />
    ) : (
      <Box as={FaCircle} color={useColorModeValue('gray.500', 'gray.300')} />
    );
  }

  return (
    <Tooltip label="Show Qualification Criteria" aria-label={`Tooltip for ${stage.name}`}>
      <Tag
        size="lg"
        variant="outline"
        bg={bgColor}
        borderColor={borderColor}
        cursor="pointer"
        onClick={onClick}
        display="flex"
        alignItems="center"
        gap={2}
        height={tagHeight}
        _hover={{
          bg: 'gray.200',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        tabIndex="0" // Make the Tag focusable
        role="button" // Inform assistive technologies that this is a button
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick();
          }
        }}
      >
        {IconComponent}
        <Text fontSize="sm" fontWeight="medium">
          {stage.name}
        </Text>
      </Tag>
    </Tooltip>
  );
};

StageTag.propTypes = {
  stage: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        answered: PropTypes.bool.isRequired,
        answer: PropTypes.string,
      })
    ),
  }).isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

/**
 * StageDetails Component
 * Displays detailed questions and answers for a specific stage.
 */
const StageDetails = ({ stage }) => {
  return (
    <Box
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="md"
      p={4}
      bg={useColorModeValue('gray.50', 'gray.700')}
      mt={2}
    >
      <Text fontSize="md" fontWeight="semibold" mb={3}>
        Questions
      </Text>
      <VStack align="stretch" spacing={3}>
        {stage.questions && stage.questions.length > 0 ? (
          stage.questions.map((question) => (
            <Box
              key={question.id}
              p={3}
              bg={useColorModeValue('white', 'gray.600')}
              borderRadius="md"
            >
              <HStack justifyContent="space-between">
                <Text fontWeight="medium">{question.text}</Text>
                {question.required && <Text color="red.500">Required</Text>}
              </HStack>
              <HStack mt={1}>
                <Text color={question.answered ? 'green.500' : 'red.500'}>
                  {question.answered ? 'Answered' : 'Not Answered'}
                </Text>
                {question.answered && (
                  <Text fontSize="sm" color="gray.600">
                    {question.answer}
                  </Text>
                )}
              </HStack>
            </Box>
          ))
        ) : (
          <Text color="gray.500">No questions available for this stage.</Text>
        )}
      </VStack>
    </Box>
  );
};

StageDetails.propTypes = {
  stage: PropTypes.shape({
    name: PropTypes.string.isRequired,
    qualification: PropTypes.bool.isRequired,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        answered: PropTypes.bool.isRequired,
        answer: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

/**
 * StagesSection Component
 * Displays deal stages as clickable tags and provides expandable sections for details.
 */
const StagesSection = ({ stages }) => {
  // State to track which stage is currently expanded
  const [expandedStageId, setExpandedStageId] = useState(null);

  /**
   * Toggles the expanded state for a specific stage.
   * If the clicked stage is already expanded, collapse it; otherwise, expand it.
   *
   * @param {number|string} stageId - The ID of the stage to toggle.
   */
  const toggleStage = (stageId) => {
    setExpandedStageId((prev) => (prev === stageId ? null : stageId));
  };

  /**
   * Memoized list of stages with their completion status.
   */
  const stagesWithStatus = useMemo(() => {
    return stages.map((stage) => ({
      ...stage,
      isCompleted: isStageCompleted(stage),
    }));
  }, [stages]);

  return (
    <Box>
      {/* Updated Header */}
      <HStack justifyContent="space-between" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Deal Stage Qualification
        </Text>
      </HStack>

      {/* Stages as Clickable Tags */}
      <HStack spacing={3} wrap="wrap">
        {stagesWithStatus.map((stage) => (
          <StageTag
            key={stage.id}
            stage={stage}
            isCompleted={stage.isCompleted}
            isExpanded={expandedStageId === stage.id}
            onClick={() => toggleStage(stage.id)}
          />
        ))}
      </HStack>

      {/* Expanded Stage Details */}
      {stagesWithStatus.map((stage) => (
        <Collapse in={expandedStageId === stage.id} animateOpacity key={stage.id}>
          <StageDetails stage={stage} />
        </Collapse>
      ))}
    </Box>
  );
};

StagesSection.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          text: PropTypes.string,
          required: PropTypes.bool,
          answered: PropTypes.bool,
          answer: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};

export default StagesSection;
