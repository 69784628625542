// src/components/Shared/GoBackHeader.jsx

import React from "react";
import PropTypes from "prop-types";
import {
  Flex,
  IconButton,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowLeft } from "lucide-react";

/**
 * GoBackHeader Component
 * Renders a header with a back button and a title.
 *
 * @param {Object} props - Component props
 * @param {Function} props.onGoBack - Function to call when the back button is clicked
 * @param {string} props.title - The title to display next to the back button
 * @returns {JSX.Element}
 */
const GoBackHeader = ({ onGoBack, title }) => {
  const textColor = useColorModeValue("#56595E", "white");

  return (
    <Flex align="center" mb={2} mt={{ base: 16, md: 8 }}>
      <IconButton
        icon={<ArrowLeft size={20} />}
        aria-label="Go back"
        onClick={onGoBack}
        variant="ghost"
        mr={1}
        color={textColor}
        size="lg"
      />
      <Heading as="h1" size="md" color={textColor}>
        {title}
      </Heading>
    </Flex>
  );
};

// Define PropTypes for type checking
GoBackHeader.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default GoBackHeader;
