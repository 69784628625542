// AccountSection.js
import React, { useContext } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Input,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { SidebarContext } from "contexts/SidebarContext";
import routesData from "routes.js"; // Ensure the correct path to your routes.js
import { useAuthData } from "auth-context";

export default function AccountSection() {
  const { visibleRoutes, toggleRouteVisibility } = useContext(SidebarContext);
  const userData = useAuthData();
  const userID = userData?.user_ID;

  // Define essential routes that cannot be hidden
  const essentialRoutes = ["Settings", "NoRoutes"]; // Add any other essential route names here
  // If user data is still loading, show a loading message or spinner
  if (!userID) {
    return (
      <Box textAlign="center" p={6}>
        <Spinner size="lg" />
        <Text mt={4} fontSize="md" color="blue.500">
          Loading user data...
        </Text>
      </Box>
    );
  }
  return (
    <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="md">
      <Heading as="h3" size="md" mb="6">
        Account
      </Heading>

      <VStack align="start" spacing={4}>
        {/* Username */}
        <Box w="100%">
          <Text mb={2} fontWeight="bold">
            Username
          </Text>
          <Input value={userData.name} isReadOnly />
        </Box>

        {/* Email */}
        <Box w="100%">
          <Text mb={2} fontWeight="bold">
            Email
          </Text>
          <Input value={userData.email} isReadOnly />
        </Box>

        {/* Team */}
        {/* <Box w="100%">
          <Heading as="h4" size="sm" mt={6} mb={2}>
            Team
          </Heading>
          <Text>Other accounts on the same team: John Doe, Sarah Smith</Text>
        </Box> */}

        {/* Plan
        <Box w="100%">
          <Heading as="h4" size="sm" mt={6} mb={2}>
            Plan
          </Heading>
          <Text>Lysto Pro</Text>
          <Text>Subscription started on: 11/8/24</Text>
          <Text>Plan renews on: 11/8/25</Text>
        </Box> */}

        {/* Contact Email */}
        <Box w="100%">
          <Heading as="h4" size="sm" mt={6} mb={2}>
            Contact Email
          </Heading>
          <Text mb={2}>Where should invoices be sent?</Text>
          <RadioGroup defaultValue="existing">
            <Stack direction="column">
              <Radio value="existing">
                Send to the existing email ({userData.email})
              </Radio>
              <Radio value="new">Add another email address</Radio>
            </Stack>
          </RadioGroup>
        </Box>

        {/* Sidebar Route Visibility Section */}
        {(userID === 6 || userID === 8) && (
          <>
            <Divider mt={6} mb={4} />
            <Box w="100%">
              <Heading as="h4" size="sm" mb={2}>
                Sidebar Route Visibility
              </Heading>
              <VStack align="start" spacing={2}>
                {routesData.map((route) => (
                  <Checkbox
                    key={route.name}
                    isChecked={visibleRoutes[route.name]}
                    onChange={() => toggleRouteVisibility(route.name)}
                    isDisabled={essentialRoutes.includes(route.name)}
                  >
                    {route.name}
                    {essentialRoutes.includes(route.name) && (
                      <Text as="span" color="gray.500" fontSize="sm" ml={2}>
                        (Essential)
                      </Text>
                    )}
                  </Checkbox>
                ))}
              </VStack>
              <Text fontSize="sm" color="gray.500" mt={2}>
                Toggle the routes you want to display in the sidebar. Essential
                routes cannot be hidden.
              </Text>
            </Box>
          </>
        )}
      </VStack>
    </Box>
  );
}
