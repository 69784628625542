// src/components/Contacts/ContactDetail.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, VStack } from '@chakra-ui/react';
import ContactOverviewSection from './ContactOverviewSection';
import ContactDealsSection from './ContactDealsSection';
import ContactActivity from './ContactActivity';

/**
 * ContactDetail Component
 * Displays detailed information about a contact, including Overview, Deals, and Activity.
 *
 * @param {Object} props
 * @param {Object} props.contact - The contact object to display.
 * @returns {JSX.Element}
 */
const ContactDetail = ({ contact }) => {
  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">

        {/* Combined Overview and Deals Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <ContactOverviewSection
              email={contact.email}
              title={contact.title}
              source={contact.source}
              status={contact.status}
            />
            <ContactDealsSection deals={contact.deals || []} />
          </VStack>
        </Box>

        {/* Activity Section */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <ContactActivity accountId={contact.account_id} contactId={contact.id} />
        </Box>

      </VStack>
    </Box>
  );
};

ContactDetail.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    crm_account_id: PropTypes.number,
    crm_account_name: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    deals: PropTypes.array,
    created_date: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default ContactDetail;
