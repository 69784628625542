// src/hooks/useFetchDeals.js

import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Custom hook to fetch deals based on the account ID.
 * @param {string} accountID - The account ID to fetch deals for.
 * @returns {Object} - Contains deals data, loading state, and error state.
 */
const useFetchDeals = (accountID) => {
  const { getAccessTokenSilently } = useAuth0();
  const [deals, setDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchDeals = async () => {
      if (!accountID) {
        setIsLoading(false);
        setDeals([]); // Ensure deals are empty if no accountID
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target = process.env.REACT_APP_API_BASE_URL || "https://lysto-be-tf.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/deals`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setDeals(response.data || []); // Set to empty array if response.data is falsy
        setIsError(false);
      } catch (error) {
        console.error("Error fetching deals:", error);
        setDeals([]); // Set deals to empty array on error
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeals();
  }, [accountID, getAccessTokenSilently]);

  return { deals, isLoading, isError };
};

export default useFetchDeals;
