// src/components/Inbox/ChatMessage.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Icon, Tooltip } from '@chakra-ui/react';
import { MdNotes } from 'react-icons/md';
import DOMPurify from 'dompurify';
import { extractEmailContent } from './extractEmailContent';

const ChatMessage = ({ message, isNote }) => {
  const isAgent = message.identity === 'agent';
  const messageDate = message.date_sent ? new Date(message.date_sent) : null;
  const timeAgo = messageDate
    ? `${messageDate.toLocaleDateString()} ${messageDate.toLocaleTimeString()}`
    : 'N/A';

  // Extract and clean the email content or use subject if content is empty
  const cleanedContent = message.content && message.content.trim() !== ""
    ? extractEmailContent(message.content)
    : message.subject || "No content available.";

  // Sanitize the HTML content
  const sanitizedContent = DOMPurify.sanitize(cleanedContent);

  // Determine the color scheme based on the message type and identity
  const getColorScheme = () => {
    if (isNote) return 'yellow';
    if (isAgent) return 'blue';
    return 'gray';
  };

  const colorScheme = getColorScheme();

  return (
    <Flex direction="column" alignItems="stretch" width="100%" mb={4}>
      {/* Sender info */}
      <Box
        as="p"
        fontSize="xs"
        color="gray.500"
        mb={1}
        textAlign={isAgent ? 'right' : 'left'}
      >
        {message.sender_name ||
          message.sender_email ||
          (isAgent ? 'Agent' : 'Customer')}
      </Box>

      {/* Message content */}
      <Flex justifyContent={isAgent ? 'flex-end' : 'flex-start'}>
        {isNote && (
          <Icon as={MdNotes} color="gray.500" boxSize={4} mr={2} mt={1} />
        )}
        <Tooltip label={timeAgo} placement="top">
          <Box
            bg={`${colorScheme}.50`}
            color={`${colorScheme}.700`}
            px={3}
            py={2}
            borderRadius="md"
            borderWidth="1px"
            borderColor={`${colorScheme}.200`}
            wordBreak="break-word"
            maxWidth="80%" // Expand up to 80% of the parent container
            whiteSpace="pre-wrap"
            _hover={{
              bg: `${colorScheme}.50`,
            }}
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.shape({
    message_id: PropTypes.string.isRequired,
    identity: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired, // Corrected field
    type: PropTypes.string,
    status: PropTypes.string,
    date_sent: PropTypes.string,
    sender_name: PropTypes.string,
    sender_email: PropTypes.string,
  }).isRequired,
  isNote: PropTypes.bool,
};

ChatMessage.defaultProps = {
  isNote: false,
};

export default ChatMessage;
