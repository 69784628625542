import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Box } from '@chakra-ui/react';
import remarkGfm from 'remark-gfm';

const MarkdownRenderer = ({ content }) => (
  <Box
    className="markdown-content"
    // Removed border properties
    // borderWidth="1px"
    // borderColor="gray.200"
    // borderRadius="md"
    p={6}  // Adjusted padding from 10 to 6 for better spacing
    // Removed maxHeight and overflow to allow full content display
    // maxHeight="400px"
    // overflowY="auto"
    fontSize="sm"  // Maintain smaller font size
    sx={{
      // Removed scrollbar styles since scrolling is no longer needed
      /*
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'gray.100',
        borderRadius: 'md',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'gray.400',
        borderRadius: 'md',
      },
      */
      // Even smaller headers
      'h1': {
        fontSize: '1.1em',
        fontWeight: '600',
        mb: 3,
        mt: 2,
        color: 'gray.800',
      },
      'h2': {
        fontSize: '1em',
        fontWeight: '600',
        mb: 2,
        mt: 3,
        color: 'gray.700',
      },
      'h3': {
        fontSize: '0.95em',
        fontWeight: '600',
        mb: 2,
        mt: 2,
        color: 'gray.700',
      },
      // List styling
      'ul, ol': {
        pl: 4,
        mb: 4,
      },
      'li': {
        mb: 2,
        listStyleType: 'none',
        position: 'relative',
        pl: 2,
        fontSize: '0.95em',  // Slightly smaller list items
      },
      'ul > li::before': {
        content: '"•"',
        position: 'absolute',
        left: '-12px',
        color: 'gray.600',
      },
      'ol > li': {
        listStyleType: 'decimal',
        ml: 2,
      },
      // Nested lists
      'ul ul, ol ol, ul ol, ol ul': {
        mt: 2,
        mb: 0,
        ml: 2,
      },
      // Paragraphs
      'p': {
        mb: 3,
        lineHeight: 'tall',
        fontSize: '0.95em',  // Slightly smaller paragraphs
      },
      // Content sections spacing
      '> *:first-of-type': {
        mt: 0,
      },
      '> *:last-child': {
        mb: 0,
      },
      // Section spacing
      'h1 + ul, h2 + ul, h3 + ul': {
        mt: 2,
      },
    }}
  >
    <ReactMarkdown 
      remarkPlugins={[remarkGfm]}
    >
      {content || 'No guidance content available.'}
    </ReactMarkdown>
  </Box>
);

MarkdownRenderer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default MarkdownRenderer;
