// src/components/Contacts/ContactsFilterControls.jsx

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";
import PropTypes from "prop-types";

/**
 * ContactsFilterControls Component
 * Renders a button group for status filtering and a search bar with suggestions.
 *
 * @param {Object} props - Component props
 * @param {string} props.currentStatusFilter - The currently selected status filter
 * @param {Function} props.setCurrentStatusFilter - Function to update the status filter
 * @param {string} props.searchQuery - Current search query
 * @param {Function} props.setSearchQuery - Function to update the search query
 * @param {Array} props.searchSuggestions - Array of strings for search suggestions
 * @returns {JSX.Element}
 */
const ContactsFilterControls = ({
  currentStatusFilter,
  setCurrentStatusFilter,
  searchQuery,
  setSearchQuery,
  searchSuggestions,
}) => {
  // Theme Colors
  const selectedBg = useColorModeValue("blue.500", "blue.300");
  const selectedTextColor = useColorModeValue("white", "gray.800");
  const unselectedBg = useColorModeValue("white", "gray.600");
  const unselectedTextColor = useColorModeValue("gray.700", "gray.200");
  const hoverBg = useColorModeValue("gray.200", "gray.500");
  const searchBg = useColorModeValue("white", "gray.600");
  const searchBorderColor = useColorModeValue("gray.300", "gray.500");

  // Local state for managing dropdown visibility
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Handle input changes and filter suggestions
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    } else {
      const filtered = searchSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    }
  }, [searchQuery, searchSuggestions]);

  // Handle selecting a suggestion
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setShowSuggestions(false);
  };

  // Status filter options - Reordered to Active, Inactive, All
  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "All", value: "all" },
  ];

  return (
    <Flex
      justify="space-between"
      align="center"
      mb={4}
      flexWrap="wrap"
      gap={4}
    >
      {/* Left Side: Status Filter Button Group */}
      <ButtonGroup isAttached variant="outline" spacing={0}>
        {statusOptions.map((option) => {
          const isSelected = currentStatusFilter === option.value;
          return (
            <Button
              key={option.value}
              onClick={() => setCurrentStatusFilter(option.value)}
              bg={isSelected ? selectedBg : unselectedBg}
              color={isSelected ? selectedTextColor : unselectedTextColor}
              _hover={{
                bg: isSelected ? selectedBg : hoverBg,
                color: isSelected ? selectedTextColor : "inherit",
              }}
              _active={{
                bg: isSelected ? selectedBg : hoverBg,
                color: isSelected ? selectedTextColor : "inherit",
              }}
              borderRadius="md"
              minW="100px"
            >
              {option.label}
            </Button>
          );
        })}
      </ButtonGroup>

      {/* Right Side: Search Bar with Suggestions */}
      <Box width={{ base: "100%", md: "300px" }} position="relative">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={MdSearch} color="gray.500" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search contacts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            bg={searchBg}
            borderColor={searchBorderColor}
            onFocus={() => {
              if (filteredSuggestions.length > 0) setShowSuggestions(true);
            }}
            onBlur={() => {
              // Delay hiding to allow click event to register
              setTimeout(() => setShowSuggestions(false), 100);
            }}
          />
        </InputGroup>

        {/* Suggestions Dropdown */}
        {showSuggestions && filteredSuggestions.length > 0 && (
          <Box
            position="absolute"
            top="100%"
            left="0"
            right="0"
            bg={useColorModeValue("white", "gray.700")}
            border="1px solid"
            borderColor={useColorModeValue("gray.200", "gray.600")}
            borderRadius="md"
            mt={1}
            zIndex={10}
            maxHeight="200px"
            overflowY="auto"
          >
            {filteredSuggestions.map((suggestion, index) => (
              <Box
                key={index}
                px={4}
                py={2}
                cursor="pointer"
                _hover={{
                  bg: useColorModeValue("gray.100", "gray.600"),
                }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Flex>
  );
};

// Define PropTypes for type checking
ContactsFilterControls.propTypes = {
  currentStatusFilter: PropTypes.string.isRequired,
  setCurrentStatusFilter: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  searchSuggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ContactsFilterControls;
