import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Updated to useNavigate
import {
  Box,
  VStack,
  Heading,
  useColorModeValue,
  IconButton,
  Flex,
  Spinner,
  Center,
  Text,
} from "@chakra-ui/react";
import { ArrowLeft } from "lucide-react";
import AccountDetail from "./AccountDetail"; // Import AccountDetail component
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthData } from "auth-context";
import Activity from "views/lysto/Activities/Activity"; // Import Activity component

const AccountDetailsPage = () => {
  const [account, setAccount] = useState(null); // State for account details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const { accountId } = useParams(); // Account ID from route params
  const navigate = useNavigate(); // Updated to useNavigate
  const textColor = useColorModeValue("#56595E", "white");

  const { getAccessTokenSilently } = useAuth0();
  const userData = useAuthData();
  const userAccountID = userData?.account_ID;

  // Fetch account details by accountId
  const fetchAccountById = async (accountId) => {
    setLoading(true);
    setError(null);

    if (!userAccountID) {
      setError("Invalid user account ID.");
      setLoading(false);
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          scope: "read:calendar",
        },
      });

      const target =
        process.env.REACT_APP_NODE_ENV === "local"
          ? "http://localhost:8080"
          : "https://lysto-be-tf.azurewebsites.net";

      const response = await axios.get(
        `${target}/api/accounts/${userAccountID}/accounts/${accountId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setAccount(response.data); // Set the fetched account data
    } catch (error) {
      console.error("Error fetching account:", error);
      setError("Failed to fetch account data. Displaying fallback information.");
      
      // Fallback dummy data for account
      const fallbackAccount = {
        id: accountId,
        business_name: "Example Corp",
        industry: "Technology",
        location: "San Francisco, CA",
        summary:
          "Example Corp is a technology company specializing in AI solutions.",
        contacts: ["John Doe", "Jane Smith"],
        deals: ["Deal 1", "Deal 2"],
      };

      setAccount(fallbackAccount);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountById(accountId);
  }, [accountId, userAccountID, getAccessTokenSilently]);

  const handleGoBack = () => {
    navigate(-1); // Updated for React Router v6
  };

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error && !account) {
    return (
      <Center h="100vh">
        <Text color="red.500">{error}</Text>
      </Center>
    );
  }

  return (
    <Box bg="#F6F6F8" p={1} width="100%">
      <Box maxWidth="1800px" marginX="auto">
        <Flex align="center" mb={4} mt={16}>
          <IconButton
            icon={<ArrowLeft />}
            aria-label="Go back"
            onClick={handleGoBack}
            mr={4}
            variant="ghost"
            color={textColor}
          />
          <Heading as="h1" size="md" color={textColor}>
            Account Information
          </Heading>
        </Flex>
        <VStack spacing={4} align="stretch">
          {/* Render AccountDetail component with the account data */}
          {account && (
            <AccountDetail account={account} onClose={handleGoBack} />
          )}
          <Activity /> {/* Include the Activity component */}
        </VStack>
      </Box>
    </Box>
  );
};

export default AccountDetailsPage;
