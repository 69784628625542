// src/components/Deals/DealDetail.jsx

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  VStack,
} from '@chakra-ui/react';
import OverviewSection from './OverviewSection'; // Ensure this is correctly implemented
import DealSummarySection from './DealSummarySection'; // Ensure this is correctly implemented
import StagesSection from './StagesSection'; // Ensure this is correctly implemented
import DealActivity from './DealActivity'; // Import the new component

const DealDetail = ({ deal }) => {
  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">

        {/* Card 1: Overview and Summary */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <OverviewSection
              accountName={deal.crm_account_name}
              dealName={deal.deal_name}
              amount={deal.amount}
              stage={deal.stage_name}
              lastActivityDate={deal.last_activity_date}
              startDate={deal.deal_summary?.start_date} // Pass startDate
              endDate={deal.deal_summary?.end_date} // Pass endDate
            />

            {/* Deal Summary */}
            <DealSummarySection
              summary={deal.deal_summary?.summary}
              shortSummary={deal.deal_summary?.short_summary} // Pass shortSummary
              startDate={deal.deal_summary?.start_date}
              endDate={deal.deal_summary?.end_date}
            />
          </VStack>
        </Box>

        {/* Card 2: Stages and Questions */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <StagesSection stages={deal.stages} />
          </VStack>
        </Box>

        {/* Card 3: Deal Activity */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <DealActivity accountId={deal.account_id} dealId={deal.id} />
          </VStack>
        </Box>

      </VStack>
    </Box>
  );
};

DealDetail.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_id: PropTypes.number.isRequired,
    crm_account_id: PropTypes.number,
    crm_account_name: PropTypes.string,
    user_id: PropTypes.number,
    user_name: PropTypes.string,
    user_last_name: PropTypes.string,
    user_email: PropTypes.string,
    description: PropTypes.string,
    amount: PropTypes.string,
    close_date: PropTypes.string,
    deal_name: PropTypes.string,
    pipeline_id: PropTypes.number,
    pipeline_name: PropTypes.string,
    stage_name: PropTypes.string,
    stage_id: PropTypes.number,
    deal_summary: PropTypes.shape({
      summary: PropTypes.string,
      short_summary: PropTypes.string, // Added short_summary to prop types
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    }),
    lead_source: PropTypes.string,
    created_date: PropTypes.string,
    contacts: PropTypes.array,
    stages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        account_id: PropTypes.number,
        pipeline_id: PropTypes.number,
        name: PropTypes.string,
        display_order: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        is_active: PropTypes.bool,
        qualification: PropTypes.bool,
        questions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            stage_id: PropTypes.number,
            text: PropTypes.string,
            required: PropTypes.bool,
            answered: PropTypes.bool,
          })
        ),
      })
    ),
    last_activity_date: PropTypes.string,
  }).isRequired,
};

export default DealDetail;
