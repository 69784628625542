// src/components/AggregatedStatsGrid.js

import React, { useState, useEffect } from "react";
import { Box, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthData } from "auth-context"; // Ensure this hook is correctly implemented
import StatCard from "./StatCard";
import { MdEmail, MdCalendarToday, MdMic } from "react-icons/md"; // Updated Icons
import { FaLinkedin } from "react-icons/fa";
import { addDays, format } from "date-fns"; // Install date-fns if not already installed

/**
 * Categorization logic for activities
 */
const getActivityCategory = (activity) => {
  const description = activity.description || "";
  const title = activity.title || "";
  const communication_medium =
    activity.classification?.communication_medium?.toLowerCase() || "";

  // New Condition:
  // If title includes "[Gong]" and description does NOT include the Gong URL, categorize as "calendar-update"
  if (
    title.toLowerCase().includes("[gong]") &&
    !description.includes("https://us-70510.app.gong.io/call?id=")
  ) {
    return "calendar-update";
  }

  if (description.includes("https://us-70510.app.gong.io/call?id=")) {
    return "recording"; // Align with "recording" category in tabs
  } else if (
    communication_medium === "gong" ||
    /(Calendly|Meeting)/i.test(title)
  ) {
    return "calendar-update"; // Align with "calendar-update" category in tabs
  } else if (activity.activity_type?.toLowerCase() === "email") {
    return "email"; // Align with "email" category in tabs
  } else {
    return "other"; // Default category
  }
};

/**
 * AggregatedStatsGrid Component
 * Fetches user-specific activity details from the new endpoint, classifies activities,
 * combines with LinkedIn data, and displays aggregated user statistics with growth calculations.
 */
const AggregatedStatsGrid = ({ timeRange }) => {
  const [aggregatedStats, setAggregatedStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  // Authentication and account info
  const { getAccessTokenSilently } = useAuth0();
  const userData = useAuthData();
  const accountID = userData?.account_ID;

  useEffect(() => {
    const fetchData = async () => {
      if (!accountID) {
        setError("Account ID not found.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        // Define duration based on timeRange
        let durationDays;

        switch (timeRange) {
          case "week":
            durationDays = 7;
            break;
          case "month":
            durationDays = 30;
            break;
          case "day":
            durationDays = 1;
            break;
          // Add more cases as needed
          default:
            durationDays = 7;
        }

        const endDate = new Date(); // Today

        // Current period
        const currentStartDate = addDays(endDate, -durationDays);
        const formattedCurrentStartDate = format(
          currentStartDate,
          "yyyy-MM-dd"
        );
        const formattedCurrentEndDate = format(endDate, "yyyy-MM-dd");

        // Previous period
        const previousEndDate = addDays(currentStartDate, -1);
        const previousStartDate = addDays(previousEndDate, -durationDays);
        const formattedPreviousStartDate = format(
          previousStartDate,
          "yyyy-MM-dd"
        );
        const formattedPreviousEndDate = format(previousEndDate, "yyyy-MM-dd");

        // Get access token
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:dashboard", // Adjust scope as needed
          },
        });

        const apiUrl =
          process.env.REACT_APP_API_URL ||
          "https://lysto-be-tf.azurewebsites.net";
        const newDealActivityUrl = `${apiUrl}/accounts/${accountID}/deal/activity-detail-by-days-and-users`;
        const linkedinActivityUrl = `${apiUrl}/accounts/${accountID}/linkedin/activity-count-by-days-and-user`;

        // Make API calls in parallel for deal activities and LinkedIn data
        const [currentDealRes, previousDealRes, linkedinRes] =
          await Promise.all([
            // Current Deal Activities
            axios.get(newDealActivityUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: {
                start_date: formattedCurrentStartDate,
                end_date: formattedCurrentEndDate,
              },
            }),
            // Previous Deal Activities
            axios.get(newDealActivityUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: {
                start_date: formattedPreviousStartDate,
                end_date: formattedPreviousEndDate,
              },
            }),
            // LinkedIn Activities
            axios.get(linkedinActivityUrl, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: {
                days: durationDays * 2, // Fetch data covering both periods if needed
                period: timeRange, // e.g., 'week', 'month'
              },
            }),
          ]);

        // Extract data from responses
        let currentDealData = currentDealRes.data.activities || [];
        let previousDealData = previousDealRes.data.activities || [];

        // Deduplicate currentDealData based on title
        const uniqueCurrentActivities = [];
        const titleSetCurrent = new Set();

        for (const activity of currentDealData) {
          const title = activity.title || "";
          if (!titleSetCurrent.has(title)) {
            titleSetCurrent.add(title);
            uniqueCurrentActivities.push(activity);
          }
        }

        // Deduplicate previousDealData based on title
        const uniquePreviousActivities = [];
        const titleSetPrevious = new Set();

        for (const activity of previousDealData) {
          const title = activity.title || "";
          if (!titleSetPrevious.has(title)) {
            titleSetPrevious.add(title);
            uniquePreviousActivities.push(activity);
          }
        }

        // Aggregate deal data using the new categorization logic
        const currentAggregated = aggregateData(uniqueCurrentActivities);
        const previousAggregated = aggregateData(uniquePreviousActivities);

        // Aggregate LinkedIn data
        const linkedinData = linkedinRes.data || []; // Assuming this includes both current and previous data per user
        const linkedinAggregated = aggregateLinkedInData(linkedinData);

        // Compute growth percentages for deal activities
        const computeGrowth = (current, previous) => {
          if (previous === 0) {
            return current === 0 ? 0 : 100; // Define as 100% growth if no previous data
          }
          return ((current - previous) / previous) * 100;
        };

        // Calculate growth for each metric
        const aggregatedStats = {
          recordings: {
            count: currentAggregated.recordings.count,
            growth: computeGrowth(
              currentAggregated.recordings.count,
              previousAggregated.recordings.count
            ),
          },
          calendarUpdates: {
            count: currentAggregated.calendarUpdates.count,
            growth: computeGrowth(
              currentAggregated.calendarUpdates.count,
              previousAggregated.calendarUpdates.count
            ),
          },
          emails: {
            count: currentAggregated.emails.count,
            growth: computeGrowth(
              currentAggregated.emails.count,
              previousAggregated.emails.count
            ),
          },
          linkedin_messages_sent: {
            count: linkedinAggregated.messagesSent.current,
            growth: linkedinAggregated.messagesSent.growth,
          },
          linkedin_messages_received: {
            count: linkedinAggregated.messagesReceived.current,
            growth: linkedinAggregated.messagesReceived.growth,
          },
          linkedin_invites: {
            count: linkedinAggregated.invites.current,
            growth: linkedinAggregated.invites.growth,
          },
        };

        setAggregatedStats(aggregatedStats);
      } catch (err) {
        console.error("Error fetching aggregated stats:", err);
        setError("Failed to fetch aggregated stats.");
        toast({
          title: "Error",
          description: "Failed to fetch aggregated stats.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accountID, timeRange, getAccessTokenSilently, toast]);

  /**
   * Aggregates data based on classifications: Recordings, Calendar Updates, Emails
   * @param {Array} dealData - Detailed activities from the deal endpoint
   * @returns {Object} Aggregated user statistics counts
   */
  const aggregateData = (dealData) => {
    // Initialize totals
    let totalRecordings = 0;
    let totalCalendarUpdates = 0;
    let totalEmails = 0;
    // let totalOther = 0; // Optional: To track 'other' category

    // Aggregate dealData with classification
    dealData.forEach((activity) => {
      const category = getActivityCategory(activity);

      switch (category) {
        case "recording":
          totalRecordings += 1;
          break;
        case "calendar-update":
          totalCalendarUpdates += 1;
          break;
        case "email":
          totalEmails += 1;
          break;
        // case "other":
        //   totalOther += 1;
        //   break;
        default:
          // Handle any unexpected categories if necessary
          break;
      }
    });

    return {
      recordings: { count: totalRecordings },
      calendarUpdates: { count: totalCalendarUpdates },
      emails: { count: totalEmails },
      // Optionally include 'other' if you want to track it
      // other: { count: totalOther },
    };
  };

  /**
   * Aggregates LinkedIn data by summing total_current and total_previous across all users
   * @param {Array} linkedinData - Array of LinkedIn activity objects per user
   * @returns {Object} Aggregated LinkedIn statistics with counts and growth percentages
   */
  const aggregateLinkedInData = (linkedinData) => {
    // Initialize totals for each LinkedIn metric
    let messagesSentCurrent = 0;
    let messagesSentPrevious = 0;

    let messagesReceivedCurrent = 0;
    let messagesReceivedPrevious = 0;

    let invitesCurrent = 0;
    let invitesPrevious = 0;

    // Iterate over each user's LinkedIn data
    linkedinData.forEach((user) => {
      const {
        linkedin_messages_sent,
        linkedin_messages_received,
        linkedin_invites,
      } = user;

      // Aggregate Messages Sent
      const sentCount = linkedin_messages_sent?.count || 0;
      const sentGrowth = linkedin_messages_sent?.growth || 0;
      let sentPrev = 0;
      if (sentGrowth === -100) {
        sentPrev = 0; // Avoid division by zero
      } else if (sentGrowth !== 0) {
        sentPrev = sentCount / (1 + sentGrowth / 100);
      } else {
        sentPrev = sentCount; // No growth
      }
      messagesSentCurrent += sentCount;
      messagesSentPrevious += sentPrev;

      // Aggregate Messages Received
      const receivedCount = linkedin_messages_received?.count || 0;
      const receivedGrowth = linkedin_messages_received?.growth || 0;
      let receivedPrev = 0;
      if (receivedGrowth === -100) {
        receivedPrev = 0; // Avoid division by zero
      } else if (receivedGrowth !== 0) {
        receivedPrev = receivedCount / (1 + receivedGrowth / 100);
      } else {
        receivedPrev = receivedCount; // No growth
      }
      messagesReceivedCurrent += receivedCount;
      messagesReceivedPrevious += receivedPrev;

      // Aggregate Invites
      const invitesCount = linkedin_invites?.count || 0;
      const invitesGrowth = linkedin_invites?.growth || 0;
      let invitesPrev = 0;
      if (invitesGrowth === -100) {
        invitesPrev = 0; // Avoid division by zero
      } else if (invitesGrowth !== 0) {
        invitesPrev = invitesCount / (1 + invitesGrowth / 100);
      } else {
        invitesPrev = invitesCount; // No growth
      }
      invitesCurrent += invitesCount;
      invitesPrevious += invitesPrev; // Fixed Assignment
    });

    // Compute growth percentages
    const computeGrowth = (current, previous) => {
      if (previous === 0) {
        return current === 0 ? 0 : 100; // Define as 100% growth if no previous data
      }
      return ((current - previous) / previous) * 100;
    };

    const messagesSentGrowth = computeGrowth(
      messagesSentCurrent,
      messagesSentPrevious
    );
    const messagesReceivedGrowth = computeGrowth(
      messagesReceivedCurrent,
      messagesReceivedPrevious
    );
    const invitesGrowth = computeGrowth(invitesCurrent, invitesPrevious);

    return {
      messagesSent: {
        current: Math.round(messagesSentCurrent),
        previous: Math.round(messagesSentPrevious),
        growth: Math.round(messagesSentGrowth),
      },
      messagesReceived: {
        current: Math.round(messagesReceivedCurrent),
        previous: Math.round(messagesReceivedPrevious),
        growth: Math.round(messagesReceivedGrowth),
      },
      invites: {
        current: Math.round(invitesCurrent),
        previous: Math.round(invitesPrevious),
        growth: Math.round(invitesGrowth),
      },
    };
  };

  // Handle loading and error states
  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Text>Loading aggregated statistics...</Text>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={10}>
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  if (!aggregatedStats) {
    return (
      <Box textAlign="center" py={10}>
        <Text>No data available.</Text>
      </Box>
    );
  }

  // Define the primary color
  const PRIMARY_COLOR = "#1A5BF6";

  return (
    <Box pb={4}>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} gap="20px">
        {/* Recordings */}
        <StatCard
          icon={MdMic}
          title="Recordings"
          value={aggregatedStats.recordings.count}
          growth={aggregatedStats.recordings.growth}
          timeRange={timeRange}
          primaryColor={PRIMARY_COLOR}
        />

        {/* Calendar Updates */}
        <StatCard
          icon={MdCalendarToday}
          title="Calendar Updates" // Updated title
          value={aggregatedStats.calendarUpdates.count} // Updated key
          growth={aggregatedStats.calendarUpdates.growth} // Updated key
          timeRange={timeRange}
          primaryColor={PRIMARY_COLOR}
        />

        {/* Emails */}
        <StatCard
          icon={MdEmail}
          title="Emails"
          value={aggregatedStats.emails.count}
          growth={aggregatedStats.emails.growth}
          timeRange={timeRange}
          primaryColor={PRIMARY_COLOR}
        />

        {/* LinkedIn Messages Sent */}
        <StatCard
          icon={FaLinkedin}
          title="LinkedIn Sent"
          value={aggregatedStats.linkedin_messages_sent.count}
          growth={aggregatedStats.linkedin_messages_sent.growth}
          timeRange={timeRange}
          primaryColor={PRIMARY_COLOR}
        />

        {/* LinkedIn Messages Received */}
        <StatCard
          icon={FaLinkedin}
          title="LinkedIn Received"
          value={aggregatedStats.linkedin_messages_received.count}
          growth={aggregatedStats.linkedin_messages_received.growth}
          timeRange={timeRange}
          primaryColor={PRIMARY_COLOR}
        />

        {/* LinkedIn Invites */}
        <StatCard
          icon={FaLinkedin}
          title="LinkedIn Invites"
          value={aggregatedStats.linkedin_invites.count}
          growth={aggregatedStats.linkedin_invites.growth}
          timeRange={timeRange}
          primaryColor={PRIMARY_COLOR}
        />
      </SimpleGrid>
    </Box>
  );
};

export default AggregatedStatsGrid;
