import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Select,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useAuthData } from "auth-context";
import axios from "axios";

const SmartFollowupsSection = () => {
  const userData = useAuthData();
  const accountID = userData?.account_ID;
  const userID = userData?.user_ID;
  const toast = useToast();
  const [days, setDays] = useState(5);
  const [isSaving, setIsSaving] = useState(false);
  const apiUrl =
    process.env.REACT_APP_API_URL || "https://lysto-be-tf.azurewebsites.net";

  useEffect(() => {
    // Fetch existing settings from the backend if available
    const fetchSettings = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/accounts/${accountID}/settings`
        );
        if (
          response.data.settings &&
          response.data.settings.days_until_follow_up
        ) {
          setDays(response.data.settings.days_until_follow_up);
        }
      } catch (error) {
        console.error("Error fetching Smart Followups settings:", error);
      }
    };

    if (accountID && userID) {
      fetchSettings();
    }
  }, [accountID, userID]);

  const handleSave = async () => {
    setIsSaving(true);
    const settings = { days_until_follow_up: days };
    try {
      await axios.put(`${apiUrl}/accounts/${accountID}/settings`, settings);
      toast({
        title: "Settings Saved",
        description: `Smart Followups will trigger after ${days} day(s) of inactivity.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("Error saving Smart Followups settings:", error);
      toast({
        title: "Save Failed",
        description: "Unable to save Smart Followups settings.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={6} bg="white" boxShadow="md">
      <Heading as="h3" size="md" mb={4}>
        Smart Followups
      </Heading>
      <VStack align="start" spacing={4}>
        <Text>
          Set the number of days of inactivity before the AI generates a
          follow-up task. For example, if set to 5 days, the AI will draft a
          follow-up email for deals with no engagement in the past 5 days.
        </Text>
        <Box>
          <Text mb={2} fontWeight="bold">
            Days Until Follow-up:
          </Text>
          <Select
            value={days}
            onChange={(e) => setDays(parseInt(e.target.value))}
            maxW="200px"
          >
            {[...Array(30)].map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1} {index + 1 === 1 ? "day" : "days"}
              </option>
            ))}
          </Select>
        </Box>
        <Button
          onClick={handleSave}
          colorScheme="blue"
          isLoading={isSaving}
          loadingText="Saving"
        >
          Save Settings
        </Button>
      </VStack>
    </Box>
  );
};

export default SmartFollowupsSection;
