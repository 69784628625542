// src/components/RichCards/EmailCard.js

import React from "react";
import {
  Box,
  VStack,
  Text,
  Divider,
  Link,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { MdAttachment } from "react-icons/md";
import PropTypes from "prop-types";
import parse from "html-react-parser"; // Import html-react-parser

// Mapping of email addresses to names
const emailToName = {
  "jane.doe@example.com": "Jane Doe",
  "john.smith@example.com": "John Smith",
  "sales@example.com": "Sales Team",
  // Add more mappings as needed
};

/**
 * Helper function to map recipient types to their emails.
 * @param {Array} recipients - Array of recipient objects.
 * @returns {Object} An object containing arrays of 'from', 'to', 'cc', and 'bcc' emails.
 */
const mapRecipients = (recipients) => {
  const mapped = {
    from: [],
    to: [],
    cc: [],
    bcc: [],
  };

  recipients.forEach((recipient) => {
    const { RecipientType, Email } = recipient;
    if (RecipientType && Email) {
      switch (RecipientType.toLowerCase()) {
        case "from":
          mapped.from.push(Email);
          break;
        case "to":
          mapped.to.push(Email);
          break;
        case "cc":
          mapped.cc.push(Email);
          break;
        case "bcc":
          mapped.bcc.push(Email);
          break;
        default:
          break;
      }
    }
  });

  return mapped;
};

/**
 * EmailCard Component
 *
 * @param {object} props
 * @param {object} props.activity - The activity object containing email details.
 * @returns {JSX.Element}
 */
const EmailCard = ({ activity }) => {
  // Map recipients
  const { from, to, cc, bcc } = mapRecipients(activity.recipients || []);

  // Function to map emails to names
  const mapEmailsToNames = (emails) => {
    if (!emails.length) return "N/A";
    return emails
      .map((email) => emailToName[email] || email)
      .join(", ");
  };

  // Extract 'From', 'To', 'CC', 'BCC'
  const fromDisplay = mapEmailsToNames(from);
  const toDisplay = mapEmailsToNames(to);
  const ccDisplay = mapEmailsToNames(cc);
  const bccDisplay = mapEmailsToNames(bcc);

  // Extract 'Subject' from activity.title
  const subject = activity.title || "No Subject";

  // Extract 'Body' from activity.description (HTML)
  const bodyHtml = activity.description || "<p>No Content</p>";

  // Handle Attachments (if applicable)
  const hasAttachments = false; // Update based on actual data
  const attachments = []; // Populate based on actual data

  return (
    <Box
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      width="100%"
      border="1px solid"
      borderColor="gray.200"
    >
      <VStack align="stretch" spacing={3}>
        {/* From */}
        <Text>
          <strong>From:</strong> {fromDisplay}
        </Text>
        {/* To */}
        <Text>
          <strong>To:</strong> {toDisplay}
        </Text>
        {/* CC (conditionally rendered) */}
        {cc.length > 0 && (
          <Text>
            <strong>CC:</strong> {ccDisplay}
          </Text>
        )}
        {/* BCC (conditionally rendered) */}
        {bcc.length > 0 && (
          <Text>
            <strong>BCC:</strong> {bccDisplay}
          </Text>
        )}
        {/* Subject */}
        <Text>
          <strong>Subject:</strong> {subject}
        </Text>
        <Divider />
        {/* Body */}
        <Box bg="gray.50" p={3} borderRadius="md">
          {parse(bodyHtml)}
        </Box>
        {/* Attachments (conditionally rendered) */}
        {hasAttachments && attachments.length > 0 && (
          <HStack>
            <Icon as={MdAttachment} color="gray.500" />
            <VStack align="start" spacing={0}>
              {attachments.map((att, index) => (
                <Link key={index} href={att.url} color="blue.500" isExternal>
                  {att.name || `Attachment ${index + 1}`}
                </Link>
              ))}
            </VStack>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

EmailCard.propTypes = {
  activity: PropTypes.shape({
    recipients: PropTypes.arrayOf(
      PropTypes.shape({
        ID: PropTypes.number,
        Name: PropTypes.string,
        Email: PropTypes.string,
        RecipientType: PropTypes.string,
      })
    ),
    title: PropTypes.string,
    description: PropTypes.string,
    // Include other fields if necessary
  }).isRequired,
};

export default EmailCard;
