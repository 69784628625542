import { Box } from "@chakra-ui/react";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarProvider, SidebarContext } from "contexts/SidebarContext";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes.js";

export default function Dashboard(props) {
  const { ...rest } = props;

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.path} // Use relative path
            key={key}
            element={<prop.component {...props} type={prop.type} />}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <SidebarProvider>
      <Box>
        <SidebarContext.Consumer>
          {({ visibleRoutes }) => (
            <Sidebar
              routes={routes.filter((route) => visibleRoutes[route.name])}
              display="none"
              {...rest}
            />
          )}
        </SidebarContext.Consumer>
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 200px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 200px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: "10px", md: "20px" }}
              pe="20px"
              minH="100vh"
              pt="0"
              style={{ marginTop: "-80px" }}
            >
              <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<Navigate to="main" replace />} />
              </Routes>
            </Box>
          ) : null}
        </Box>
      </Box>
    </SidebarProvider>
  );
}
