// src/constants/inboxMappings.js

export const STATUS_MAP = {
  "draft": { label: "Draft", color: "yellow" },
  "sent": { label: "Sent", color: "green" },
  // Add more statuses as needed
};

export const TASK_MAP = {
  "Follow-up Call": { label: "Follow-up Call", color: "blue" },
  "Follow-up Email": { label: "Follow-up Email", color: "green" },
  // Add more tasks as needed
};
