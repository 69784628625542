import React from "react";
import { Box, Flex, Text, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import StatCard from "./StatCard";
import { MdEmail, MdCalendarToday, MdMic, MdPerson } from "react-icons/md"; // Updated Icons
import { FaLinkedin } from "react-icons/fa";

/**
 * UserStatCard Component
 * Displays a user's information and their activity metrics using StatCard components.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.user - The user data object.
 * @returns {JSX.Element} The rendered UserStatCard component.
 */
const UserStatCard = ({ user }) => {
  // Define background color based on color mode
  const bg = useColorModeValue("white", "gray.700");

  // Capitalize the first letter of the user's name
  const capitalizedUserName =
    user.name.charAt(0).toUpperCase() + user.name.slice(1);

  // Define the primary color
  const PRIMARY_COLOR = "#1A5BF6";

  return (
    <Box bg={bg} borderRadius="md" p={4} boxShadow="sm">
      {/* User Information */}
      <Flex alignItems="center" mb={4}>
        <MdPerson size={24} color={PRIMARY_COLOR} /> {/* Updated Icon */}
        <Box ml={3}>
          <Text fontWeight="bold" fontSize="lg">
            {capitalizedUserName}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {user.email}
          </Text>
        </Box>
      </Flex>

      {/* User Metrics */}
      <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} spacing={4}>
        {/* Recordings */}
        <StatCard
          icon={MdMic}
          title="Recordings"
          value={user.recordings?.count || 0}
          growth={user.recordings?.growth || 0}
          timeRange=""
          primaryColor={PRIMARY_COLOR} // Use primary blue
        />

        {/* Calendars */}
        <StatCard
          icon={MdCalendarToday}
          title="Calendars"
          value={user.calendars?.count || 0}
          growth={user.calendars?.growth || 0}
          timeRange=""
          primaryColor={PRIMARY_COLOR} // Use primary blue
        />

        {/* Emails */}
        <StatCard
          icon={MdEmail}
          title="Emails"
          value={user.emails?.count || 0}
          growth={user.emails?.growth || 0}
          timeRange=""
          primaryColor={PRIMARY_COLOR} // Use primary blue
        />

        {/* LinkedIn Messages Sent */}
        <StatCard
          icon={FaLinkedin}
          title="LinkedIn Sent"
          value={user.linkedin_messages_sent?.count || 0}
          growth={user.linkedin_messages_sent?.growth || 0}
          timeRange=""
          primaryColor={PRIMARY_COLOR} // Use primary blue
        />

        {/* LinkedIn Messages Received */}
        <StatCard
          icon={FaLinkedin}
          title="LinkedIn Received"
          value={user.linkedin_messages_received?.count || 0}
          growth={user.linkedin_messages_received?.growth || 0}
          timeRange=""
          primaryColor={PRIMARY_COLOR} // Use primary blue
        />

        {/* LinkedIn Invites */}
        <StatCard
          icon={FaLinkedin}
          title="LinkedIn Invites"
          value={user.linkedin_invites?.count || 0}
          growth={user.linkedin_invites?.growth || 0}
          timeRange=""
          primaryColor={PRIMARY_COLOR} // Use primary blue
        />
      </SimpleGrid>
    </Box>
  );
};

export default UserStatCard;
