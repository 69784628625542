// src/components/Shared/PaginationControls.jsx

import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

/**
 * PaginationControls Component
 * Provides Previous and Next buttons along with current page information.
 *
 * @param {Object} props - Component props
 * @param {number} props.currentPage - The current active page (1-based index)
 * @param {number} props.totalPages - Total number of pages
 * @param {Function} props.onPrevious - Function to call when Previous is clicked
 * @param {Function} props.onNext - Function to call when Next is clicked
 * @returns {JSX.Element}
 */
const PaginationControls = ({ currentPage, totalPages, onPrevious, onNext }) => {
  return (
    <Flex justify="space-between" align="center" mt={4}>
      <Button
        onClick={onPrevious}
        isDisabled={currentPage === 1}
        bg="white" // White background
        color="black" // Adjust text color as needed
        borderRadius="md" // Less rounded corners
        _hover={{
          bg: "gray.100", // Light gray on hover
        }}
      >
        Previous
      </Button>
      <Text>
        Page {currentPage} of {totalPages}
      </Text>
      <Button
        onClick={onNext}
        isDisabled={currentPage === totalPages}
        bg="white" // White background
        color="black" // Adjust text color as needed
        borderRadius="md" // Less rounded corners
        _hover={{
          bg: "gray.100", // Light gray on hover
        }}
      >
        Next
      </Button>
    </Flex>
  );
};

// Define PropTypes for type checking
PaginationControls.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default PaginationControls;
