// src/hooks/useFetchDealById.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Custom hook to fetch deal based on the account ID.
 * @param {string} accountID - The account ID to fetch deal for.
 * @param {string} dealID - The deal ID to fetch.
 * @returns {Object} - Contains deal data, loading state, and error state.
 */
const useFetchDealById = (accountID, dealID) => {
  const { getAccessTokenSilently } = useAuth0();
  const [deal, setDeal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  
  useEffect(() => {
    const fetchDeal = async () => {
      if (!accountID || !dealID) {
        console.log("No account ID or deal ID provided");
        setIsLoading(false);
        setDeal(null); // Ensure deal is null if no accountID or dealID
        return;
      }
      console.log("Fetching deal...");

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-be-tf.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/deals/${dealID}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setDeal(response.data || null); // Set to null if response.data is falsy
        setIsError(false);
      } catch (error) {
        console.error("Error fetching deal:", error);
        setDeal(null); // Set deal to null on error
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeal();
  }, [accountID, dealID, getAccessTokenSilently]);

  return { deal, isLoading, isError };
};

export default useFetchDealById;
