import React, { useState, useCallback, useEffect } from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import ConversationTable from "views/admin/dataTables/components/ConversationTable";
import HoverBox from "views/lysto/Shared/HoverBox";
import { MdBusiness } from 'react-icons/md';
import { faLink, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { FaLinkedin } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Updated to use useNavigate
import { useAuthData } from "auth-context";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import accountData from "./AccountData";

export default function Accounts() {
  const userData = useAuthData();
  const accountID = userData?.account_ID || "";
  const navigate = useNavigate(); // Updated to use useNavigate
  const { getAccessTokenSilently } = useAuth0();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!accountID) return;
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:calendar",
          },
        });

        const target =
          process.env.REACT_APP_NODE_ENV === "local"
            ? "http://localhost:8080"
            : "https://lysto-be-tf.azurewebsites.net";

        const dataResponse = await axios.get(
          `${target}/api/accounts/${accountID}/crm-accounts`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              account_type: "Lead",
              page_number: 1,
              items_per_page: 10, 
            },
          }
        );
        if (dataResponse.data) {
          setAccounts(dataResponse.data);
        }
      } catch (err) {
        setAccounts(accountData); // Fallback to dummy data
        console.error("Error fetching data:", err.message);
      }
    };

    fetchData();
  }, [accountID, getAccessTokenSilently]);

  const handleViewDetails = useCallback((account) => {
    navigate(`/admin/accounts/${account.id}`); // Updated to use navigate
  }, [navigate]);

  const columnsAccount = [
    {
      Header: "Business",
      accessor: "name",
      Cell: ({ row }) => (
        <HoverBox 
          icon={MdBusiness} 
          hoverIcon={faExpandAlt} 
          onClick={() => handleViewDetails(row.original)}
        >
          <Text fontWeight="bold">{row.original.name}</Text>
        </HoverBox>
      ),
    },
    {
      Header: "Industry",
      accessor: "enrichment.Industry",
      Cell: ({ value }) => <Text>{value || "N/A"}</Text>, // Added fallback for missing values
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ value }) => <Text>{value || "N/A"}</Text>, // Added fallback for missing values
    },
    {
      Header: "Champion",
      accessor: "champion",
      Cell: ({ value }) => <Text>{value || "N/A"}</Text>, // Added fallback for missing values
    },
    {
      Header: "LinkedIn",
      accessor: "linkedin",
      Cell: ({ value }) => (
        value ? (
          <HoverBox icon={FaLinkedin} hoverIcon={faLink}>
            <Text
              as="a"
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              fontSize="sm"
              fontWeight="700"
              _groupHover={{ color: "#1A5BF6" }}
            >
              LinkedIn
            </Text>
          </HoverBox>
        ) : (
          <Text fontSize="sm" color="gray.500">N/A</Text>
        )
      ),
    },
    {
      Header: "Last Activity",
      accessor: "last_activity_date",
      Cell: ({ value }) => {
        if (!value) return <Text>N/A</Text>;
        const date = new Date(value);
        return <Text>{date.toLocaleDateString()}</Text>;
      },
    },
  ];

  return (
    <Box pt={{ base: "80px", sm: "50px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <ConversationTable
          columnsData={columnsAccount}
          tableData={accounts}
          title="Accounts"
        />
      </SimpleGrid>
    </Box>
  );
}
