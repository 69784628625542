// PieCard.js
import React from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { CHART_COLORS } from "../constants";

/**
 * Helper function to calculate the luminance of a color.
 * Luminance is a measure of how bright a color is.
 *
 * @param {string} hexColor - The hex code of the color (e.g., "#FFFFFF").
 * @returns {number} The luminance value (0 for darkest, 1 for lightest).
 */
const getLuminance = (hexColor) => {
  // Remove the hash if present
  const color = hexColor.replace("#", "");

  // Parse the r, g, b values
  const r = parseInt(color.substring(0, 2), 16) / 255;
  const g = parseInt(color.substring(2, 4), 16) / 255;
  const b = parseInt(color.substring(4, 6), 16) / 255;

  // Apply the sRGB formula
  const a = [r, g, b].map((v) =>
    v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  );

  // Calculate luminance
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
};

/**
 * Helper function to capitalize the first letter of a string.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} The capitalized string.
 */
const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * PieCard Component
 * Renders a pie chart along with its legend, with labels and colors sorted in descending order.
 * Only displays data points with nonzero values.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.pieData - The data for the pie chart.
 * @param {Object} rest - Additional props.
 */
export default function PieCard({ pieData, ...rest }) {
  const textColor = useColorModeValue("b_gray.300", "white");
  const lightTextColor = useColorModeValue("b_gray.300", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  /**
   * Sort the labels and data in descending order based on data values
   * Also sort the CHART_COLORS in descending order based on darkness
   * Filter out data points with zero values
   */
  const sortedData = [...pieData.datasets[0].data];
  const sortedLabels = [...pieData.labels];

  // Combine labels and data into an array of objects
  let combined = sortedLabels.map((label, index) => ({
    label,
    value: sortedData[index],
    color: CHART_COLORS[index % CHART_COLORS.length],
  }));

  // Filter out data points with zero values
  combined = combined.filter((item) => item.value > 0);

  // Sort the combined array in descending order based on value
  combined.sort((a, b) => b.value - a.value);

  // Sort the remaining CHART_COLORS by luminance (darkest to lightest)
  const sortedColors = [...CHART_COLORS].sort(
    (a, b) => getLuminance(a) - getLuminance(b)
  );

  // Assign the sorted colors to the sorted data points
  const sortedColorsDescending = sortedColors.slice(0, combined.length);

  // Map each sorted data point to the corresponding sorted color
  const finalCombined = combined.map((item, index) => ({
    ...item,
    color: sortedColorsDescending[index % sortedColorsDescending.length],
  }));

  // Separate the sorted labels, data, and colors
  const sortedLabelsDescending = finalCombined.map((item) => capitalizeFirstLetter(item.label));
  const sortedDataDescending = finalCombined.map((item) => item.value);
  const sortedColorsFinal = finalCombined.map((item) => item.color);

  // Recalculate the total based on sorted data (should be same as originalTotal minus zero values)
  const total = sortedDataDescending.reduce((acc, val) => acc + val, 0);

  return (
    <Card p="20px" align="center" direction="column" w="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="xl" fontWeight="600" mt="4px">
          {pieData.title}
        </Text>
      </Flex>

      {/* Chart container */}
      <Box w="75%" mx="auto">
        <PieChart
          chartData={sortedDataDescending}
          chartOptions={{
            labels: sortedLabelsDescending,
            colors: sortedColorsFinal,
            chart: {
              width: "100%",
              height: "300px",
            },
            states: {
              hover: {
                filter: {
                  type: "none",
                },
              },
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: true, // Enable data labels
              formatter: (val) => `${val.toFixed(1)}%`, // Show only percentage with one decimal
              style: {
                colors: ["white"],
              },
            },
            hover: { mode: null },
            plotOptions: {
              pie: {
                expandOnClick: false,
              },
            },
            fill: {
              colors: sortedColorsFinal,
            },
            tooltip: {
              enabled: true,
              theme: "dark",
              y: {
                formatter: (val) =>
                  `${val} (${((val / total) * 100).toFixed(1)}%)`,
              },
            },
          }}
        />
      </Box>

      <Card
        bg={cardColor}
        flexDirection="row"
        boxShadow={cardShadow}
        w="100%"
        pY="15px" // Ensure equal padding top and bottom
        pX="20px"
        mt="15px"
        mx="auto"
      >
        <Flex flexWrap="wrap" justifyContent="space-around" w="100%">
          {sortedLabelsDescending.map((label, index) => (
            <Flex
              key={label}
              direction="column"
              alignItems="center"
              minW="80px"
              // Removed mb="10px" to eliminate padding below labels
            >
              <Flex alignItems="center" mb="5px">
                <Box
                  h="8px"
                  w="8px"
                  bg={sortedColorsFinal[index % sortedColorsFinal.length]}
                  borderRadius="50%"
                  mr="4px"
                />
                <Text fontSize="xs" color={lightTextColor} fontWeight="700">
                  {label}
                </Text>
              </Flex>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {sortedDataDescending[index]}
                {/* Optionally, display percentage */}
                {/* ({((sortedDataDescending[index] / total) * 100).toFixed(1)}%) */}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Card>
    </Card>
  );
}
