import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  VStack,
  Text,
  Flex,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Tooltip,
  useDisclosure,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { MoreVertical, Trash2, ArrowLeft } from "lucide-react";
import MeetingInfo from "./MeetingInfo";
import MeetingContext from "./MeetingContext";
import MeetingPrepContent from "./MeetingPrepContent";
import { format } from "date-fns";

const MeetingPrepPage = () => {
  const [meeting, setMeeting] = useState(null);
  const { meetingID } = useParams();
  const navigate = useNavigate(); // Replace useHistory with useNavigate
  const location = useLocation();
  const { onClose: closePopover } = useDisclosure();
  const toast = useToast();

  const handleDelete = async () => {
    closePopover();
    // Implement delete API call here
    try {
      // await deleteMeeting(meetingID);
      toast({
        title: "Meeting deleted",
        description: "The meeting has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/admin/meetings"); // Use navigate instead of history.push
    } catch (error) {
      console.error("Error deleting meeting:", error);
      toast({
        title: "Error",
        description: "Failed to delete the meeting",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchMeetingById = (meetingID) => {
    // Simulated API call
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          id: meetingID,
          title: "The Bluth Company Demo Call",
          date: "2024-09-05T13:30:00.000Z",
          participants: ["John Doe", "Jane Smith"],
          status: "Scheduled",
          agenda: "Discuss Q3 performance and Q4 goals",
          context: "Meeting to be held via Zoom",
          // Add other relevant meeting data here
        });
      }, 500);
    });
  };

  useEffect(() => {
    console.log("MeetingPage rendered with meetingID:", meetingID);
    console.log("Current location:", window.location.pathname);

    const loadMeeting = async () => {
      if (location.state && location.state.meetingData) {
        location.state.meetingData.context = "Meeting to be held via Zoom";
        setMeeting(location.state.meetingData);
      } else {
        try {
          const meetingData = await fetchMeetingById(meetingID);
          setMeeting(meetingData);
        } catch (error) {
          console.error("Error fetching meeting:", error);
          toast({
            title: "Error",
            description: "Failed to load meeting data",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    loadMeeting();
  }, [meetingID, location.state, toast]);

  const handleBack = () => {
    navigate("/admin/meetings"); // Use navigate instead of history.push
  };

  if (!meeting) {
    return <Box>Loading...</Box>;
  }

  return (
    <VStack align="stretch" spacing={8} pt={20}>
      <Flex align="center">
        <IconButton
          icon={<ArrowLeft />}
          aria-label="Go back"
          onClick={handleBack}
          variant="ghost"
          size="lg"
          mr={4}
        />
        <VStack align="start" spacing={2}>
          <Text fontSize="lg" fontWeight="bold" color="#57595E">
            {meeting.title}
          </Text>
          <Text color="#57595E">
            {format(new Date(meeting.date), "MMM dd, yyyy ha")} •{" "}
            {meeting.participants.length} attendees
          </Text>
        </VStack>
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <IconButton
              icon={<MoreVertical />}
              variant="ghost"
              size="sm"
              aria-label="More options"
              ml="auto"
            />
          </PopoverTrigger>
          <PopoverContent width="auto">
            <PopoverBody p={0}>
              <Tooltip label="Delete" placement="left">
                <IconButton
                  icon={<Trash2 />}
                  variant="ghost"
                  size="sm"
                  aria-label="Delete meeting"
                  onClick={handleDelete}
                  w="100%"
                  justifyContent="flex-start"
                  borderRadius={0}
                />
              </Tooltip>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>

      <Flex>
        <Box flex={1} mr={8}>
          <Tabs variant="unstyled">
            <TabList>
              {["Prep", "Overview", "Context"].map((tab, index) => (
                <Tab
                  key={index}
                  _selected={{
                    color: "#1A5BF6",
                    fontWeight: "bold",
                    position: "relative",
                    _after: {
                      content: '""',
                      position: "absolute",
                      bottom: "-2px",
                      left: 0,
                      right: 0,
                      height: "2px",
                      backgroundColor: "#1A5BF6",
                      borderRadius: "2px",
                      width: "100%", // Ensures the underline spans the full width of the tab text
                    },
                  }}
                  px={4} // Add horizontal padding to the tabs
                  py={2} // Add vertical padding to the tabs
                >
                  {tab}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              <TabPanel px={0}>
                <MeetingPrepContent meetingData={meeting} />
              </TabPanel>
              <TabPanel px={0}>
                <MeetingInfo meetingData={meeting} />
              </TabPanel>
              <TabPanel px={0}>
                <MeetingContext meetingData={meeting} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </VStack>
  );
};

export default MeetingPrepPage;
