import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Spinner, Center, Text, VStack } from "@chakra-ui/react";

const CallbackComponent = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate("/admin");
      } else if (error) {
        // Handle or display the error, or redirect to an error page.
        console.error("Authentication error:", error);
        navigate("/auth/logout");
      }
    }
  }, [isLoading, isAuthenticated, error, navigate]);

  if (isLoading) {
    return (
      <Center height="100vh">
        <VStack spacing={4}>
          <Spinner size="xl" />
          <Text>Authenticating, please wait...</Text>
        </VStack>
      </Center>
    );
  }

  if (error) {
    return (
      <Center height="100vh">
        <VStack spacing={4} color="red.500">
          <Text>Authentication error occurred.</Text>
          <Text>{error.message}</Text>
        </VStack>
      </Center>
    );
  }

  return null; // No visible content after authentication.
};

export default CallbackComponent;
