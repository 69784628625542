// src/components/Contacts/hooks/useFetchContactById.js

import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Custom hook to fetch a contact by ID.
 * @param {string} accountID - The account ID.
 * @param {string} contactID - The contact ID.
 * @returns {Object} { contact, isLoading, isError }
 */
const useFetchContactById = (accountID, contactID) => {
  const { getAccessTokenSilently } = useAuth0();
  const [contact, setContact] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchContact = async () => {
      if (!accountID || !contactID) {
        setIsLoading(false);
        setContact(null);
        return;
      }

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            scope: "read:contacts",
          },
        });

        const target =
          process.env.REACT_APP_API_BASE_URL ||
          "https://lysto-be-tf.azurewebsites.net";

        const response = await axios.get(
          `${target}/accounts/${accountID}/contacts/${contactID}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setContact(response.data || null);
        setIsError(false);
      } catch (error) {
        console.error("Error fetching contact:", error);
        setContact(null);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContact();
  }, [accountID, contactID, getAccessTokenSilently]);

  return { contact, isLoading, isError };
};

export default useFetchContactById;
