// src/components/Inbox/EmailFollowupDetail.jsx
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, VStack, useToast } from "@chakra-ui/react";
import OverviewSection from "./OverviewSection";
import EmailDetailsSection from "./EmailDetailsSection";
import ConversationHistory from "./ConversationHistory";
import MessageInput from "./MessageInput";
import ActionButtons from "./ActionButtons"; // Ensure this is imported
import AnnotationModal from "./AnnotationModal";

const EmailFollowupDetail = ({
  item,
  onSendMessage,
  onAddAnnotation,
  onArchiveToggle, // Add this prop if not already
}) => {
  const toast = useToast();

  // Find the draft message in the conversation history
  const draftMessage = item?.conversationHistory?.find(
    (msg) => msg.status === "draft"
  );

  // Initialize state with draft content if available
  const [message, setMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // Update state when draft message or item changes
  useEffect(() => {
    if (draftMessage) {
      setMessage(draftMessage.content || "");
      setEmailSubject(draftMessage.subject || item?.emailSubject || "");
      setEmailRecipients(item.email_recipients || []);
    } else {
      setMessage("");
      setEmailSubject(item?.emailSubject || "");
      setEmailRecipients(item.email_recipients || []);
    }
  }, [draftMessage, item]);

  const toggleAnnotationModal = () => {
    setIsAnnotationModalOpen((prev) => !prev);
  };

  const handleSend = async () => {
    if (!message.trim()) {
      toast({
        title: "Empty Message",
        description: "Please enter a message to send.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (emailRecipients.length === 0) {
      toast({
        title: "No Recipients",
        description: "Please add at least one recipient (To, CC, or BCC).",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSending(true);
    try {
      await onSendMessage({
        subject: emailSubject,
        content: message,
        email_recipients: emailRecipients,
      });
      setMessage("");
      setEmailSubject("");
      setEmailRecipients([]);
      toast({
        title: "Message Sent",
        description: "Your email has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Send Failed",
        description: error.message || "Failed to send the message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleEmailSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleEmailRecipientsChange = (recipients) => {
    setEmailRecipients(recipients);
  };

  // Sort conversation history by date, excluding draft messages and empty content
  const sortedConversationHistory = item?.conversationHistory
    ? [...item.conversationHistory]
        .filter(
          (msg) =>
            msg.status !== "draft" &&
            msg.content &&
            msg.content.trim() !== ""
        )
        .sort((a, b) => {
          const dateA = new Date(a.date_sent || a.created_at);
          const dateB = new Date(b.date_sent || b.created_at);
          return dateA - dateB;
        })
    : [];

  // Early return if no item
  if (!item) {
    return null;
  }

  return (
    <Box
      bg="transparent"
      p={0}
      borderRadius="md"
      boxShadow="none"
      mb={4}
      height="100%"
    >
      <VStack spacing={6} align="stretch" height="100%">

        {/* Card 1: Overview and Conversation History */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <OverviewSection
              account={item.account_name}
              taskType={
                item.task_subtype === "email"
                  ? "Email Follow-up"
                  : item.task_subtype
              }
              champion={item.champion || "N/A"}
              lastEngagementDate={item.last_activity_date}
            />

            <ConversationHistory
              conversationHistory={sortedConversationHistory}
            />
          </VStack>
        </Box>

        {/* Card 2: Email Details and Message Input */}
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          boxShadow="sm"
          borderWidth="1px"
          borderColor="gray.200"
        >
          <VStack spacing={6} align="stretch">
            <EmailDetailsSection
              emailSubject={emailSubject}
              onSubjectChange={handleEmailSubjectChange}
              recipients={emailRecipients}
              onRecipientsChange={handleEmailRecipientsChange}
            />

            <MessageInput
              message={message}
              setMessage={setMessage}
              isSending={isSending}
            />

            {/* Send UI Implementation */}
            <ActionButtons
              onArchiveToggle={onArchiveToggle}
              onAddAnnotation={toggleAnnotationModal}
              onSend={handleSend}
              isSending={isSending}
              isArchived={item.status === "archived"}
              isMessageEmpty={!message.trim()}
            />
          </VStack>
        </Box>
      </VStack>

      {/* Annotation Modal */}
      <AnnotationModal
        isOpen={isAnnotationModalOpen}
        onClose={toggleAnnotationModal}
        onAddAnnotation={async (annotation) => {
          try {
            await onAddAnnotation(annotation);
            toggleAnnotationModal();
            toast({
              title: "Note Added",
              description: "Your note has been added successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          } catch (error) {
            console.error("Error adding note:", error);
            toast({
              title: "Add Note Failed",
              description: error.message || "Failed to add the note.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }}
      />
    </Box>
  );
};

EmailFollowupDetail.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    conversationHistory: PropTypes.arrayOf(
      PropTypes.shape({
        message_id: PropTypes.string.isRequired,
        identity: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        type: PropTypes.string,
        status: PropTypes.string,
        date_sent: PropTypes.string,
        sender_name: PropTypes.string,
        sender_email: PropTypes.string,
      })
    ),
    subject: PropTypes.string,
    email_recipients: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        name: PropTypes.string,
        recipient_type: PropTypes.oneOf(["to", "cc", "bcc"]).isRequired,
      })
    ),
    account_name: PropTypes.string,
    task_subtype: PropTypes.string.isRequired,
    last_activity_date: PropTypes.string,
    champion: PropTypes.string,
  }).isRequired,
  onArchiveToggle: PropTypes.func.isRequired, // Ensure this prop is passed
  onSendMessage: PropTypes.func.isRequired,
  onAddAnnotation: PropTypes.func.isRequired,
};

export default EmailFollowupDetail;
